<template>
  <section class="cmn-section agent-system" id="agent-system">
    <v-container>
      <page-heading :heading="title" />
      <div class="wrap d-flex" style="place-items: center">
        <img class="header-img" src="../../../assets/img/about/zone_hcm_2.png"/>
        <v-sheet
          max-width="880"
          class="zone-area"
          v-if="Model.ho_chi_minh_city"
        >
          <v-slide-group
            class="agency-list"
            multiple
            show-arrows
          >
            <v-slide-item
              v-for="n in Model.ho_chi_minh_city.length"
              :key="n"
            >
              <div class="ma-4" style="margin-left: 5px!important;margin-right: 5px!important;">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <span
                      v-bind="attrs"
                      v-on="on"
                    >
                      <img :src="Model.ho_chi_minh_city[n - 1].img_logo.url" height="50" alt=""/>
                    </span>
                  </template>
                  <ul>
                    <li>{{ Model.ho_chi_minh_city[n - 1].name }}</li>
                    <li>{{ Model.ho_chi_minh_city[n - 1].address }}</li>
                    <li>{{ Model.ho_chi_minh_city[n - 1].email }}</li>
                    <li>{{ Model.ho_chi_minh_city[n - 1].telephone }}</li>
                    <li v-if="Model.ho_chi_minh_city[n - 1].url">{{ Model.ho_chi_minh_city[n - 1].url }}</li>
                  </ul>
                </v-tooltip>
              </div>
            </v-slide-item>
          </v-slide-group>
        </v-sheet>
      </div>

      <div class="wrap d-flex" style="place-items: center">
        <img class="header-img" src="../../../assets/img/about/zone_mdg.png"/>
        <v-sheet
          max-width="880"
          width="100%"
          class="zone-area"
          v-if="Model.east_area"
        >
          <v-slide-group
            class="agency-list"
            multiple
            show-arrows
          >
            <v-slide-item
              v-for="n in Model.east_area.length"
              :key="n"
            >
              <div v-if="n===1" class="ma-4" style="margin: 0px!important;">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <span
                      v-bind="attrs"
                      v-on="on"
                    >
                      <img :src="Model.east_area[n - 1].img_logo.url" height="80"  alt=""/>
                    </span>
                  </template>
                  <ul>
                    <li>{{ Model.east_area[n - 1].name }}</li>
                    <li>{{ Model.east_area[n - 1].address }}</li>
                    <li>{{ Model.east_area[n - 1].email }}</li>
                    <li>{{ Model.east_area[n - 1].telephone }}</li>
                    <li v-if="Model.east_area[n - 1].url">{{ Model.east_area[n - 1].url }}</li>
                  </ul>
                </v-tooltip>
              </div>
              <div v-else-if="n===3" class="ma-4" style="margin-bottom: 0px!important;">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <span
                        v-bind="attrs"
                        v-on="on"
                    >
                      <img :src="Model.east_area[n - 1].img_logo.url" height="60"  alt=""/>
                    </span>
                  </template>
                  <ul>
                    <li>{{ Model.east_area[n - 1].name }}</li>
                    <li>{{ Model.east_area[n - 1].address }}</li>
                    <li>{{ Model.east_area[n - 1].email }}</li>
                    <li>{{ Model.east_area[n - 1].telephone }}</li>
                    <li v-if="Model.east_area[n - 1].url">{{ Model.east_area[n - 1].url }}</li>
                  </ul>
                </v-tooltip>
              </div>
              <div v-else-if="n===4" class="ma-4" style="margin: 13px!important;">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <span
                        v-bind="attrs"
                        v-on="on"
                    >
                      <img :src="Model.east_area[n - 1].img_logo.url" height="60"  alt=""/>
                    </span>
                  </template>
                  <ul>
                    <li>{{ Model.east_area[n - 1].name }}</li>
                    <li>{{ Model.east_area[n - 1].address }}</li>
                    <li>{{ Model.east_area[n - 1].email }}</li>
                    <li>{{ Model.east_area[n - 1].telephone }}</li>
                    <li v-if="Model.east_area[n - 1].url">{{ Model.east_area[n - 1].url }}</li>
                  </ul>
                </v-tooltip>
              </div>
              <div v-else class="ma-4">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <span
                        v-bind="attrs"
                        v-on="on"
                    >
                      <img :src="Model.east_area[n - 1].img_logo.url" height="50"  alt=""/>
                    </span>
                  </template>
                  <ul>
                    <li>{{ Model.east_area[n - 1].name }}</li>
                    <li>{{ Model.east_area[n - 1].address }}</li>
                    <li>{{ Model.east_area[n - 1].email }}</li>
                    <li>{{ Model.east_area[n - 1].telephone }}</li>
                    <li v-if="Model.east_area[n - 1].url">{{ Model.east_area[n - 1].url }}</li>
                  </ul>
                </v-tooltip>
              </div>
            </v-slide-item>
          </v-slide-group>
        </v-sheet>
      </div>

      <div class="wrap d-flex" style="place-items: center">
        <img class="header-img" src="../../../assets/img/about/zone_mty_2.png"/>
        <v-sheet
          max-width="880"
          width="100%"
          class="zone-area"
          v-if="Model.west_area"
        >
          <v-slide-group
            class="agency-list"
            multiple
            show-arrows
          >
            <v-slide-item
              v-for="n in Model.west_area.length"
              :key="n"
            >
              <div v-if="n===3" class="ma-4" style="margin: 11px!important;">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <span
                        v-bind="attrs"
                        v-on="on"
                    >
                      <img :src="Model.west_area[n - 1].img_logo.url" height="55" alt=""/>
                    </span>
                  </template>
                  <ul>
                    <li>{{ Model.west_area[n - 1].name }}</li>
                    <li>{{ Model.west_area[n - 1].address }}</li>
                    <li>{{ Model.west_area[n - 1].email }}</li>
                    <li>{{ Model.west_area[n - 1].telephone }}</li>
                    <li v-if="Model.west_area[n - 1].url">{{ Model.west_area[n - 1].url }}</li>
                  </ul>
                </v-tooltip>
              </div>
              <div v-else class="ma-4">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <span
                      v-bind="attrs"
                      v-on="on"
                    >
                      <img :src="Model.west_area[n - 1].img_logo.url" height="50" alt=""/>
                    </span>
                  </template>
                  <ul>
                    <li>{{ Model.west_area[n - 1].name }}</li>
                    <li>{{ Model.west_area[n - 1].address }}</li>
                    <li>{{ Model.west_area[n - 1].email }}</li>
                    <li>{{ Model.west_area[n - 1].telephone }}</li>
                    <li v-if="Model.west_area[n - 1].url">{{ Model.west_area[n - 1].url }}</li>
                  </ul>
                </v-tooltip>
              </div>
            </v-slide-item>
          </v-slide-group>
        </v-sheet>
      </div>

      <div class="wrap d-flex" style="place-items: center">
        <img class="header-img" src="../../../assets/img/about/zone_mtg_2.png"/>
        <v-sheet
          max-width="880"
          width="100%"
          class="zone-area"
          v-if="Model.middle_area"
        >
          <v-slide-group
            class="agency-list"
            multiple
            show-arrows
          >
            <v-slide-item
              v-for="n in Model.middle_area.length"
              :key="n"
            >
              <div class="ma-4">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <span
                      v-bind="attrs"
                      v-on="on"
                    >
                      <img :src="Model.middle_area[n - 1].img_logo.url" height="50" alt=""/>
                    </span>
                  </template>
                  <ul>
                    <li>{{ Model.middle_area[n - 1].name }}</li>
                    <li>{{ Model.middle_area[n - 1].address }}</li>
                    <li>{{ Model.middle_area[n - 1].email }}</li>
                    <li>{{ Model.middle_area[n - 1].telephone }}</li>
                    <li v-if="Model.middle_area[n - 1].url">{{ Model.middle_area[n - 1].url }}</li>
                  </ul>
                </v-tooltip>
              </div>
            </v-slide-item>
          </v-slide-group>
        </v-sheet>
      </div>

      <div class="wrap d-flex" style="place-items: center">
        <img class="header-img" src="../../../assets/img/about/zone_mbc_2.png"/>
        <v-sheet
          max-width="880"
          width="100%"
          class="zone-area"
          v-if="Model.north_area"
        >
          <v-slide-group
            class="agency-list"
            multiple
            show-arrows
          >
            <v-slide-item
              v-for="n in Model.north_area.length"
              :key="n"
            >
              <div v-if="n<=2" class="ma-4"  style="margin: 10px 0px 0px 16px!important;">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <span
                      v-bind="attrs"
                      v-on="on"
                    >
                      <img :src="Model.north_area[n - 1].img_logo.url"  height="70" alt=""/>
                    </span>
                  </template>
                  <ul>
                    <li>{{ Model.north_area[n - 1].name }}</li>
                    <li>{{ Model.north_area[n - 1].address }}</li>
                    <li>{{ Model.north_area[n - 1].email }}</li>
                    <li>{{ Model.north_area[n - 1].telephone }}</li>
                    <li v-if="Model.north_area[n - 1].url">{{ Model.north_area[n - 1].url }}</li>
                  </ul>
                </v-tooltip>
              </div>
              <div v-else class="ma-4">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <span
                        v-bind="attrs"
                        v-on="on"
                    >
                      <img :src="Model.north_area[n - 1].img_logo.url" height="50" alt=""/>
                    </span>
                  </template>
                  <ul>
                    <li>{{ Model.north_area[n - 1].name }}</li>
                    <li>{{ Model.north_area[n - 1].address }}</li>
                    <li>{{ Model.north_area[n - 1].email }}</li>
                    <li>{{ Model.north_area[n - 1].telephone }}</li>
                    <li v-if="Model.north_area[n - 1].url">{{ Model.north_area[n - 1].url }}</li>
                  </ul>
                </v-tooltip>
              </div>
            </v-slide-item>
          </v-slide-group>
        </v-sheet>
      </div>

    </v-container>
  </section>
</template>

<script>
import PageHeading from "@/components/PageHeading.vue";
export default {
  components: { PageHeading },
  name: "AgentSystem",
  props: {
    Model: {
      type: Object,
      required: true,
    }
  },
  data: () => ({
    title: "HỆ THỐNG đại lý cấp 1 thành viên",
  }),
};
</script>
