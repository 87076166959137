<template>
  <v-sheet
    elevation="0"
    max-width="870"
    class="rounded overflow-hidden"
    :class="className"
  >
    <slot />
  </v-sheet>
</template>

<script>
export default {
  name: "RightContent",
  props: ["className"]
};
</script>
