<template>
  <v-container>
    <v-row
        align-content-sm="center"
        align-content-md="space-between"
        class="flex-column-reverse flex-md-row"
    >
      <v-col lg="3" md="4" cols="12">
        <sidebar>
          <sidebar-item label="Điểm bán trúng Jackpot">
            <v-sheet class="px-3">
              <v-list dense>
                <v-list-item v-for="(item, i) in sampleData" :key="i" link>
                  <v-list-item-title
                      class="text-subtitle-1 font-weight-regular"
                  >
                    <span class="text-h6 mr-1 va-c">•</span>
                    {{ item }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-sheet>
          </sidebar-item>
<!--          <sidebar-item label="Người trúng thưởng">-->
<!--            <v-sheet class="p-2 winner">-->
<!--              <v-carousel-->
<!--                  light-->
<!--                  height="300"-->
<!--                  delimiter-icon="mdi-checkbox-blank-circle"-->
<!--                  hide-delimiter-background-->
<!--                  v-if="isTheWinnersLoaded"-->
<!--              >-->
<!--                <v-carousel-item v-for="(slide, i) in theWinners" :key="i">-->
<!--                  <v-card elevation="0" class="px-2 py-4">-->
<!--                    <v-sheet-->
<!--                        color="transparent"-->
<!--                        class="mx-auto transition-swing"-->
<!--                        height="130"-->
<!--                        width="130"-->
<!--                    >-->
<!--                      <v-img-->
<!--                          :src="slide.image"-->
<!--                          height="130"-->
<!--                          width="130"-->
<!--                          class="rounded-circle"-->
<!--                      />-->
<!--                    </v-sheet>-->
<!--                    <v-sheet elevation="0" class="px-4 mt-5 text-center">-->
<!--                      <h6 class="text-md-18 text-16 text-center mb-5">-->
<!--                        {{-->
<!--                          slide.title.length > 100-->
<!--                              ? slide.title.substring(0, 120) + "..."-->
<!--                              : slide.title-->
<!--                        }}-->
<!--                      </h6>-->
<!--                      <v-btn small :to="slide.link" color="secondary" outlined>-->
<!--                        Chi tiết-->
<!--                        <v-icon class="ml-1" x-small>mdi-chevron-right</v-icon>-->
<!--                      </v-btn>-->
<!--                    </v-sheet>-->
<!--                  </v-card>-->
<!--                </v-carousel-item>-->
<!--              </v-carousel>-->
<!--              <v-skeleton-loader type="article" v-else></v-skeleton-loader>-->
<!--            </v-sheet>-->
<!--          </sidebar-item>-->
        </sidebar>
      </v-col>
      <v-col lg="9" md="8" cols="12">
        <right-content class="pa-md-8 py-8 px-3 sale">
          <div class="pt-8 pb-12">
            <page-heading :heading="title" :subHeading="description" />
          </div>
          <v-row no-gutters>
            <v-col cols="6">
              <div class="pa-1">
                <v-text-field
                    hide-details
                    dense
                    outlined
                    placeholder="Đường..."
                    label="Tìm kiếm"
                    v-model="search"
                />
              </div>
            </v-col>
            <v-col cols="3">
              <div class="pa-1">
                <v-select
                    outlined
                    dense
                    full-width
                    :items="District"
                    label="Quận/Huyện"
                    @change="filterByDistrict"
                    no-data-text="Chưa có dữ liệu"
                />
              </div>
            </v-col>
            <v-col cols="3">
              <div class="pa-1">
                <v-select
                    outlined
                    dense
                    full-width
                    :items="Area"
                    label="Tỉnh/Thành Phố"
                    @change="filterByCities"
                    no-data-text="Chưa có dữ liệu"
                />
              </div>
            </v-col>
          </v-row>
          <div class="mt-5 mt-md-9">
            <v-tabs v-model="activeTab" centered>
              <v-tab>Danh Sách Điểm Bán Hàng</v-tab>
              <v-tab>Sơ Đồ Google Map</v-tab>
            </v-tabs>
            <v-tabs-items v-model="activeTab">
              <v-tab-item class="mt-5">
                <div class="sale-list">
                  <v-sheet
                      v-if="firstLoad"
                      class="d-flex flex-column"
                      width="100%"
                  >
                    <v-skeleton-loader
                        type="table-tbody"
                        :loading="loading"
                    ></v-skeleton-loader>
                  </v-sheet>

                  <v-data-table
                      v-else
                      class="elevation-1"
                      :headers="headers"
                      :items="TableData"
                      fixed-header
                      :search="search"
                      :loading="loading"
                      no-data-text="Không có dữ liệu nào"
                      no-results-text="Không tìm thấy dữ liệu"
                      hide-default-footer
                      :page.sync="page"
                      :items-per-page="itemsPerPage"
                      @page-count="pageCount = $event"
                  >
                    <template v-slot:item.address="{ item }">
                      {{ item.full_address }}
                    </template>
                    <template v-slot:item.ward="{ item }">
                      {{ item.ward_name }}
                    </template>
                    <template v-slot:item.district="{ item }">
                      {{ item.district_name }}
                    </template>
                    <template v-slot:item.city="{ item }">
                      {{ item.province_name }}
                    </template>
                  </v-data-table>
                  <div class="text-center pt-2">
                    <v-pagination
                        v-model="page"
                        :length="pageCount"
                    ></v-pagination>
                  </div>
                </div>
              </v-tab-item>
              <v-tab-item class="mt-5">
                <GmapMap
                    ref="mapRef"
                    :center="center"
                    :zoom="12"
                    style="width: 100%; height: 500px"
                >
                  <GmapInfoWindow :options="infoOptions" :position="infoWindowPos" :opened="infoWinOpen" @closeclick="infoWinOpen=false">
                  </GmapInfoWindow>
                  <GmapMarker
                      ref="myMarker"
                      :key="index"
                      v-for="(m, index) in markers"
                      :position="google && new google.maps.LatLng(m.Lat, m.Long)"
                      :title="m.full_address"
                      :clickable="true"
                      @click="toggleInfoWindow(m, index)"
                  />
                </GmapMap>
              </v-tab-item>
            </v-tabs-items>
          </div>
        </right-content>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Sidebar from "@/components/Sidebar";
import SidebarItem from "../../components/SidebarItem.vue";
import RightContent from "../../components/RightContent.vue";
import PageHeading from "../../components/PageHeading.vue";
import {gmapApi} from "gmap-vue";
import axios from "axios";
import {apiUrl} from "@/main";

export default {
  name: "Sale",
  components: {
    Sidebar,
    SidebarItem,
    RightContent,
    PageHeading
  },
  data: () => ({
    title: "Điểm bán hàng",
    description: "Các điểm bán hàng trong hệ thống Mai Tâm Anh",
    Info: {
      meta_title: 'CÔNG TY TNHH ĐẦU TƯ MAI TÂM ANH | Danh sách ĐBH'
    },
    sampleData: [
      "71-73 Châu Văn Liêm, Quận 5, Tp.HCM",
      "Tổ 4, khu phố Thạnh Sơn, Phước Bửu, Xuyên Mộc, BR-VT",
      "Số 253 Kim Mã, Ba Đình, Hà Nội",
      "Số 9 Nguyễn Ái Quốc, Đồng Nai",
      "Số 226E Đường 3/2, phường Hưng Lợi, Quận Ninh Kiều, Cần Thơ",
      "Số 26/7A đường Khóm Trung Hưng, phường Mỹ Thới, Long Xuyên, An Giang",
      "Số 879 Nguyễn Trãi, phường 14, Quận 5, HCM",
      "100 KP 3B, Trần Quốc Toản, An Bình, Biên Hòa, Đồng Nai",
      "292A Lạc Long Quân, P. Xuân La, Q.Tây Hồ, Hà Nội",
      "Số 138 đường Nguyễn Trãi, Thị Trấn Đồng Hới, Biên Hòa, Quảng Ninh",
      "Số 202 đường Nguyễn Biểu, phường 2, Quận 5, HCM",
      "Số 139B Trần Quý Cáp, phường Tự An, thành phố Buôn Ma Thuột, Đaklak",
      "Số 221 Lê Đại Hành, Phường Thanh Bình, Ninh Bình",
      "Số 5 Huỳnh Mẫn Đạt, phường 19, quận Bình Thạnh, HCM",
      "97 đường Nguyễn Văn Thoại, An Hải Đông, Sơn Trà, Đà Nẵng",
      "Tôn Thất Đạm, Tổ 23 Ấp Hòa Long 4, thị trấn An Châu, huyện Châu Thành, An Giang",
      "Khu phố 1, Thị trấn Hương Canh, Bình Xuyên, Vĩnh Phúc",
      "Số 181 Đoàn Văn Bơ, phường 12, Quận Bình Thạnh",
      "Đường Nguyễn Việt Khái, Khóm 1, thị trấn Cái Đôi Vàm, Cà Mau",
      "1411 G04 Bùi Văn Hòa, KP7, P Long Bình, Biên Hòa, Đồng Nai",
      "Số 67 đường Hàm Tử Quan, phường Phúc Tân, Hoàn Kiếm, Hà Nội"
    ],
    theWinners: [],
    activeTab: 0,
    headers: [
      {
        text: "Địa chỉ",
        value: "full_address",
        align: "start",
        class: "table-header",
        sortable: false,
      },
      {
        text: "Phường/Xã",
        value: "ward_name",
        align: "start",
        class: "table-header",
        sortable: false,
      },
      {
        text: "Quận/Huyện",
        value: "district_name",
        align: "start",
        class: "table-header",
        sortable: false,
      },
      {
        text: "Tỉnh/Thành Phố",
        value: "province_name",
        align: "start",
        class: "table-header",
        sortable: false
      }
    ],
    center: { lat: 10.762622, lng: 106.660172 },
    markers: [
      {
        Lat: "10.769522",
        Long: "106.673747",
      },
      {
        Lat:"10.763864",
        Long:"106.619813",
      },
      {
        Lat:"10.795911",
        Long:"106.639777",
      },
      {
        Lat:"10.802754",
        Long:"106.690878",
      },
      {
        Lat:"10.793371",
        Long:"106.6912569"
      },
      {
        Lat:"10.83679392",
        Long:"106.6278157"
      },
      {
        Lat:"10.7525237",
        Long:"106.6585964"
      },
      {
        Lat:"10.757407",
        Long:"106.684719",
      },
      {
        Lat:"10.744238",
        Long:"106.645135"
      },
      {
        Lat:"10.768317",
        Long:"106.65128",
      },
      {
        Lat:"10.750812",
        Long:"106.665086"
      },
      {
        Lat:"10.756879",
        Long:"106.586218"
      }
    ],
    infoWindowPos: null,
    infoWinOpen: false,
    currentMidx: null,
    infoOptions: {
      content: '',
      //optional: offset infowindow so it visually sits nicely on top of our marker
      pixelOffset: {
        width: 0,
        height: -35
      }
    },
    Data: [],
    TableData: [],
    search: "",
    loading: true,
    firstLoad: true,
    isTheWinnersLoaded: false,
    DistrictData: {},
    District: [],
    Area: [],
    MapObj: [],
    DistrictObj: [],
    AreaObj: [],
    page: 1,
    pageCount: 0,
    itemsPerPage: 20,
  }),
  created() {
  },
  computed: {
    google: gmapApi,
  },
  mounted() {
    this.getTheWinners();
    this.initGGMap();
    axios
        .get(`${apiUrl.api}/acf/v3/options/options`)
        .then(res => {
          if (!res.data) return false;
          this.Info = res.data.acf;
        })
        .catch(err => {
          console.log(err);
        });
  },
  methods: {
    async initGGMap() {
      await this.$gmapApiPromiseLazy();
      await this.callApi();
    },
    callApi() {
      let ctx = this;
      let xhr = new XMLHttpRequest();
      xhr.open(
          "GET",
          `${apiUrl.extUrl}/v1/pos/index-public?per-page=5000`
      );
      xhr.setRequestHeader('Authorization', `Bearer ${apiUrl.saApiKey}`);
      xhr.onload = function() {
        if (xhr.status !== 200) return false;
        const data = JSON.parse(xhr.response);
        ctx.Data = data;
        ctx.TableData = data;
        const cities = [];
        const districts = {};
        const cityCodes = [...new Set(data.map(x => x.province_id))];

        cityCodes.forEach(x => {
          const cts = data.find(y => y.province_id === x);
          cities.push({ value: cts.province_id, text: cts.province_name });

          const dsts = data.filter(y => y.province_id === x);
          const districtValues = [...new Set(dsts.map(d => d))];
          districts[x] = districtValues.map(ds => ({
            value: ds.district_id,
            text: ds.district_name
          }));
        });

        ctx.DistrictData = districts;
        ctx.Area = cities;

        //TODO: Remove comment out code
        //TODO: Display marker with Longtitue and latitude (value is not Null)

        ctx.markers = ctx.Data.map(function (obj){
          return {
            Lat : obj.latitude !== null && obj.latitude,
            Long: obj.longitude !== null && obj.longitude,
            Title: obj.full_address
          }
        })

        // TODO: Remove comment out code

        ctx.firstLoad = false;
        ctx.loading = false;
      };
      xhr.send();
    },
    filterByCities(value) {
      if (value !== '-1') {
        this.District = this.DistrictData[value];
        this.TableData = this.Data.filter(x => x.province_id == value);
      } else {
        this.TableData = this.Data;
        this.District = [];
      }

      this.markers = this.TableData.map(function (obj){
        return {
          Lat : obj.latitude !== null && obj.latitude,
          Long: obj.longitude !== null && obj.longitude,
          Title: obj.full_address
        }
      });
      if (this.TableData.length > 0) {
        for (let i=0; i < this.TableData.length; i++) {
          if (this.TableData[i].latitude !== null && this.TableData[i].longitude !== null) {
            this.center = {lat: parseFloat(this.TableData[i].latitude), lng: parseFloat(this.TableData[i].longitude)};
            break;
          }
        }
      }
    },
    filterByDistrict(value) {
      this.TableData = this.Data.filter(x => x.district_id == value);
      this.markers = this.TableData.map(function (obj){
        return {
          Lat : obj.latitude !== null && obj.latitude,
          Long: obj.longitude !== null && obj.longitude,
          Title: obj.full_address
        }
      });
      if (this.TableData.length > 0) {
        for (let i=0; i < this.TableData.length; i++) {
          if (this.TableData[i].latitude !== null && this.TableData[i].longitude !== null) {
            this.center = {lat: parseFloat(this.TableData[i].latitude), lng: parseFloat(this.TableData[i].longitude)};
            break;
          }
        }
      }
    },
    getTheWinners() {
      this.isTheWinnersLoaded = false;
      const url = new URL(`${apiUrl.api}/wp/v2/posts`);
      url.searchParams.append("categories", 4);
      axios
        .get(url)
        .then(response => {
          if (!response.data) return;
          this.theWinners = response.data.map(x => {
            const {title: resTitle, img_url: resImage, id} = x;
            return {
              title: resTitle.rendered,
              image: resImage,
              link: `/tin-tuc/${id}`
            };
          });
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.isTheWinnersLoaded = true;
        });
    },
    toggleInfoWindow: function(marker, idx) {
      this.infoWindowPos = this.google && new this.google.maps.LatLng(marker.Lat, marker.Long);
      this.infoOptions.content = marker.Title;

      //check if its the same marker that was selected if yes toggle
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
      }
      //if different marker set infowindow to open and reset current marker index
      else {
        this.infoWinOpen = true;
        this.currentMidx = idx;

      }
    }
  },
  metaInfo() {
    return {
      title: this.Info.meta_title  + ' | Danh Sách ĐBH',
      description: this.Info.meta_description,
      meta: [
        {
          name: "title",
          content: this.Info.meta_title + ' | Về Chúng Tôi'
        },
        {
          name: "description",
          content: this.Info.meta_description
        },
        {
          property: "og:type",
          content: "website"
        },
        {
          property: "og:url",
          content: this.Info.meta_facebook_url
        },
        {
          property: "og:title",
          content: this.Info.meta_facebook_title
        },
        {
          property: "og:description",
          content: this.Info.meta_facebook_desciprtion
        },
        {
          property: "og:image",
          content: this.Info.meta_facebook_image
        },
        {
          property: "twitter:card",
          content: "summary_large_image"
        },
        {
          property: "twitter:url",
          content: this.Info.meta_twitter_url
        },
        {
          property: "twitter:title",
          content: this.Info.meta_twitter_title
        },
        {
          property: "twitter:description",
          content: this.Info.meta_twitter_desciprtion
        },
        {
          property: "twitter:image",
          content: this.Info.meta_twitter_image
        }
      ],
      htmlAttrs: {
        lang: "en"
      }
    };
  }
};
</script>
