<template>
  <right-content class="news py-5 py-sm-15 px-3 px-sm-5">
    <page-heading :heading="title"></page-heading>
    <v-container>
      <v-row class="news-list">
        <v-sheet v-if="firstLoad" class="d-flex flex-column" width="100%">
          <div v-for="n in 4" :key="n">
            <v-skeleton-loader
                v-bind="attrs"
                type="article"
                :loading="loading"
            ></v-skeleton-loader>
          </div>
        </v-sheet>
        <v-col cols="12" sm="6" md="12" v-for="(item, idx) in Model" :key="idx">
          <post-item
              v-show="!firstLoad"
              :title="item.title.rendered"
              :postId="item.id"
              :description="item.excerpt.rendered"
              :createdDate="item.date"
              :image="item.x_featured_media"
          ></post-item>
        </v-col>
      </v-row>
    </v-container>
    <v-pagination
        v-if="this.totalPage > 1"
        v-model="currentPage"
        :length="this.totalPage"
        circle
        color="primary"
        light
        @input="getPost"
        class="pt-4 pt-sm-8"
        next-aria-label="Tiếp"
        next-icon="mdi-arrow-right"
        prev-aria-label="Trước"
        prev-icon="mdi-arrow-left"
    >
    </v-pagination>
  </right-content>
</template>

<script>
import RightContent from "../../components/RightContent.vue";
import PageHeading from "../../components/PageHeading.vue";
import PostItem from "../../components/PostItem.vue";
import axios from "axios";
import { apiUrl } from "@/main";

export default {
  name: "NewsList",
  components: { RightContent, PageHeading, PostItem },
  data: () => ({
    title: "Tin tức",
    currentPage: 0,
    categorySlug: null,
    totalPage: 0,
    Model: [],
    loading: true,
    firstLoad: true,
    attrs: {
      class: "mb-6",
      boilerplate: true,
      elevation: 2
    }
  }),
  methods: {
    getPost: function(page = 1) {
      this.firstLoad = true;
      this.currentPage = page;
      const localCategories = sessionStorage.getItem("post-categories");
      const categoryId = JSON.parse(localCategories).find(
          x => x.slug === this.categorySlug
      )?.id;

      const url = new URL(`${apiUrl.api}/wp/v2/posts`);
      if (categoryId) {
        url.searchParams.append("categories", categoryId);
      }
      if (page > 1) {
        url.searchParams.append("page", page);
      }

      axios
          .get(url)
          .then(response => {
            if (!response.data) return false;
            this.totalPage = +response.headers['x-wp-totalpages'];
            this.Model = response.data;
          })
          .catch(err => {
            console.log(err);
          })
          .finally(() => {
            this.firstLoad = false;
          });
    },
  },
  mounted() {
    this.categorySlug = this.$route.params.categorySlug;
    this.getPost();
  },
  watch: {
    $route(to, from) {
      this.categorySlug = to.params.categorySlug;
      this.getPost();
    }
  }
};
</script>
