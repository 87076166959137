import Vue from "vue";
import VueRouter from "vue-router";
import routes from "../data/menu";
import goTo from 'vuetify/es5/services/goto'

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: "/",
  routes,
    scrollBehavior: (to, from, savedPosition) => {
        let scrollTo = 0
        if (to.hash) {
            scrollTo = to.hash
        } else if (savedPosition) {
            scrollTo = savedPosition.y
        }
        return goTo(scrollTo)
    },
});

export default router;
