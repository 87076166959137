<template>
  <v-sheet elevation="0">
    <v-img
      :height="
        $vuetify.breakpoint.lgAndUp
          ? 250
          : $vuetify.breakpoint.smAndUp
          ? 200
          : 120
      "
      width="100%"
      :src="$vuetify.breakpoint.lgAndUp ? $route.meta.banner : $vuetify.breakpoint.smAndUp ? $route.meta.bannerMd : $route.meta.bannerSm"
    />
  </v-sheet>
</template>
s
<script>
export default {
  name: "Banner",
  props: ["image"]
};
</script>
