<template>
  <section class="contact-open cmn-section">
    <v-container>
      <h2 class="ttl-section clr-white text-center mb-10">
        Liên hệ mở điểm <br />
        bán hàng Vietlott ở đâu?
      </h2>
      <div class="text-center mb-md-13 mb-8">
        <p class="sub-ttl mb-0">Ở đâu bạn muốn mở - ở đó có Mai Tâm Anh</p>
      </div>
      <a
        :href="'tel:' + Contact.call_center"
        class="v-btn btn-mdf d-flex justify-center align-center mr-auto ml-auto"
        ><img :src="Data.Call" alt="" />{{ Contact.call_center }}</a
      >
      <ul
        class="d-flex list flex-column flex-md-row mb-md-13 mb-8"
        v-if="Contact.telephone"
      >
        <li class="item" v-for="(item, idx) in Contact.telephone" :key="idx">
          <figure><img :src="item.icon.url" alt="" /></figure>
          <a
            :href="
              item.telephone_social.tel_url
                ? 'tel:' + item.telephone_social.telephone
                : item.telephone_social.telephone
            "
            :target="item.telephone_social.tel_url ? null : '_blank'"
          >
            {{
              item.telephone_social.tel_url
                ? item.telephone_social.telephone
                : "Facebook"
            }}
          </a>
        </li>
      </ul>
      <div class="direct-counseling">
        <h2 class="ttl-section fn-lg text-center">
          TƯ VẤN TRỰC TIẾP TẠI VĂN PHÒNG
        </h2>
        <v-tabs
          v-model="Data.Tab"
          align-with-title
          center-active
          class="mb-6"
          hide-slider
        >
          <v-tab
            v-for="(item, idx) in Model"
            :key="idx"
            class="tab-mdf"
            ref="Tab"
          >
            <p class="ma-0 text-14 text-md-18">{{ item.title }}</p>
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="Data.Tab">
          <v-tab-item v-for="(item, idx) in Model" :key="idx">
            <ul class="d-flex lists flex-md-row flex-column">
              <li class="d-flex mb-5 mb-lg-0">
                <p class="mb-0">
                  <v-icon small>mdi-map-marker-outline</v-icon>
                  {{ item.address }}
                </p>
              </li>
              <li class="d-flex mb-5 mb-lg-0">
                <a :href="'tel:' + item.telephone">
                  <v-icon small>mdi-phone-outline</v-icon>
                  {{ item.telephone }}
                </a>
              </li>
              <li class="d-flex mb-5 mb-lg-0">
                <a :href="'mailto:' + item.email">
                  <v-icon small>mdi-send-outline</v-icon>
                  {{ item.email }}
                </a>
              </li>
            </ul>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </v-container>
  </section>
</template>

<script>
import Call from "@/icons/icon-call.svg";
import Viper from "@/icons/icon-viper.svg";
import Phone from "@/icons/icon-telephone.svg";
import Face from "@/icons/icon-facebook.svg";
import Zalo from "@/icons/icon-zalo.svg";

export default {
  name: "ContactOpen",
  props: {
    Model: {
      type: Array,
      required: true
    },
    Contact: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    Data: {
      Call: Call,
      Icon01: Phone,
      Icon02: Viper,
      Icon03: Zalo,
      Icon04: Face,
      isActive: false,
      Tab: null,
    }
  })
};
</script>

<style scoped></style>
