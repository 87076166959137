<template>
  <section class="investments">
    <div class="mb-5 mb-md-13">
      <page-heading :heading="heading"></page-heading>
    </div>
    <div class="mb-8" v-html="data"></div>
  </section>
</template>

<script>
import PageHeading from "../../../components/PageHeading.vue";

export default {
  components: { PageHeading },
  props: ["data"],
  name: "Investments",
  data: () => ({
    heading: "Hạng Mục Đầu Tư"
  })
};
</script>
