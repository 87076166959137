<template>
  <v-sheet class="rounded overflow-hidden mb-5" elevation="0" color="#FFFFFF">
    <v-sheet
      v-if="label"
      elevation="0"
      color="#67358B"
      class="py-4 text-center"
    >
      <h3 class="text-14 text-md-16 text-xl-18 text-lg-18 white--text">
        {{ label }}
      </h3>
    </v-sheet>
    <slot />
  </v-sheet>
</template>

<script>
export default {
  name: "SidebarItem",
  props: ["label"]
};
</script>
