<template>
  <v-app>
      <section id="app-loading" v-if="firstLoad">
        <img :src="logoImage" alt="Tổng Đại Lý Mai Tâm Anh" />
      </section>
      <layout-default v-else>
        <router-view />
      </layout-default>
  </v-app>
</template>

<script>
import "reset-css";
import Logo from "@/assets/img/common/logo.jpg";
import Variable from "./sass/variables.scss";
import Style from "./sass/stylesheet.scss";
import { apiUrl } from "@/main";
import axios from "axios";
import LayoutDefault from "./layout/Default.vue";
import dvhcvn from "./data/dvhcvn.json"

export default {
  name: "App",
  components: {
    Variable,
    Style,
    LayoutDefault
  },
  data() {
    return {
      layout: `div`,
      logoImage: Logo,
      firstLoad: true,
      Info: {}
    };
  },
  metaInfo() {
    return {
      title: this.Info.meta_title,
      description: this.Info.meta_description,
      meta: [
        {
          name: "title",
          content: this.Info.meta_title
        },
        {
          name: "description",
          content: this.Info.meta_description
        },
        {
          property: "og:type",
          content: "website"
        },
        {
          property: "og:url",
          content: this.Info.meta_facebook_url
        },
        {
          property: "og:title",
          content: this.Info.meta_facebook_title
        },
        {
          property: "og:description",
          content: this.Info.meta_facebook_desciprtion
        },
        {
          property: "og:image",
          content: this.Info.meta_facebook_image
        },
        {
          property: "twitter:card",
          content: "summary_large_image"
        },
        {
          property: "twitter:url",
          content: this.Info.meta_twitter_url
        },
        {
          property: "twitter:title",
          content: this.Info.meta_twitter_title
        },
        {
          property: "twitter:description",
          content: this.Info.meta_twitter_desciprtion
        },
        {
          property: "twitter:image",
          content: this.Info.meta_twitter_image
        }
      ],
      htmlAttrs: {
        lang: "en"
      }
    };
  },
  mounted() {
    axios
      .get(`${apiUrl.api}/acf/v3/options/options`)
      .then(res => {
        if (!res.data) return false;
        this.Info = res.data.acf;
        this.firstLoad = false;
      })
      .catch(err => {
        console.log(err);
      });
    this.firstLoad = true;
    const _seft = this;
    _seft.getProducts();
    _seft.getCateogies();
    _seft.getCities();

  },
  methods: {
    getCities: () => {
      const addressData = dvhcvn.data.map(city => {
        const name = city["name"];
        const districts = [];

        city["level2s"].map(district =>
          districts.push(district["name"])
        );

        return { name, districts };
      });

      sessionStorage.setItem("address-data", JSON.stringify(addressData));
    },
    getCateogies: () => {
      axios
        .get(`${apiUrl.api}/wp/v2/categories`)
        .then(response => {
          if (!response.data) return;
          sessionStorage.setItem(
            "post-categories",
            JSON.stringify(response.data)
          );
        })
        .catch(err => {
          console.log("Can not get categories due to: ", err);
        });
    },
    getProducts: async function() {
      try {
        const response = await axios.get(`${apiUrl.page}/san-pham`);
        if (!response.data && !(response.data.length > 0)) return;
        const { product_list } = response.data[0].acf;
        const localProductList = sessionStorage.getItem("product-list");
        if (!localProductList && product_list.length > 0) {
          const url = `${apiUrl.product}?include=${product_list.join(",")}`;

          axios.get(url).then(data => {
            if (!data.data) return;
            const result = data.data.map(item => {
              const {
                id,
                title: { rendered: title },
                excerpt: description,
                acf,
                slug
              } = item;
              return { id, slug, title, description, ...acf };
            });

            sessionStorage.setItem("product-list", JSON.stringify(result));
          });
        }
      } catch (error) {
        console.log("Can not get products due to: ", error);
      }
    }
  },
};
</script>
