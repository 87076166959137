<template>
  <footer>
    <v-container>
      <v-row no-gutters>
        <v-col cols="12" md="6" sm="6" lg="3">
          <h2 class="ttl">Về Mai Tâm Anh</h2>
          <ul class="pl-8">
            <li v-for="(item, idx) in Model.abouts" :key="idx">
              <router-link :to="{name: 'Về Mai Tâm Anh', hash: item.id}">{{ item.text }}</router-link>
            </li>
            <li>
              <RouterLink to="/privacy-policy">Điều khoản & Bảo mật</RouterLink>
            </li>
          </ul>
        </v-col>
        <v-col cols="12" md="6" sm="6" lg="3">
          <h2 class="ttl">Blog</h2>
          <ul class="pl-8">
            <li v-for="(item, idx) in Model.blogs" :key="idx">
              <router-link :to="'/tin-tuc/danh-muc/' + item.slug ">{{ item.name }}</router-link>
            </li>
          </ul>
        </v-col>
        <v-col cols="12" md="6" sm="6" lg="3">
          <h2 class="ttl">Liên hệ</h2>
          <ul class="pl-8">
            <li v-for="(item, idx) in Model.contacts" :key="idx">
              <router-link :to="{ name: 'Liên hệ', hash: item.url }">{{ item.text }}</router-link>
            </li>
          </ul>
        </v-col>
        <v-col cols="12" md="6" sm="6" lg="3">
          <h2 class="ttl">Hướng dẫn cách chơi</h2>
          <div class="wrap-iframe mt-6">
            <div v-html="Youtube" class="iframe-responsive"></div>
          </div>
          <ul class="d-flex pl-16">
            <li
              v-for="(item, idx) in Model.icons"
              :key="idx"
              class="mt-0 ml-auto"
            >
              <a :href="item.href" class="icon">
                <v-img :src="item.icon" max-width="30"></v-img>
              </a>
            </li>
          </ul>
        </v-col>
      </v-row>
    </v-container>
    <div class="copyright">
      <p class="text-center mb-0">@copyright</p>
    </div>
  </footer>
</template>

<script>
import Zalo from "@/icons/icon-zalo.svg";
import Facebook from "@/icons/icon-facebook.svg";
import Viper from "@/icons/icon-viper.svg";
export default {
  name: "Footer",
  data: () => ({
    Model: {
      icons: [
        {
          icon: Viper,
          href: "#viper"
        },
        {
          icon: Zalo,
          href: "#zalo"
        },
        {
          icon: Facebook,
          href: "#facebook"
        }
      ],
      abouts: [
        {
          text: "Thông tin Công ty",
          id: "#info"
        },
        {
          text: "Hình thành và Phát triển",
          id: "#formation"
        },
        {
          text: "Tầm nhìn - Sứ mệnh",
          id: "#vision"
        },
        {
          text: "Giá trị Cốt lõi",
          id: "#core-values"
        },
        {
          text: "Cơ cấu Tổ chức",
          id: "#organizational-structure"
        },
        {
          text: "Đội ngũ Mai Tâm Anh",
          id: "#executive-board"
        },
        {
          text: "Hệ thống Đại lý",
          id: "#agent-system"
        }
      ],
      // links: [
      //   {
      //     text: "Điều khoản & Bảo mật",
      //     url: "/privacy-policy"
      //   }
      // ],
      blogs: [],
      contacts: [
        {
          text: "Hội Sở HCM",
          url: "#ho-chi-minh"
        },
        {
          text: "Miền Tây",
          url: "#mien-tay"
        },
        {
          text: "Miền Đông",
          url: "#mien-dong"
        },
        {
          text: "Miền Trung",
          url: "#mien-trung"
        },
        {
          text: "Miền Bắc",
          url: "#mien-bac"
        }
      ]
    },
    Youtube: null,
    isAbout: false,
  }),
  mounted() {
    this.Model.blogs = JSON.parse(sessionStorage.getItem("post-categories"));
    this.Youtube = sessionStorage.getItem("YoutubeFooter")
  }
};
</script>

<style scoped></style>
