<template>
  <section class="criteria">
    <div class="mb-5 mb-md-13">
      <page-heading :heading="heading"></page-heading>
    </div>
    <div class="mb-8">
      <h3 class="section-heading">
        TỔNG QUAN TRANG THIẾT BỊ TẠI ĐIỂM BÁN HÀNG VIETLOTT
      </h3>
      <v-img :src="Table"></v-img>
      <h3 class="section-heading mt-8">TRUNG TâM XỔ SỐ</h3>
      <div class="content">
        <div v-html="data.description_center" class="mb-8" />
        <v-carousel v-model="carousel" hide-delimiters>
          <v-carousel-item
              v-for="(item, i) in data.center"
              :key="i"
          >
            <v-img :src="item.url">
            </v-img>
          </v-carousel-item>
        </v-carousel>
      </div>
      <h3 class="section-heading mt-8 mb-8">
        ĐIỂM BÁN HÀNG ĐỘC LẬP
      </h3>
      <div class="content">
        <div v-html="data.description_independent" class="mb-8" />
        <v-carousel v-model="carousel2" hide-delimiters>
          <v-carousel-item
              v-for="(item, i) in data.independent_score"
              :key="i"
          >
            <v-img :src="item.url">
            </v-img>
          </v-carousel-item>
        </v-carousel>
      </div>
      <h3 class="section-heading mt-8 mb-8">
        Điểm bán kèm
      </h3>
      <div class="content">
        <div v-html="data.description_sale" class="mb-8 text-paragraph" />
        <v-carousel v-model="carousel3" hide-delimiters>
          <v-carousel-item
              v-for="(item, i) in data.point_of_sale"
              :key="i"
          >
            <v-img :src="item.url">
            </v-img>
          </v-carousel-item>
        </v-carousel>
      </div>
    </div>
  </section>
</template>

<script>
import PageHeading from "../../../components/PageHeading.vue";
import Table from "@/assets/img/registration/design/table.jpg"
import Img01 from "@/assets/img/registration/design/img01.jpg"
import Img02 from "@/assets/img/registration/design/img02.jpg"
import Img03 from "@/assets/img/registration/design/img03.jpg"
import Img04 from "@/assets/img/registration/design/img04.jpg"
import Img05 from "@/assets/img/registration/design/img05.jpg"
import Img06 from "@/assets/img/registration/design/img06.jpg"

export default {
  components: { PageHeading },
  name: "Design",
  props: {
    data: {
      type: Object,
    }
  },
  data: () => ({
    heading: "LAYOUT Điểm Bán Hàng",
    Table: Table,
    Img01: Img01,
    Img02: Img02,
    Img03: Img03,
    Img04: Img04,
    Img05: Img05,
    Img06: Img06,
    carousel: 0,
    carousel2: 0,
    carousel3: 0,
    colors: [
      'primary',
      'secondary',
      'yellow darken-2',
      'red',
      'orange',
    ],
  })
};
</script>
