<template>
  <section class="register-and-manager cmn-section">
    <v-container>
      <v-row>
        <v-col cols="12" md="6" class="text-center pr-md-12">
          <h2 class="ttl-section mt-10 mb-8">
            Bạn muốn đăng ký<br />mở mới Điểm Bán Hàng Vietlott?
          </h2>
          <p class="sub-ttl">Tìm hiểu tại đây</p>
          <div class="section-img mr-auto ml-auto mb-5"><ArrowDown /></div>
          <v-btn
            tag="a"
            width="200px"
            depressed
            color="orange"
            to="/dang-ky-diem-ban"
            class="mdf-btn"
          >
            Chi Tiết
            <v-icon color="white" class="absolute">
              mdi-menu-right
            </v-icon>
          </v-btn>
          <ul
            class="d-flex flex-wrap text-left ml-auto mr-auto mt-8 list-find-out"
          >
            <li v-for="(item, idx) in FindOut" :key="idx" class="col-6">
              <IconChecked class="checked" />{{ item.txt }}
            </li>
          </ul>
        </v-col>
        <v-col cols="12" md="6" class="pl-md-12">
          <div class="wrap-top pb-8">
            <h2 class="ttl text-center">Quản lý bán hàng</h2>
            <div class="form-wrap">
              <div class="input-wrap has-icon">
                <div class="input-wrap-inner mb-3">
                  <span class="icon">
                    <v-img></v-img>
                  </span>
                  <input type="text" id="user-name" name="user-name" placeholder="Tên đăng nhập" v-model="Model.UserName" />
                  <span class="focus-border">
                    <i></i>
                  </span>
                </div>
                <div class="input-wrap-inner mb-3">
                  <span class="icon">
                    <v-img></v-img>
                  </span>
                  <input type="password" id="password" name="password" placeholder="Mật khẩu" v-model="Model.PassWord"/>
                  <span class="focus-border">
                    <i></i>
                  </span>
                </div>
              </div>
              <v-btn
                width="158"
                max-width="158"
                height="40"
                depressed
                color="white"
                class="ml-auto mr-auto d-block btn-mdf mb-3"
                @click="loginManager"
              >
                Đăng nhập
              </v-btn>
              <div class="text-center">
<!--                <a class="forgot-password" href="javascript:void(0)">Quên mật khẩu?</a>-->
              </div>
            </div>
          </div>
          <div class="wrap-bottom">
            <ul class="d-flex flex-wrap text-left ml-auto mr-auto list-manager">
              <li v-for="(item, idx) in Manager" :key="idx" class="col-6">
                <IconChecked class="checked" />{{ item.txt }}
              </li>
            </ul>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>

// Component
import ArrowDown from "@/icons/ArrowDown";
import IconChecked from "@/icons/IconChecked";


export default {
  name: "RegisterAndManager",
  components: {
    IconChecked,
    ArrowDown,
  },
  props: {
    Model: {
      type: Object,
      require: true
    }
  },
  data: () => ({
    FindOut: [
      {
        txt: "Mức hoa hồng"
      },
      {
        txt: "Vốn"
      },
      {
        txt: "Điều kiện"
      },
      {
        txt: "Quy trình"
      }
    ],
    Manager: [
      {
        txt: "Doanh số"
      },
      {
        txt: "Hoa hồng"
      },
      {
        txt: "Hạn mức bán hàng"
      },
      {
        txt: "Vé thưởng, Vé huỷ"
      }
    ],
  }),
  methods: {
    loginManager: function () {
      this.$emit('login')
    },
  }
};
</script>

<style scoped lang="scss"></style>
