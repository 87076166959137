<template>
  <v-app>
    <Header />
    <v-main id="main">
      <v-sheet color="#F6F7F9" elevation="0">
        <v-app-bar />
        <Banner v-if="$route.path !== '/'" />
        <BreadCrumbs v-if="$route.path !== '/'" />
        <v-sheet
          elevation="0"
          color="transparent"
          :class="$route.path !== '/' && 'pt-5'"
        >
          <slot />
        </v-sheet>
      </v-sheet>
    </v-main>
    <Contact />
    <Footer />
    <v-btn
      v-scroll="onScroll"
      v-show="fab"
      fab
      dark
      fixed
      bottom
      right
      color="primary"
      @click="toTop"
    >
      <v-icon>mdi-chevron-up</v-icon>
    </v-btn>
  </v-app>
</template>

<script>
import Header from "@/components/Shared/Header";
import Footer from "@/components/Shared/Footer";
import Contact from "@/components/Shared/Contact";
import BreadCrumbs from "@/components/BreadCrumbs.vue";
import Banner from "@/components/Banner.vue";

export default {
  name: `LayoutDefault`,
  components: {
    Header,
    Footer,
    Contact,
    Banner,
    BreadCrumbs
  },
  data: () => ({
    fab: false
  }),
  methods: {
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 100;
    },
    toTop() {
      this.$vuetify.goTo(0);
    }
  }
};
</script>
