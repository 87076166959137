<template>
  <section class="contact-shared text-center cmn-section">
    <v-container>
      <h2 class="title-02 mb-7">Tổng Đại Lý Mai Tâm Anh</h2>
      <p class="d-flex align-center justify-center address mb-8">
        <img :src="Maker" alt="" class="hidden-md-and-down" /> Tầng 14, Tòa nhà
        LIM 3, 29A Nguyễn Đình Chiểu, Phường Đa Kao, Quận 1, TP.HCM
      </p>
      <div
        class="d-flex justify-center flex-md-row flex-column full-width align-center"
      >
        <v-btn
          tag="a"
          href="/lien-he"
          depressed
          color="orange"
          :width="$vuetify.breakpoint.mdAndUp ? '30%' : '100%'"
          class="btn-mdf darken-1"
        >
          <img :src="PhoneLg" alt="" />
          Liên Hệ
        </v-btn>
        <v-btn
            tag="a"
            href="https://songanh.maitamanh.vn"
            depressed
            color="orange"
            style="text-transform: lowercase"
            :width="$vuetify.breakpoint.mdAndUp ? '40%' : '100%'"
            class="btn-mdf"
        >
          <img :src="SongAnh" alt="" width="33"/>
          songanh.maitamanh.vn
        </v-btn>
        <v-btn
          tag="a"
          href="tel:1900 633 035"
          depressed
          color="orange"
          class="btn-mdf darken-1"
          :width="$vuetify.breakpoint.mdAndUp ? '30%' : '100%'"
        >
          <img :src="Call" alt="" />
          1900 633 035
        </v-btn>
      </div>
    </v-container>
  </section>
</template>

<script>
import Maker from "@/icons/icon-maker.svg";
import PhoneLg from "@/icons/icon-phone-lg.svg";
import Fly from "@/icons/icon-send.svg";
import Call from "@/icons/icon-call.svg";
import SongAnh from "@/assets/img/common/logo_song_anh_f6be3c.png";
export default {
  name: "Contact",
  data: () => ({
    Maker: Maker,
    PhoneLg: PhoneLg,
    Fly: Fly,
    Call: Call,
    SongAnh: SongAnh,
  })
};
</script>

<style scoped></style>
