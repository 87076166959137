<template>
  <v-container>
    <v-row>
      <v-col md="3" cols="12">
        <sidebar>
          <sidebar-item label="Danh Mục">
            <v-sheet v-if="isLoading" class="d-flex flex-column" width="100%">
              <div v-for="n in 4" :key="n">
                <v-skeleton-loader
                  type="list-item"
                  :loading="loading"
                ></v-skeleton-loader>
              </div>
            </v-sheet>
            <v-sheet class="px-3" v-else>
              <v-list dense>
                <v-list-item
                  v-for="(item, i) in categories"
                  :key="i"
                  link
                  :to="`/tin-tuc/danh-muc/${item.slug}`"
                >
                  <v-list-item-title
                    class="text-subtitle-1 font-weight-regular"
                  >
                    <v-icon small class="text-h6 mr-1 va-c">
                      mdi-menu-right
                    </v-icon>
                    {{ item.name }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-sheet>
          </sidebar-item>
          <sidebar-item label="Bài Viết Hàng Đầu">
            <v-sheet v-if="isLoading" class="d-flex flex-column" width="100%">
              <div v-for="n in 4" :key="n">
                <v-skeleton-loader
                  type="list-item"
                  :loading="loading"
                ></v-skeleton-loader>
              </div>
            </v-sheet>
            <v-sheet class="px-3" v-else>
              <v-list dense v-if="topPost">
                <div v-for="(item, i) in topPost" :key="i">
                  <v-list-item
                    v-if="item.sticky"
                    link
                    :to="`/tin-tuc/${item.id}`"
                  >
                    <v-list-item-title
                      class="text-subtitle-1 font-weight-regular"
                    >
                      {{ item.title.rendered }}
                    </v-list-item-title>
                  </v-list-item>
                </div>
              </v-list>
            </v-sheet>
          </sidebar-item>
        </sidebar>
      </v-col>
      <v-col md="9" cols="12">
        <v-card flat elevation="0">
          <router-view></router-view>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SidebarItem from "../../components/SidebarItem.vue";
import Sidebar from "../../components/Sidebar.vue";
import axios from "axios";
import { apiUrl } from "@/main";

export default {
  components: { SidebarItem, Sidebar },
  name: "News",
  data: () => ({
    topPost: [],
    categories: [],
    isLoading: true,
    loading: true,
    Info: {
      meta_title: 'CÔNG TY TNHH ĐẦU TƯ MAI TÂM ANH | Blog'
    }
  }),
  methods: {
    getPost: function() {
      axios
        .get(`${apiUrl.api}/wp/v2/posts`)
        .then(response => {
          if (!response.data) return;
          this.topPost = response.data;
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  },
  mounted() {
    this.getPost();
    this.categories = JSON.parse(sessionStorage.getItem("post-categories"));

    axios
        .get(`${apiUrl.api}/acf/v3/options/options`)
        .then(res => {
          if (!res.data) return false;
          this.Info = res.data.acf;
        })
        .catch(err => {
          console.log(err);
        });
  },
  metaInfo() {
    return {
      title: this.Info.meta_title  + ' | Blog',
      description: this.Info.meta_description,
      meta: [
        {
          name: "title",
          content: this.Info.meta_title
        },
        {
          name: "description",
          content: this.Info.meta_description
        },
        {
          property: "og:type",
          content: "website"
        },
        {
          property: "og:url",
          content: this.Info.meta_facebook_url
        },
        {
          property: "og:title",
          content: this.Info.meta_facebook_title
        },
        {
          property: "og:description",
          content: this.Info.meta_facebook_desciprtion
        },
        {
          property: "og:image",
          content: this.Info.meta_facebook_image
        },
        {
          property: "twitter:card",
          content: "summary_large_image"
        },
        {
          property: "twitter:url",
          content: this.Info.meta_twitter_url
        },
        {
          property: "twitter:title",
          content: this.Info.meta_twitter_title
        },
        {
          property: "twitter:description",
          content: this.Info.meta_twitter_desciprtion
        },
        {
          property: "twitter:image",
          content: this.Info.meta_twitter_image
        }
      ],
      htmlAttrs: {
        lang: "en"
      }
    };
  }
};
</script>
