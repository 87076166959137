//Banner
import BannerRegistration from "@/assets/banner/dang-ky-diem-ban-hang.png";
import BannerPointOfSale from "@/assets/banner/diem-ban-hang.png";
import BannerContact from "@/assets/banner/lien-he.png";
import BannerProduct from "@/assets/banner/san-pham.png";
import PolicyBanner from "@/assets/banner/ve-chung-toi.gif";
import MobileAppBanner from "@/assets/banner/ve-chung-toi.gif";
import BannerAbout from "@/assets/banner/ve-chung-toi.gif";
import BannerNews from "@/assets/banner/tin-tuc.png";
import BannerAboutSm from "@/assets/banner/ve-chung-toi-mobile.gif";
import Agency from "@/views/Manager/Agency";
import AgencyDetails from "@/views/Manager/AgencyDetails";
import About from "../views/About";
import Contact from "../views/Contact";
import Home from "../views/Home";
import ManagerSale from "../views/Manager";
import Product from "../views/Product";
import MobileApp from "../views/MobileApp";
import Policy from "../views/Policy";
import ProductDetails from "../views/Product/Details.vue";
import Registration from "../views/Registration";
import Sale from "../views/Sale";

import Post from "../views/Post";
import PostDetails from "../views/Post/Details.vue";
import PostList from "../views/Post/List.vue";

export default [
    {
        path: "/",
        component: Home,
        name: "Trang chủ",
        exact: true,
        meta: {
            breadCrumb: "Trang chủ"
        },
        type: "mainMenu"
    },
    {
        path: "/ve-chung-toi",
        component: About,
        name: "Về Mai Tâm Anh",
        meta: {
            breadCrumb: "Về Mai Tâm Anh",
            banner: BannerAbout,
            bannerMd: BannerAboutSm,
            bannerSm: BannerAboutSm,
        },
        type: "mainMenu"
    },
    {
        path: "/san-pham",
        component: Product,
        name: "Sản phẩm",
        meta: {
            breadCrumb: "Sản phẩm",
            banner: BannerProduct,
            bannerMd: BannerProduct,
            bannerSm: BannerProduct,
        },
        type: "mainMenu"
    },
    {
        path: "/san-pham/:productSlug",
        component: ProductDetails,
        name: "Chi tiết sản phẩm",
        meta: {
            breadCrumb: "Sản phẩm",
            banner: BannerProduct,
            bannerMd: BannerProduct,
            bannerSm: BannerProduct,
        }
    },
    {
        path: "/tin-tuc",
        component: Post,
        name: "Blog",
        type: "mainMenu",
        meta: {
            breadCrumb: "Tin tức"
        },
        children: [
            {
                path: "/",
                component: PostList,
                meta: {
                    banner: BannerNews
                }
            },
            {
                path: ":postId",
                component: PostDetails,
                meta: {
                    banner: BannerNews
                }
            },
            {
                path: "danh-muc/:categorySlug",
                component: PostList,
                meta: {
                    banner: BannerNews
                }
            },
            {
                path: "tac-gia/:authorId",
                component: PostList,
                meta: {
                    banner: BannerNews
                }
            }
        ]
    },
    {
        path: "/quan-ly-diem-ban-hang",
        component: ManagerSale,
        name: "Quản lý ĐBH",
        meta: {
            breadCrumb: "Quản lý ĐBH",
            banner: BannerPointOfSale,
            bannerMd: BannerPointOfSale,
            bannerSm: BannerPointOfSale
        },
        type: "mainMenu"
    },
    {
        path: "/chinh-sach-dai-ly/:agencyId",
        component: AgencyDetails,
        name: "Chính sách đại lý",
        meta: {
            breadCrumb: "Chính sách đại lý",
            banner: BannerPointOfSale,
            bannerMd: BannerPointOfSale,
            bannerSm: BannerPointOfSale
        }
    },
    {
        path: "/chinh-sach-dai-ly",
        component: Agency,
        name: "Chính sách đại lý",
        meta: {
            breadCrumb: "Chính sách đại lý",
            banner: BannerPointOfSale,
            bannerMd: BannerPointOfSale,
            bannerSm: BannerPointOfSale
        }
    },
    // {
    //     path: "/mobile-app",
    //     component: MobileApp,
    //     name: "Ứng dụng di động",
    //     meta: {
    //         breadCrumb: "Ứng dụng di động",
    //         banner: MobileAppBanner,
    //         bannerMd: MobileAppBanner,
    //         bannerSm: MobileAppBanner
    //     },
    //     type: "smallMenu"
    // },
    {
        path: "/privacy-policy",
        component: Policy,
        name: "Điều khoản & Bảo mật",
        meta: {
            breadCrumb: "Điều khoản & Bảo mật",
            banner: PolicyBanner,
            bannerMd: PolicyBanner,
            bannerSm: PolicyBanner
        }
    },
    {
        path: "/diem-ban-hang",
        component: Sale,
        name: "Điểm bán hàng",
        meta: {
            breadCrumb: "Điểm bán hàng",
            banner: BannerPointOfSale,
            bannerMd: BannerPointOfSale,
            bannerSm: BannerPointOfSale
        },
        type: "smallMenu"
    },
    {
        path: "/lien-he",
        component: Contact,
        name: "Liên hệ",
        meta: {
            breadCrumb: "Liên hệ",
            banner: BannerContact
        },
        type: "smallMenu"
    },
    {
        path: "/dang-ky-diem-ban",
        component: Registration,
        name: "Đăng ký điểm bán hàng",
        meta: {
            breadCrumb: "Đăng ký điểm bán hàng",
            banner: BannerRegistration
        },
        type: "buttonMenu"
    }
];
