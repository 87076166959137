<template>
  <v-card elevation="0" :to="link" class="product-item">
    <v-img
      width="230"
      height="150"
      :src="image"
      contain
      position="center"
    ></v-img>
    <div class="product-item-content">
      <h3
        class="product-item__title text-16 text-sm-20 text-lg-24"
        v-html="title"
      ></h3>
      <div class="product-item__desc" v-html="description"></div>
      <div class="product-item__actions">
        <v-btn
          :small="$vuetify.breakpoint.smAndDown"
          :width="$vuetify.breakpoint.smAndDown ? 130 : 160"
          depressed
          outlined
          color="secondary"
          :to="`/san-pham/${slug}`"
          class="mdf-btn"
        >
          Chi tiết
          <v-icon color="secondary" class="absolute">
            mdi-menu-right
          </v-icon>
        </v-btn>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "ProductItem",
  props: ["id", "slug", "title", "description", "link", "image", "isTopProduct"]
};
</script>

<style></style>
