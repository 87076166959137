<template>
  <section class="cmn-section core-values" id="core-values">
    <v-container>
      <page-heading :heading="title" />
      <div class="core-values__content mt-10 mt-md-10 text-center">
        <img
          :src="$vuetify.breakpoint.mdAndDown ? mainContentSP : mainContent"
        />
      </div>
    </v-container>
  </section>
</template>

<script>
import _mainContent from "@/assets/img/about/core-values-new.png";
import _mainContentSP from "@/assets/img/about/core-values-sp.png";
import PageHeading from "@/components/PageHeading";
export default {
  name: "CoreValues",
  components: { PageHeading },
  data: () => ({
    title: "Giá trị cốt lõi",
    mainContent: _mainContent,
    mainContentSP: _mainContentSP
  })
};
</script>
