<template>
  <v-container class="my-0 py-10 home-blog">
    <div class="mb-5 mb-md-10 " style="margin-top: 30px;">
      <h2 class="ttl-section text-center mb-2 mb-md-5">Blog</h2>
    </div>
    <section v-if="Model">
      <v-row class="mb-5">
        <v-col cols="12" sm="2" md="3" v-for="(item, i) in Model" :key="i">
          <v-card elevation="0" :to="'/tin-tuc/' + item.id">
            <v-img class="mb-3" height="200" :src="item.x_featured_media" />
            <h6
              class="text-md-20 text-16 font-weight-bold mb-2 mb-md-4"
              v-html="item.title.rendered"
            ></h6>
            <div
              :style="{
                height: '63px',
                overflow: 'hidden',
                marginBottom: '12px'
              }"
              class="text-12 text-md-14"
              v-html="item.excerpt.rendered"
            ></div>
          </v-card>
        </v-col>
      </v-row>
      <div class="text-center">
        <v-btn
          tag="a"
          width="200px"
          height="50px"
          depressed
          color="orange"
          to="/tin-tuc"
          class="mdf-btn"
        >
          Xem Thêm
          <v-icon color="white" class="absolute">
            mdi-menu-right
          </v-icon>
        </v-btn>
      </div>
    </section>
  </v-container>
</template>

<script>
export default {
  name: "Blog",
  props: {
    Model: {
      type: Array,
      required: true
    }
  },
};
</script>
