<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="18.099" height="18.508" viewBox="0 0 18.099 18.508">
    <g id="Component_2_4" data-name="Component 2 – 4" transform="translate(1 1.385)">
      <path id="Path_43" data-name="Path 43" d="M251.392,481.645l3.065,2.7,7.053-10.719" transform="translate(-245.796 -473.625)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
      <path id="Path_271" data-name="Path 271" d="M7.061,0H-2.987V16h16V7.691" transform="translate(2.987 0.123)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="2"/>
    </g>
  </svg>
</template>

<script>
export default {
name: "IconChecked"
}
</script>

<style scoped>

</style>