import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#67358b",
        secondary: "#F6BE3C",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",

        red: "#d81921",
        semilight: "#f6f7f9",
        white: "#ffffff",
        semiblack: "#2c2c2c",
        orange: "#F6BE3C",
        darkorange: "#EC7603",
        grey: "#d2d2d2",
        darkblue: "#4e4ea8",
        lightgrey: "#F7F7F7",
        black: "#000000"
      }
    }
  }
});
