<template>
  <section class="faq">
    <div class="pb-13">
      <page-heading :heading="title" />
    </div>
    <v-expansion-panels>
      <v-expansion-panel v-for="(item, i) in data" :key="i" class="mb-5">
        <v-expansion-panel-header>
          <div class="d-flex align-center">
            <div :style="{ flex: '0 0 30px', marginRight: '10px' }">
              <v-img width="30" height="30" :src="questionIcon"></v-img>
            </div>
            <p class="text-md-16 text-13 ma-0">
              <b>
                {{ item.question }}
              </b>
              <v-divider class="mt-2" />
            </p>
          </div>
          <template v-slot:actions>
            <v-icon color="primary">
              mdi-plus
            </v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div class="d-flex">
            <div :style="{ flex: '0 0 30px', marginRight: '10px' }">
              <v-img width="30" height="30" :src="answerIcon"></v-img>
            </div>
            <div class="text-md-16 text-13 ma-0" v-html="item.answer" />
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </section>
</template>
<script>
import PageHeading from "@/components/PageHeading";
import _question from "@/assets/img/registration/faq/question.png";
import _answer from "@/assets/img/registration/faq/answer.png";

export default {
  components: { PageHeading },
  props: ["data"],
  name: "FAQ",
  data: () => ({
    title: "câu hỏi thường gặp",
    questionIcon: _question,
    answerIcon: _answer
  })
};
</script>
