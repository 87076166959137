<template>
  <section class="formation cmn-section" id="formation">
    <v-container>
      <page-heading :heading="title"></page-heading>
      <v-sheet v-if="firstLoad" class="d-flex flex-column" width="100%">
        <div v-for="n in 4" :key="n">
          <v-skeleton-loader
            type="list-item-three-line"
            :loading="loading"
          ></v-skeleton-loader>
        </div>
      </v-sheet>
      <div v-if="!firstLoad" class="description">
        <div class="wrap">
          <h3 class="ttl mb-6">Thành lập</h3>
          <p>{{ Model.establish }}</p>
        </div>
        <div class="wrap">
          <h3 class="ttl mb-6">Định hướng phát triển</h3>
          <p>{{ Model.oriented_development }}</p>
        </div>
        <div class="wrap" id="open-market">
          <h3 class="ttl mb-6">Mở rộng thị trường</h3>
          <v-sheet
            class="mx-auto relative custom"
            max-width="1170"
          >
            <v-slide-group
              multiple
              show-arrows
              class="time-line"
              v-if="Model.market_expansion"
            >
              <v-slide-item
                v-for="n in Model.market_expansion.years_of_development.length"
                :key="n"
              >
                <div
                  class="mx-2 d-flex flex-column"
                >
                  <span class="year">{{ Model.market_expansion.years_of_development[n - 1].year }}</span>
                  <span>{{ Model.market_expansion.years_of_development[n - 1].short_description }}</span>
                  <div style="max-width: 300px; word-break: break-word; white-space: pre-wrap" class="content" v-html="Model.market_expansion.years_of_development[n - 1].description"/>
                </div>
              </v-slide-item>
            </v-slide-group>
          </v-sheet>
        </div>
        <div class="wrap">
          <h3 class="ttl mb-6">Kết nối hệ thống - Hoàn thiện tổ chức</h3>
          <p v-html="Model.system_connection" />
        </div>
        <div class="wrap">
          <h3 class="ttl mb-6">Phát triển bền vững cùng đối tác</h3>
          <p v-html="Model.sustainable_development"></p>
        </div>
      </div>
    </v-container>
  </section>
</template>

<script>
import PageHeading from "@/components/PageHeading";
export default {
  name: "FormationAndDevelopment",
  components: {
    PageHeading
  },
  props: {
    Model: {
      type: Object,
      required: true
    },
    firstLoad: {
      type: Boolean
    }
  },
  data: () => ({
    title: "HÌNH THÀNH VÀ PHÁT TRIỂN",
    loading: true,
  }),
}
</script>

<style scoped>

</style>
