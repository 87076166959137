<template>
  <section class="manage-store">
    <v-container>
      <v-row>
        <v-col md="3" cols="12">
          <v-sheet v-if="isLoading" class="d-flex flex-column" width="100%">
            <div v-for="n in 4" :key="n">
              <v-skeleton-loader
                type="list-item"
                :loading="loading"
              ></v-skeleton-loader>
            </div>
          </v-sheet>
          <sidebar v-else>
            <sidebar-item>
              <v-tabs vertical v-model="tab" class="custom">
                <v-tab to="/quan-ly-diem-ban-hang">
                  {{ heading }}
                </v-tab>
                <v-tab to="/chinh-sach-dai-ly">
                  {{ heading2 }}
                </v-tab>
              </v-tabs>
            </sidebar-item>
          </sidebar>
        </v-col>
        <v-col md="9" cols="12">
          <v-sheet v-if="isLoading" class="d-flex flex-column" width="100%">
            <div v-for="n in 4" :key="n">
              <v-skeleton-loader
                type="article"
                :loading="loading"
              ></v-skeleton-loader>
            </div>
          </v-sheet>
          <v-card flat elevation="0" class="pt-10" v-else>
            <page-heading :heading="heading2"></page-heading>
            <v-col
              cols="12"
              sm="6"
              md="12"
              v-for="(item, idx) in Model"
              :key="idx"
            >
              <post-item
                :title="item.title.rendered"
                :description="item.content.rendered"
                :createdDate="item.date"
                :image="item.img_url"
                :link="`/chinh-sach-dai-ly/${item.id}`"
              ></post-item>
            </v-col>
            <v-pagination
              v-if="Model.length > 5"
              v-model="currentPage"
              :length="6"
              circle
              color="primary"
              light
              class="pt-4 pt-sm-8"
              next-aria-label="Tiếp"
              next-icon="mdi-arrow-right"
              prev-aria-label="Trước"
              prev-icon="mdi-arrow-left"
            >
            </v-pagination>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import Img01 from "@/assets/img/manager/dbh01.jpg";
import Img02 from "@/assets/img/manager/dbh02.jpg";

import PageHeading from "@/components/PageHeading";
import axios from "axios";
import { apiUrl } from "@/main";
import Sidebar from "@/components/Sidebar";
import SidebarItem from "@/components/SidebarItem";
import PostItem from "@/components/PostItem";

export default {
  name: "Agency",
  components: { PageHeading, Sidebar, SidebarItem, PostItem },
  data: () => ({
    Img01: Img01,
    Img02: Img02,
    title: "Quản lý điểm bán hàng",
    Model: "",
    heading: "Quản lý điểm bán hàng",
    heading2: "Chính sách đại lý",
    tab: null,
    isLoading: true,
    loading: true,
    currentPage: 0
  }),
  mounted() {
    axios
      .get(`${apiUrl.api}/wp/v2/agency_policy`)
      .then(response => {
        if (!response.data) return false;
        this.Model = response.data;
        this.isLoading = false;
      })
      .catch(err => {
        console.log(err);
      });
  }
};
</script>
