<template>
  <div>
    <h2 class="page-heading text-22 text-md-22 text-lg-26 text-xl-30">
      {{ heading }}
    </h2>
    <p class="page-subheading text-14 text-md-14 text-lg-16">
      {{ subHeading }}
    </p>
  </div>
</template>

<script>
export default {
  name: "PageHeading",
  props: ["heading", "subHeading"]
};
</script>
