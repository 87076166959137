var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"cmn-section agent-system",attrs:{"id":"agent-system"}},[_c('v-container',[_c('page-heading',{attrs:{"heading":_vm.title}}),_c('div',{staticClass:"wrap d-flex",staticStyle:{"place-items":"center"}},[_c('img',{staticClass:"header-img",attrs:{"src":require("../../../assets/img/about/zone_hcm_2.png")}}),(_vm.Model.ho_chi_minh_city)?_c('v-sheet',{staticClass:"zone-area",attrs:{"max-width":"880"}},[_c('v-slide-group',{staticClass:"agency-list",attrs:{"multiple":"","show-arrows":""}},_vm._l((_vm.Model.ho_chi_minh_city.length),function(n){return _c('v-slide-item',{key:n},[_c('div',{staticClass:"ma-4",staticStyle:{"margin-left":"5px!important","margin-right":"5px!important"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('img',{attrs:{"src":_vm.Model.ho_chi_minh_city[n - 1].img_logo.url,"height":"50","alt":""}})])]}}],null,true)},[_c('ul',[_c('li',[_vm._v(_vm._s(_vm.Model.ho_chi_minh_city[n - 1].name))]),_c('li',[_vm._v(_vm._s(_vm.Model.ho_chi_minh_city[n - 1].address))]),_c('li',[_vm._v(_vm._s(_vm.Model.ho_chi_minh_city[n - 1].email))]),_c('li',[_vm._v(_vm._s(_vm.Model.ho_chi_minh_city[n - 1].telephone))]),(_vm.Model.ho_chi_minh_city[n - 1].url)?_c('li',[_vm._v(_vm._s(_vm.Model.ho_chi_minh_city[n - 1].url))]):_vm._e()])])],1)])}),1)],1):_vm._e()],1),_c('div',{staticClass:"wrap d-flex",staticStyle:{"place-items":"center"}},[_c('img',{staticClass:"header-img",attrs:{"src":require("../../../assets/img/about/zone_mdg.png")}}),(_vm.Model.east_area)?_c('v-sheet',{staticClass:"zone-area",attrs:{"max-width":"880","width":"100%"}},[_c('v-slide-group',{staticClass:"agency-list",attrs:{"multiple":"","show-arrows":""}},_vm._l((_vm.Model.east_area.length),function(n){return _c('v-slide-item',{key:n},[(n===1)?_c('div',{staticClass:"ma-4",staticStyle:{"margin":"0px!important"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('img',{attrs:{"src":_vm.Model.east_area[n - 1].img_logo.url,"height":"80","alt":""}})])]}}],null,true)},[_c('ul',[_c('li',[_vm._v(_vm._s(_vm.Model.east_area[n - 1].name))]),_c('li',[_vm._v(_vm._s(_vm.Model.east_area[n - 1].address))]),_c('li',[_vm._v(_vm._s(_vm.Model.east_area[n - 1].email))]),_c('li',[_vm._v(_vm._s(_vm.Model.east_area[n - 1].telephone))]),(_vm.Model.east_area[n - 1].url)?_c('li',[_vm._v(_vm._s(_vm.Model.east_area[n - 1].url))]):_vm._e()])])],1):(n===3)?_c('div',{staticClass:"ma-4",staticStyle:{"margin-bottom":"0px!important"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('img',{attrs:{"src":_vm.Model.east_area[n - 1].img_logo.url,"height":"60","alt":""}})])]}}],null,true)},[_c('ul',[_c('li',[_vm._v(_vm._s(_vm.Model.east_area[n - 1].name))]),_c('li',[_vm._v(_vm._s(_vm.Model.east_area[n - 1].address))]),_c('li',[_vm._v(_vm._s(_vm.Model.east_area[n - 1].email))]),_c('li',[_vm._v(_vm._s(_vm.Model.east_area[n - 1].telephone))]),(_vm.Model.east_area[n - 1].url)?_c('li',[_vm._v(_vm._s(_vm.Model.east_area[n - 1].url))]):_vm._e()])])],1):(n===4)?_c('div',{staticClass:"ma-4",staticStyle:{"margin":"13px!important"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('img',{attrs:{"src":_vm.Model.east_area[n - 1].img_logo.url,"height":"60","alt":""}})])]}}],null,true)},[_c('ul',[_c('li',[_vm._v(_vm._s(_vm.Model.east_area[n - 1].name))]),_c('li',[_vm._v(_vm._s(_vm.Model.east_area[n - 1].address))]),_c('li',[_vm._v(_vm._s(_vm.Model.east_area[n - 1].email))]),_c('li',[_vm._v(_vm._s(_vm.Model.east_area[n - 1].telephone))]),(_vm.Model.east_area[n - 1].url)?_c('li',[_vm._v(_vm._s(_vm.Model.east_area[n - 1].url))]):_vm._e()])])],1):_c('div',{staticClass:"ma-4"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('img',{attrs:{"src":_vm.Model.east_area[n - 1].img_logo.url,"height":"50","alt":""}})])]}}],null,true)},[_c('ul',[_c('li',[_vm._v(_vm._s(_vm.Model.east_area[n - 1].name))]),_c('li',[_vm._v(_vm._s(_vm.Model.east_area[n - 1].address))]),_c('li',[_vm._v(_vm._s(_vm.Model.east_area[n - 1].email))]),_c('li',[_vm._v(_vm._s(_vm.Model.east_area[n - 1].telephone))]),(_vm.Model.east_area[n - 1].url)?_c('li',[_vm._v(_vm._s(_vm.Model.east_area[n - 1].url))]):_vm._e()])])],1)])}),1)],1):_vm._e()],1),_c('div',{staticClass:"wrap d-flex",staticStyle:{"place-items":"center"}},[_c('img',{staticClass:"header-img",attrs:{"src":require("../../../assets/img/about/zone_mty_2.png")}}),(_vm.Model.west_area)?_c('v-sheet',{staticClass:"zone-area",attrs:{"max-width":"880","width":"100%"}},[_c('v-slide-group',{staticClass:"agency-list",attrs:{"multiple":"","show-arrows":""}},_vm._l((_vm.Model.west_area.length),function(n){return _c('v-slide-item',{key:n},[(n===3)?_c('div',{staticClass:"ma-4",staticStyle:{"margin":"11px!important"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('img',{attrs:{"src":_vm.Model.west_area[n - 1].img_logo.url,"height":"55","alt":""}})])]}}],null,true)},[_c('ul',[_c('li',[_vm._v(_vm._s(_vm.Model.west_area[n - 1].name))]),_c('li',[_vm._v(_vm._s(_vm.Model.west_area[n - 1].address))]),_c('li',[_vm._v(_vm._s(_vm.Model.west_area[n - 1].email))]),_c('li',[_vm._v(_vm._s(_vm.Model.west_area[n - 1].telephone))]),(_vm.Model.west_area[n - 1].url)?_c('li',[_vm._v(_vm._s(_vm.Model.west_area[n - 1].url))]):_vm._e()])])],1):_c('div',{staticClass:"ma-4"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('img',{attrs:{"src":_vm.Model.west_area[n - 1].img_logo.url,"height":"50","alt":""}})])]}}],null,true)},[_c('ul',[_c('li',[_vm._v(_vm._s(_vm.Model.west_area[n - 1].name))]),_c('li',[_vm._v(_vm._s(_vm.Model.west_area[n - 1].address))]),_c('li',[_vm._v(_vm._s(_vm.Model.west_area[n - 1].email))]),_c('li',[_vm._v(_vm._s(_vm.Model.west_area[n - 1].telephone))]),(_vm.Model.west_area[n - 1].url)?_c('li',[_vm._v(_vm._s(_vm.Model.west_area[n - 1].url))]):_vm._e()])])],1)])}),1)],1):_vm._e()],1),_c('div',{staticClass:"wrap d-flex",staticStyle:{"place-items":"center"}},[_c('img',{staticClass:"header-img",attrs:{"src":require("../../../assets/img/about/zone_mtg_2.png")}}),(_vm.Model.middle_area)?_c('v-sheet',{staticClass:"zone-area",attrs:{"max-width":"880","width":"100%"}},[_c('v-slide-group',{staticClass:"agency-list",attrs:{"multiple":"","show-arrows":""}},_vm._l((_vm.Model.middle_area.length),function(n){return _c('v-slide-item',{key:n},[_c('div',{staticClass:"ma-4"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('img',{attrs:{"src":_vm.Model.middle_area[n - 1].img_logo.url,"height":"50","alt":""}})])]}}],null,true)},[_c('ul',[_c('li',[_vm._v(_vm._s(_vm.Model.middle_area[n - 1].name))]),_c('li',[_vm._v(_vm._s(_vm.Model.middle_area[n - 1].address))]),_c('li',[_vm._v(_vm._s(_vm.Model.middle_area[n - 1].email))]),_c('li',[_vm._v(_vm._s(_vm.Model.middle_area[n - 1].telephone))]),(_vm.Model.middle_area[n - 1].url)?_c('li',[_vm._v(_vm._s(_vm.Model.middle_area[n - 1].url))]):_vm._e()])])],1)])}),1)],1):_vm._e()],1),_c('div',{staticClass:"wrap d-flex",staticStyle:{"place-items":"center"}},[_c('img',{staticClass:"header-img",attrs:{"src":require("../../../assets/img/about/zone_mbc_2.png")}}),(_vm.Model.north_area)?_c('v-sheet',{staticClass:"zone-area",attrs:{"max-width":"880","width":"100%"}},[_c('v-slide-group',{staticClass:"agency-list",attrs:{"multiple":"","show-arrows":""}},_vm._l((_vm.Model.north_area.length),function(n){return _c('v-slide-item',{key:n},[(n<=2)?_c('div',{staticClass:"ma-4",staticStyle:{"margin":"10px 0px 0px 16px!important"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('img',{attrs:{"src":_vm.Model.north_area[n - 1].img_logo.url,"height":"70","alt":""}})])]}}],null,true)},[_c('ul',[_c('li',[_vm._v(_vm._s(_vm.Model.north_area[n - 1].name))]),_c('li',[_vm._v(_vm._s(_vm.Model.north_area[n - 1].address))]),_c('li',[_vm._v(_vm._s(_vm.Model.north_area[n - 1].email))]),_c('li',[_vm._v(_vm._s(_vm.Model.north_area[n - 1].telephone))]),(_vm.Model.north_area[n - 1].url)?_c('li',[_vm._v(_vm._s(_vm.Model.north_area[n - 1].url))]):_vm._e()])])],1):_c('div',{staticClass:"ma-4"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('img',{attrs:{"src":_vm.Model.north_area[n - 1].img_logo.url,"height":"50","alt":""}})])]}}],null,true)},[_c('ul',[_c('li',[_vm._v(_vm._s(_vm.Model.north_area[n - 1].name))]),_c('li',[_vm._v(_vm._s(_vm.Model.north_area[n - 1].address))]),_c('li',[_vm._v(_vm._s(_vm.Model.north_area[n - 1].email))]),_c('li',[_vm._v(_vm._s(_vm.Model.north_area[n - 1].telephone))]),(_vm.Model.north_area[n - 1].url)?_c('li',[_vm._v(_vm._s(_vm.Model.north_area[n - 1].url))]):_vm._e()])])],1)])}),1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }