<template>
  <v-container>
    <v-row>
      <v-col md="3" cols="12">
        <sidebar>
          <sidebar-item label="Hướng dẫn cách chơi">
            <div class="pa-5">
              <div class="wrap-iframe">
                <div
                  v-html="this.details.youtube01"
                  class="iframe-responsive"
                ></div>
              </div>
            </div>
          </sidebar-item>
          <sidebar-item label="Tỷ trọng Doanh Số Sản Phẩm">
            <div class="proportion-of-sale text-center pa-3">
              <v-sheet
                color="transparent"
                width="250px"
                height="250px"
                elevation="0"
                class="mb-5"
              >
                <proportion-sales-chart :chart-data="chartData">
                </proportion-sales-chart>
              </v-sheet>

              <v-select
                name="zone"
                outlined
                dense
                :items="Chart.Zone"
                item-text="name"
                item-value="value"
                full-width
                placeholder="- Chọn khu vực -"
                v-model="ZoneSelected"
                @change="callApi"
              />
              <v-select
                name="product"
                outlined
                dense
                :items="Chart.Product"
                item-text="name"
                item-value="value"
                full-width
                placeholder="- Chọn sản phẩm -"
                v-model="ProductSelected"
              />
            </div>
          </sidebar-item>
          <sidebar-item label="Người trúng thưởng">
            <div class="pa-5">
              <div class="wrap-iframe">
                <div
                  v-html="this.details.youtube02"
                  class="iframe-responsive"
                ></div>
              </div>
            </div>
          </sidebar-item>
        </sidebar>
      </v-col>
      <v-col md="9" cols="12">
        <right-content class="product-details px-md-10 py-md-15 px-3 py-10">
          <page-heading :heading="title"></page-heading>
          <v-tabs
            show-arrows
            slider-color="secondary"
            color="secondary"
            center-active
          >
            <v-tab
              v-for="item in productList"
              :key="item.id"
              exact
              :to="`/san-pham/${item.slug}`"
              class="product-tab"
            >
              <div v-html="item.title"></div>
            </v-tab>
          </v-tabs>
          <v-card elevation="0" color="transparent" class="mt-md-8 mt-4">
            <v-img
              height="220"
              width="100%"
              :src="details.banner"
              class="mb-md-10 mb-5"
            />
            <h3
              class="font-weight-bold text-20 text-lg-22 text-center primary--text mb-md-10 mb-5"
              v-html="details.title"
            ></h3>
            <div class="mb-md-12 mb-8" v-html="details.description"></div>
            <h4 class="section-heading">Cơ cấu giải thưởng</h4>
            <div v-html="details.prize_structure"></div>

            <h4 class="section-heading">
              {{ `${details.title} CHƠI NHƯ THẾ NÀO` }}
            </h4>
            <div v-html="details.how_to_play"></div>
          </v-card>
        </right-content>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SidebarItem from "../../components/SidebarItem.vue";
import RightContent from "../../components/RightContent.vue";
import PageHeading from "../../components/PageHeading.vue";
import Sidebar from "../../components/Sidebar.vue";
import { apiUrl } from "@/main";
import ProportionSalesChart from "./ProportionSalesChart.js";

export default {
  components: {
    SidebarItem,
    RightContent,
    PageHeading,
    Sidebar,
    ProportionSalesChart
  },
  name: "ProductDetails",
  data: () => ({
    isChartLoaded: false,
    chartData: {},
    title: "Thông tin sản phẩm",
    productList: [],
    details: {
      title: "",
      banner: "",
      how_to_play: "",
      prize_structure: ""
    },
    Data: [],
    Chart: {
      Zone: [
        {
          name: "Hồ Chí Minh",
          value: "HCM"
        },
        {
          name: "Đông Nam Bộ",
          value: "MDG"
        },
        {
          name: "Tây Nam Bộ",
          value: "MTY"
        },
        {
          name: "Bắc Trung Bộ",
          value: "BTB"
        },
        {
          name: "Bắc Bộ",
          value: "MBC"
        },
        {
          name: "Trung Bộ",
          value: "MTG"
        }
      ],
      Product: [
        {
          name: "Mega 6/45",
          value: 1
        },
        {
          name: "Power 6/55",
          value: 2
        },
        {
          name: "Max 4D",
          value: 4
        },
        {
          name: "Keno",
          value: 5
        },
        {
          name: "Max 3D",
          value: 6
        }
      ]
    },
    ZoneSelected: null,
    ProductSelected: null,
    PageId: null,
    Youtube01: null,
    Youtube02: null
  }),
  methods: {
    changeProduct(productSlug) {
      const localProductList = JSON.parse(
        sessionStorage.getItem("product-list")
      );
      if (!localProductList) return;
      const details = this.productList.find(x => x.slug === productSlug);
      this.details = details ? details : this.productList[0];
    },
    callApi(zone, product) {
      this.isChartLoaded = false;
      let _self = this;
      let xhr = new XMLHttpRequest();
      const url = new URL(`${apiUrl.extUrl}/api/Remote/GameWeight`);

      if (!zone || !product) {
        this.isChartLoaded = true;
        return;
      }

      url.searchParams.append("Area", zone);
      url.searchParams.append("Game", product);

      xhr.open("GET", url);
      xhr.onload = function() {
        if (xhr.status !== 200) return false;
        _self.Data = JSON.parse(JSON.parse(xhr.response));
        const responseData = _self.Data[0].Game;
        _self.chartData = {
          datasets: [
            {
              label: "Data One",
              backgroundColor: ["#FF8208", "#87C443"],
              data: [100 - responseData, responseData]
            }
          ],
          labels: [`${100 - responseData}%`, `${responseData}%`]
        };
      };
      xhr.send();
      this.isChartLoaded = true;
    }
  },
  mounted() {
    this.ZoneSelected = this.Chart.Zone[0].value;
    this.ProductSelected = this.Chart.Product[0].value;

    this.productList = JSON.parse(sessionStorage.getItem("product-list"));
    const currentId = this.$route.params.productSlug;
    if (currentId) this.changeProduct(currentId);
  },
  watch: {
    $route(to) {
      const productSlug = to.params.productSlug;
      this.changeProduct(productSlug);
    },
    ZoneSelected: function(val) {
      this.callApi(val, this.ProductSelected);
    },
    ProductSelected: function(val) {
      this.callApi(this.ZoneSelected, val);
    }
  }
};
</script>
<style scoped lang="scss">
.wrap-iframe {
  padding-top: 100%;
}
</style>
