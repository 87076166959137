<template>
  <v-container>
    <v-row>
      <v-col md="3" cols="12">
        <sidebar>
          <sidebar-item>
            <v-tabs vertical v-model="tab" class="custom">
              <v-tab href="#tab-1">
                {{ heading }}
              </v-tab>
              <v-tab href="#tab-2">
                {{ heading2 }}
              </v-tab>
            </v-tabs>
          </sidebar-item>
        </sidebar>
      </v-col>
      <v-col md="9" cols="12">
        <right-content class="product">
          <v-tabs-items v-model="tab" touchless>
            <v-tab-item value="tab-1">
              <page-heading :heading="heading"></page-heading>
              <div class="content">
                <div class="mb-5">
                  <img :src="theGame.image" alt="Sức Hấp Dẫn Các Trò Chơi" />
                </div>
                <div class="content" v-html="theGame.content" />
              </div>
            </v-tab-item>
            <v-tab-item value="tab-2">
              <page-heading :heading="heading2"></page-heading>
              <v-row class="product-list" v-if="productList.length > 0">
                <v-col
                  cols="12"
                  sm="6"
                  md="12"
                  v-for="(item, i) in productList"
                  :key="i"
                >
                  <product-item
                    :image="item.featured_image"
                    :title="item.title"
                    :description="item.description"
                    :id="item.id"
                    :slug="item.slug"
                  ></product-item>
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs-items>
        </right-content>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import proportionOfSale from "../../assets/img/product/ty-trong-doanh-so-san-pham.png";
import Sidebar from "../../components/Sidebar.vue";
import SidebarItem from "../../components/SidebarItem.vue";
import ProductItem from "../../components/ProductItem.vue";
import RightContent from "../../components/RightContent.vue";
import PageHeading from "../../components/PageHeading.vue";
import axios from "axios";
import { apiUrl } from "@/main";

export default {
  components: { Sidebar, SidebarItem, ProductItem, RightContent, PageHeading },
  name: "Product",
  data: () => ({
    proportionOfSaleImg: proportionOfSale,
    heading: "Tổng quan các sản phẩm",
    heading2: "Chi tiết sản phẩm",
    tab: "tab-2",
    productList: [],
    theGame: {
      image: "",
      content: ""
    },
    Model: {},
    Info: {
      meta_title: 'CÔNG TY TNHH ĐẦU TƯ MAI TÂM ANH | Sản Phẩm'
    }
  }),
  async mounted() {
    const localProductList = sessionStorage.getItem("product-list");
    if (!localProductList) return;
    this.productList = JSON.parse(localProductList);
    try {
      const response = await axios.get(`${apiUrl.page}/san-pham`);
      if (!response.data && !(response.data.length < 0)) return;
      const { product_list, the_game } = response.data[0].acf;
      this.theGame = {
        image: the_game.image.url,
        content: the_game.content
      };
    } catch (error) {
      console.log(error);
    }
    axios
        .get(`${apiUrl.api}/acf/v3/options/options`)
        .then(res => {
          if (!res.data) return false;
          this.Info = res.data.acf;
        })
        .catch(err => {
          console.log(err);
        });
  },
  metaInfo() {
    return {
      title: this.Info.meta_title  + ' | Sản Phẩm',
      description: this.Info.meta_description,
      meta: [
        {
          name: "title",
          content: this.Info.meta_title + ' | Về Chúng Tôi'
        },
        {
          name: "description",
          content: this.Info.meta_description
        },
        {
          property: "og:type",
          content: "website"
        },
        {
          property: "og:url",
          content: this.Info.meta_facebook_url
        },
        {
          property: "og:title",
          content: this.Info.meta_facebook_title
        },
        {
          property: "og:description",
          content: this.Info.meta_facebook_desciprtion
        },
        {
          property: "og:image",
          content: this.Info.meta_facebook_image
        },
        {
          property: "twitter:card",
          content: "summary_large_image"
        },
        {
          property: "twitter:url",
          content: this.Info.meta_twitter_url
        },
        {
          property: "twitter:title",
          content: this.Info.meta_twitter_title
        },
        {
          property: "twitter:description",
          content: this.Info.meta_twitter_desciprtion
        },
        {
          property: "twitter:image",
          content: this.Info.meta_twitter_image
        }
      ],
      htmlAttrs: {
        lang: "en"
      }
    };
  }
};
</script>
