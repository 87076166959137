<template>
  <div class="contact">
    <v-sheet
      elevation="0"
      color="#F6F7F9"
      class="pt-4 pb-4 pt-md-10 pb-md-15 pt-md-13"
    >
      <v-container>
        <div class="mb-5 mb-md-13">
          <page-heading heading="Liên hệ"></page-heading>
        </div>
        <div
          class="d-flex flex-column-reverse flex-sm-row align-center justify-center"
        >
          <v-sheet
            elevation="0"
            color="#CCC"
            class="contact-map mx-auto mx-md-0"
            width="100%"
            max-width="670"
            v-html="headquarters.google_map"
          >
          </v-sheet>
          <v-sheet elevation="0" class="contact-info" id="ho-chi-minh">
            <v-skeleton-loader
              v-if="isTopSectionLoaded"
              type="article"
              :loading="isTopSectionLoaded"
            ></v-skeleton-loader>
            <div v-else>
              <h3
                class="text-18 text-md-22 mb-md-4 mb-2 zone-title primary--text font-weight-bold"
              >
                <img src="../../assets/img/contact/ho.jpg" height="50" style="margin-right: 7px;" alt=""/> {{ headquarters.title }}
              </h3>
              <v-list>
                <v-list-item dense>
                  <v-list-item-icon class="mr-2 mr-md-5">
                    <v-icon>mdi-map-marker</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content
                    class="text-12 text-lg-16 text-md-14 pa-0"
                  >
                    {{ headquarters.address }}
                  </v-list-item-content>
                </v-list-item>
                <v-list-item dense link href="https://songanh.maitamanh.vn">
                  <v-list-item-icon class="mr-2 mr-md-5">
                    <img src="../../assets/img/common/logo_song_anh_767676.png" width="24" alt="" />
                  </v-list-item-icon>
                  <v-list-item-content
                      class="text-12 text-lg-16 text-md-14 pa-0"
                  >
                    songanh.maitamanh.vn
                  </v-list-item-content>
                </v-list-item>
                <v-list-item dense link href="mailto:cskh@maitamanh.vn">
                  <v-list-item-icon class="mr-2 mr-md-5">
                    <v-icon>mdi-send</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content
                    class="text-12 text-lg-16 text-md-14 pa-0"
                  >
                    {{ headquarters.email }}
                  </v-list-item-content>
                </v-list-item>
                <v-list-item dense link :href="'tel:+' + headquarters.telephone">
                  <v-list-item-icon class="mr-2 mr-md-5">
                    <v-icon>mdi-phone</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content
                    class="text-12 text-lg-16 text-md-14 pa-0"
                  >
                    <a :href="'tel:+' + headquarters.telephone"></a>
                    {{ headquarters.telephone }}
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </div>
          </v-sheet>
        </div>
      </v-container>
    </v-sheet>
    <v-sheet
      elevation="0"
      color="#FFF"
      class="contact-offices pt-4 pb-4 pb-md-15 pt-md-10 pt-md-13"
    >
      <v-container>
        <div class="mb-3 mb-md-5">
          <page-heading heading="VĂN PHÒNG chi nhánh các Miền"></page-heading>
        </div>
        <v-card elevation="0">
          <v-tabs
            center-active
            v-model="currentTab"
            color="secondary"
            slider-color="secondary"
            centered
          >
            <v-tab
              v-for="(item, i) in offices"
              :key="i"
              :href="
                i == 0
                  ? '#mien-tay'
                  : i == 1
                  ? '#mien-dong'
                  : i == 2
                  ? '#mien-trung'
                  : '#mien-bac'
              "
            >
              <v-skeleton-loader
                v-if="isTopSectionLoaded"
                type="card-heading"
                :loading="isTopSectionLoaded"
              ></v-skeleton-loader>
              <h3
                class="text-12 text-md-14 text-lg-16 mb-0 text-capitalize"
                v-else
              >
                {{ item.title }}
              </h3>
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="currentTab" class="mt-10">
            <v-tab-item
              v-for="(item, i) in offices"
              :key="i"
              :value="
                i == 0
                  ? 'mien-tay'
                  : i == 1
                  ? 'mien-dong'
                  : i == 2
                  ? 'mien-trung'
                  : 'mien-bac'
              "
            >
              <v-card flat>
                <div
                  class="d-flex flex-column-reverse flex-sm-row-reverse align-center justify-center"
                >
                  <v-sheet
                    elevation="0"
                    color="#CCC"
                    class="contact-map mx-auto mx-md-0"
                    width="100%"
                    max-width="670"
                    v-html="item.google_map"
                  >
                  </v-sheet>
                  <v-sheet
                    elevation="0"
                    class="contact-info contact-info--reverse"
                  >
                    <v-skeleton-loader
                      v-if="isTopSectionLoaded"
                      type="article"
                      :loading="isTopSectionLoaded"
                    ></v-skeleton-loader>
                    <div v-else>
                      <h3
                        class="text-18 text-md-22 zone-title mb-md-4 mb-2 primary--text font-weight-bold"
                      >
                        <img v-if="i == 0" src="../../assets/img/contact/logo_zone_mty.png" height="40" alt="" style="margin-right: 5px"/>
                        <img v-if="i == 1" src="../../assets/img/contact/logo_zone_mdg.png" height="40" alt="" style="margin-right: 5px"/>
                        <img v-if="i == 2" src="../../assets/img/contact/logo_zone_mtg.png" height="40" alt="" style="margin-right: 5px"/>
                        <img v-if="i == 3" src="../../assets/img/contact/logo_zone_mbc.png" height="40" alt="" style="margin-right: 5px"/>
                        {{item.title}}
                      </h3>

                      <v-list color="transparent">
                        <v-list-item dense>
                          <v-list-item-icon class="mr-2 mr-md-5">
                            <v-icon>mdi-map-marker</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content
                            class="text-12 text-lg-16 text-md-14 pa-0"
                            v-html="item.address"
                          >
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item dense link href="https://songanh.maitamanh.vn">
                          <v-list-item-icon class="mr-2 mr-md-5">
                            <img src="../../assets/img/common/logo_song_anh_f5f5f5.png" width="24" alt="" />
                          </v-list-item-icon>
                          <v-list-item-content
                              class="text-12 text-lg-16 text-md-14 pa-0"
                          >
                            songanh.maitamanh.vn
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item dense link href="mailto:cskh@maitamanh.vn">
                          <v-list-item-icon class="mr-2 mr-md-5">
                            <v-icon>mdi-send</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content
                            class="text-12 text-lg-16 text-md-14 pa-0"
                            v-html="item.email"
                          >
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item dense link :href="'tel:+' + item.telephone">
                          <v-list-item-icon class="mr-2 mr-md-5">
                            <v-icon>mdi-phone</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content
                            class="text-12 text-lg-16 text-md-14 pa-0"
                            v-html="item.telephone"
                          >
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </div>
                  </v-sheet>
                </div>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-container>
    </v-sheet>
  </div>
</template>

<script>
import PageHeading from "../../components/PageHeading.vue";

import { apiUrl } from "@/main";
import axios from "axios";

export default {
  name: "Contact",
  components: { PageHeading },
  data: () => ({
    currentTab: null,
    offices: [],
    headquarters: {
      google_map: ""
    },
    Info: {
      meta_title: 'CÔNG TY TNHH ĐẦU TƯ MAI TÂM ANH | Liên Hệ'
    },
    isTopSectionLoaded: true
  }),
  created() {
    this.currentTab = this.$route.hash.substr(1);
  },
  mounted() {
    axios
      .get(`${apiUrl.page}/lien-he`)
      .then(response => {
        if (!response) return false;
        const data = response.data[0].acf;
        this.headquarters = data.top_section;
        this.offices = data.representative_office;
      })
      .catch(err => console.log(err))
      .finally(() => {
        this.isTopSectionLoaded = false;
      });
    axios
        .get(`${apiUrl.api}/acf/v3/options/options`)
        .then(res => {
          if (!res.data) return false;
          this.Info = res.data.acf;
          this.firstLoad = false;
        })
        .catch(err => {
          console.log(err);
        });
  },
  metaInfo() {
    return {
      title: this.Info.meta_title  + ' | Liên Hệ',
      description: this.Info.meta_description,
      meta: [
        {
          name: "title",
          content: this.Info.meta_title
        },
        {
          name: "description",
          content: this.Info.meta_description
        },
        {
          property: "og:type",
          content: "website"
        },
        {
          property: "og:url",
          content: this.Info.meta_facebook_url
        },
        {
          property: "og:title",
          content: this.Info.meta_facebook_title
        },
        {
          property: "og:description",
          content: this.Info.meta_facebook_desciprtion
        },
        {
          property: "og:image",
          content: this.Info.meta_facebook_image
        },
        {
          property: "twitter:card",
          content: "summary_large_image"
        },
        {
          property: "twitter:url",
          content: this.Info.meta_twitter_url
        },
        {
          property: "twitter:title",
          content: this.Info.meta_twitter_title
        },
        {
          property: "twitter:description",
          content: this.Info.meta_twitter_desciprtion
        },
        {
          property: "twitter:image",
          content: this.Info.meta_twitter_image
        }
      ],
      htmlAttrs: {
        lang: "en"
      }
    };
  }
};
</script>
<style scoped lang="scss">
#ho-chi-minh {
  margin-top: -85px;
}
</style>
