<template>
  <section class="manage-store">
    <v-container>
      <v-row>
        <v-col md="3" cols="12">
          <v-sheet v-if="isLoading" class="d-flex flex-column" width="100%">
            <div v-for="n in 4" :key="n">
              <v-skeleton-loader
                type="list-item"
                :loading="loading"
              ></v-skeleton-loader>
            </div>
          </v-sheet>
          <sidebar v-else>
            <sidebar-item>
              <v-tabs vertical v-model="tab" class="custom">
                <v-tab to="/quan-ly-diem-ban-hang">
                  {{ heading }}
                </v-tab>
                <v-tab to="/chinh-sach-dai-ly">
                  {{ heading2 }}
                </v-tab>
              </v-tabs>
            </sidebar-item>
          </sidebar>
        </v-col>
        <v-col md="9" cols="12">
          <v-sheet v-if="isLoading" class="d-flex flex-column" width="100%">
            <div v-for="n in 4" :key="n">
              <v-skeleton-loader
                type="article"
                :loading="loading"
              ></v-skeleton-loader>
            </div>
          </v-sheet>
          <v-card flat elevation="0" class="pt-10" v-else>
            <page-heading :heading="heading"></page-heading>
            <div
              class="content"
              v-if="Model.content"
              v-html="Model.content.rendered"
            />
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import Img01 from "@/assets/img/manager/dbh01.jpg";
import Img02 from "@/assets/img/manager/dbh02.jpg";

import PageHeading from "@/components/PageHeading";
import axios from "axios";
import { apiUrl } from "@/main";
import Sidebar from "@/components/Sidebar";
import SidebarItem from "@/components/SidebarItem";

export default {
  name: "ManagerSales",
  components: { PageHeading, Sidebar, SidebarItem },
  data: () => ({
    Img01: Img01,
    Img02: Img02,
    title: "Quản lý điểm bán hàng",
    Model: "",
    heading: "Quản lý điểm bán hàng",
    heading2: "Chính sách đại lý",
    tab: null,
    isLoading: true,
    loading: true,
    Info: {
      meta_title: 'CÔNG TY TNHH ĐẦU TƯ MAI TÂM ANH | Quản Lý ĐBH'
    }
  }),
  mounted() {
    axios.get(`${apiUrl.page}/quan-ly-diem-ban-hang`).then(response => {
      if (!response) return false;
      this.Model = response.data[0];
      this.isLoading = false;
    });
    axios
        .get(`${apiUrl.api}/acf/v3/options/options`)
        .then(res => {
          if (!res.data) return false;
          this.Info = res.data.acf;
        })
        .catch(err => {
          console.log(err);
        });
  },
  metaInfo() {
    return {
      title: this.Info.meta_title  + ' | Quản Lý ĐBH',
      description: this.Info.meta_description,
      meta: [
        {
          name: "title",
          content: this.Info.meta_title
        },
        {
          name: "description",
          content: this.Info.meta_description
        },
        {
          property: "og:type",
          content: "website"
        },
        {
          property: "og:url",
          content: this.Info.meta_facebook_url
        },
        {
          property: "og:title",
          content: this.Info.meta_facebook_title
        },
        {
          property: "og:description",
          content: this.Info.meta_facebook_desciprtion
        },
        {
          property: "og:image",
          content: this.Info.meta_facebook_image
        },
        {
          property: "twitter:card",
          content: "summary_large_image"
        },
        {
          property: "twitter:url",
          content: this.Info.meta_twitter_url
        },
        {
          property: "twitter:title",
          content: this.Info.meta_twitter_title
        },
        {
          property: "twitter:description",
          content: this.Info.meta_twitter_desciprtion
        },
        {
          property: "twitter:image",
          content: this.Info.meta_twitter_image
        }
      ],
      htmlAttrs: {
        lang: "en"
      }
    };
  }
};
</script>
