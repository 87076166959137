<template>
  <section class="criteria">
    <div class="mb-5 mb-md-13">
      <page-heading :heading="heading"></page-heading>
    </div>
    <h3 class="section-heading">
      Điều kiện mặt bằng
    </h3>
    <div v-html="data.site_conditions"></div>
    <div class="mt-8 mt-md-13">
      <h3 class="section-heading">
        Hồ sơ, chứng từ cần chuẩn bị
      </h3>
      <div v-html="data.document"></div>
    </div>
  </section>
</template>

<script>
import PageHeading from "../../../components/PageHeading.vue";
export default {
  components: { PageHeading },
  props: ["data"],
  name: "Criteria",
  data: () => ({
    heading: "Tiêu chí Điểm bán hàng"
  })
};
</script>
<style lang="scss" scoped>
.required {
  color: red !important;
}
</style>
