<template>
  <v-card
    elevation="0"
    :to="link ? link : `/tin-tuc/${postId}`"
    class="post-item d-md-flex"
    :class="classes"
  >
    <v-img
      width="230"
      height="170"
      :src="image"
      contain
      position="center"
      v-if="image"
    >
    </v-img>
    <v-sheet
      elevation="0"
      color="#EEE"
      width="230"
      height="170"
      v-else
    ></v-sheet>

    <div class="post-item__content ml-0 ml-md-8 mt-3 mt-md-0">
      <span class="post-item__date mb-1 mb-md-3 text-13 text-md-15">
        {{ new Date(createdDate).toLocaleDateString() }}
      </span>
      <h6
        class="post-item__title primary--text mb-1 mb-md-3 text-16 text-md-18 font-weight-bold"
        v-html="title"
      ></h6>
      <div
        class="post-item__desc text-14 text-md-16"
        v-html="description"
      ></div>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "PostItem",
  props: [
    "image",
    "createdDate",
    "title",
    "description",
    "postId",
    "classes",
    "link"
  ]
};
</script>
