<template>
  <section id="info" class="info-company">
    <v-container>
      <page-heading :heading="title"></page-heading>
      <v-sheet v-if="firstLoad" class="d-flex flex-column" width="100%">
        <div v-for="n in 4" :key="n">
          <v-skeleton-loader
            type="list-item-three-line"
            :loading="loading"
          ></v-skeleton-loader>
        </div>
      </v-sheet>
      <div v-if="!firstLoad" class="d-flex flex-column mt-12">
        <div class="wrap d-flex flex-column flex-md-row align-md-center">
          <div class="name"><b>Tên Công ty</b></div>
          <div class="description"><b>{{ Model.name }}</b></div>
        </div>
        <div class="wrap d-flex flex-column flex-md-row align-md-center">
          <div class="name">Tên giao dịch quốc tế</div>
          <div class="description">{{ Model.name_global }}</div>
        </div>
        <div class="wrap d-flex flex-column flex-md-row align-md-center">
          <div class="name">Địa chỉ</div>
          <div class="description">{{ Model.address }}</div>
        </div>
        <div class="wrap d-flex flex-column flex-md-row align-md-center">
          <div class="name">Hotline</div>
          <div class="description"><a :href="'tel:'+ Model.hotline">{{ Model.hotline }}</a></div>
        </div>
        <div class="wrap d-flex flex-column flex-md-row align-md-center">
          <div class="name">Website</div>
          <div class="description"><a :href="Model.website">{{ Model.website }}</a></div>
        </div>
        <div class="wrap d-flex flex-column flex-md-row align-md-center">
          <div class="name">Mã số doanh nghiệp</div>
          <div class="description">{{ Model.co_number }}</div>
        </div>
        <div class="wrap d-flex flex-column flex-md-row align-md-center">
          <div class="name">Đại diện pháp luật</div>
          <div class="description">{{ Model.representative }}</div>
        </div>
      </div>
    </v-container>
  </section>
</template>

<script>
import PageHeading from "@/components/PageHeading";
export default {
  name: "InfoCompany",
  components: {
    PageHeading
  },
  props: {
    Model: {
      type: Object,
    },
    firstLoad: {
      type: Boolean,
    }
  },
  data: () => ({
    title: "Thông tin công ty",
    loading: true,
  }),
}
</script>

<style scoped>

</style>
