<template>
  <section class="vision cmn-section" id="vision">
    <v-container>
      <page-heading :heading="title"></page-heading>
      <v-row justify="center">
        <v-col cols="12" md="6" lg="4">
          <div class="wrap">
            <v-img :src="Img01" contain class="ico-mdf mb-6" />
            <h3
              class="font-weight-bold text-md-22 text-18 text-uppercase mb-3 mb-md-6 text-center"
            >
              Phương châm hoạt động
            </h3>
            <p>
              Luôn đặt lợi ích Đối tác/Điểm Bán Hàng lên hàng đầu để phục vụ.
            </p>
          </div>
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <div class="wrap">
            <v-img :src="Img02" contain class="ico-mdf mb-6" />
            <h3
              class="font-weight-bold text-md-22 text-18 text-uppercase mb-3 mb-md-6 text-center"
            >
              Tầm nhìn
            </h3>
            <p>
              Trở thành tổng đại lý lớn, chuyên nghiệp trong quản lý và phát triển Điểm Bán Hàng phân phối sản phẩm xổ số đa dạng trên phạm vi toàn quốc
            </p>
          </div>
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <div class="wrap">
            <v-img :src="Img03" contain class="ico-mdf mb-6" />
            <h3
              class="font-weight-bold text-md-22 text-18 text-uppercase mb-3 mb-md-6 text-center"
            >
              Sứ mệnh
            </h3>
            <p>
              Tạo ra một hệ thống liên kết hỗ trợ chặt chẽ, phục vụ nhanh chóng và tốt nhất cho các Điểm Bán Hàng và Đối tác trên cả nước.
            </p>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import Icon01 from "@/assets/img/about/icon-01.svg";
import Icon02 from "@/assets/img/about/icon-02.svg";
import Icon03 from "@/assets/img/about/icon-03.svg";

import PageHeading from "@/components/PageHeading";
export default {
  name: "Vision",
  components: { PageHeading },
  data: () => ({
    title: "Phương châm hoạt động - Tầm nhìn - Sứ mệnh",
    Img01: Icon01,
    Img02: Icon02,
    Img03: Icon03
  })
};
</script>

<style scoped></style>
