<template>
  <section class="manage-store">
    <v-container>
      <v-row>
        <v-col md="3" cols="12">
          <sidebar>
            <sidebar-item>
              <v-tabs vertical v-model="tab" class="custom">
                <v-tab to="/quan-ly-diem-ban-hang">
                  {{ heading }}
                </v-tab>
                <v-tab to="/chinh-sach-dai-ly">
                  {{ heading2 }}
                </v-tab>
              </v-tabs>
            </sidebar-item>
          </sidebar>
        </v-col>

        <v-col md="9" cols="12">
          <right-content>
            <v-skeleton-loader
              type="article"
              :loading="isLoading"
              v-if="isLoading"
            ></v-skeleton-loader>
            <section class="post-details pa-5 pa-md-10" v-else>
              <div v-if="!error">
                <span class="post-item__date mb-3 text-15">
                  {{ details.createdDate }}
                </span>
                <h6
                  class="post-item__title primary--text mb-4 text-md-22 text-20 font-weight-bold"
                  v-html="details.title"
                ></h6>
                <div
                  class="post-details__content mb-10"
                  v-html="details.content"
                ></div>
                <div class="post-author text-center" v-if="details.authorId">
                  <v-avatar size="128" class="mb-md-5 mb-3">
                    <img :src="author.avatar" :alt="author.name" />
                  </v-avatar>
                  <h6 class="text-16 text-md-20 mb-2 font-weight-bold">
                    {{ author.name }}
                  </h6>
                  <p class="grey--text text--darken-4 text-14 text-md-16 mb-3">
                    {{ author.position }}
                  </p>
                  <v-btn
                    elevation="0"
                    text
                    :to="`/tin-tuc/tac-gia/${author.id}`"
                    class="black--text"
                  >
                    {{ `Xem thêm bài viết từ ${author.name}` }}
                  </v-btn>
                  <div
                    class="post-details__navigation d-flex justify-space-between align-center mt-5"
                  >
                    <v-btn
                      v-if="details.prevPostId"
                      elevation="0"
                      text
                      :to="`/tin-tuc/${details.prevPostId}`"
                      class="black--text"
                    >
                      <v-icon small class="mr-1">mdi-arrow-left</v-icon>
                      Trước
                    </v-btn>
                    <v-btn
                      v-if="details.nextPostId"
                      elevation="0"
                      text
                      :to="`/tin-tuc/${details.nextPostId}`"
                      class="black--text"
                    >
                      Tiếp
                      <v-icon small class="ml-1">mdi-arrow-right</v-icon>
                    </v-btn>
                  </div>
                </div>
              </div>
              <div class="text-13 text-md-16" v-else v-html="error"></div>
            </section>
          </right-content>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import RightContent from "../../components/RightContent.vue";
import Sidebar from "@/components/Sidebar";
import SidebarItem from "@/components/SidebarItem";
import axios from "axios";
import { apiUrl } from "@/main";

export default {
  components: { Sidebar, SidebarItem, RightContent },
  name: "AgencyDetails",
  data: () => ({
    heading: "Quản lý điểm bán hàng",
    heading2: "Chính sách đại lý",
    isLoading: true,
    details: {},
    error: null,
    tab: null,
  }),
  methods: {
    getPostDetails: function(agencyId) {
      this.isLoading = true;
      const url = `${apiUrl.api}/wp/v2/agency_policy/${agencyId}`;

      axios
        .get(url)
        .then(response => {
          if (!response.data) return false;
          const {
            title: { rendered: title },
            date_gmt: createdDate,
            content: { rendered: content }
          } = response.data;

          this.details = {
            title,
            createdDate: new Date(createdDate).toLocaleDateString(),
            content
          };

          this.error = null;
        })
        .catch(err => {
          this.error = "Không tìm thấy bài viết";
        })
        .finally(() => (this.isLoading = false));
    }
  },
  mounted() {
    this.getPostDetails(this.$route.params.agencyId);
  },
  watch: {
    $route(to, from) {
      const currentAgencyId = to.params.agencyId;
      if (currentAgencyId) this.getPostDetails(currentAgencyId);
    }
  }
};
</script>
