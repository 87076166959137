<template>
  <v-app class="homepage">
    <RegisterAndManager
      :model="Model.RegisterAndManager"
      v-on:login="loginManager"
    />
    <WhyChoose :model="Model.WhyChoose" />
    <OpenVietlott :model="Model.OpenStore" />
    <WhatYouPrepare :model="Model.WhatYouPrepare" />
    <ContactOpen :model="Model.ContactOpen" :contact="Model.ContactClass" />
    <Blog :model="Model.Blog" />
    <v-snackbar v-model="showSnackbar" right :color="snackbarOptions.variant">
      <div v-html="snackbarOptions.message"></div>
      <template v-slot:action="{ attrs }">
        <v-btn icon color="gray" v-bind="attrs" @click="showSnackbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
// @ is an alias to /src
import RegisterAndManager from "./components/RegisterAndManager";
import WhyChoose from "./components/WhyChoose";
import OpenVietlott from "./components/OpenVietlott";
import WhatYouPrepare from "./components/WhatYouPrepare";
import ContactOpen from "./components/ContactOpen";
import Blog from "./components/Blog.vue";
import axios from "axios";
import { apiUrl } from "@/main";

export default {
  name: "Home",
  components: {
    ContactOpen,
    WhatYouPrepare,
    OpenVietlott,
    WhyChoose,
    RegisterAndManager,
    Blog
  },
  data: () => ({
    // Register Object API
    Model: {
      RegisterAndManager: {
        UserName: "",
        PassWord: ""
      },
      WhyChoose: [],
      OpenStore: {},
      WhatYouPrepare: [],
      ContactOpen: [],
      ContactClass: {},
      Blog: [],
    },
    showSnackbar: false,
    snackbarOptions: {
      message: null,
      variant: null
    },
    isLogin: "",
    Info: {}
  }),
  mounted() {
    // TODO: Call API here, using store call
    axios
      .get(`${apiUrl.page}/trang-chu`)
      .then(response => {
        if (!response.data) return false;
        let Model = response.data[0].acf;
        this.Model.WhyChoose = Model.why_choose;
        this.Model.WhatYouPrepare = Model.what_you_prepare;
        this.Model.ContactOpen = Model.contact_open_store;
        this.Model.ContactClass = Model.telephone_number;
        this.Model.OpenStore = Model.open_vietlott;
        sessionStorage.setItem('YoutubeFooter', Model.youtube_footer);
      })
      .catch(error => {
        console.log(error);
      });
    axios
        .get(`${apiUrl.api}/acf/v3/options/options`)
        .then(res => {
          if (!res.data) return false;
          this.Info = res.data.acf;
        })
        .catch(err => {
          console.log(err);
        });

    this.getBlogs();
  },
  methods: {
    loginManager: function() {
      let account = this.Model.RegisterAndManager.UserName,
        password = this.Model.RegisterAndManager.PassWord;
      let ctx = this;
      let xhr = new XMLHttpRequest();
      xhr.open(
        "GET",
        `${apiUrl.extUrl}/api/Remote/user?Account=${account}&Password=${password}`
      );
      xhr.onload = function() {
        if (xhr.status !== 200) return false;
        if (xhr.response.toString() == '"YES"') {
          window.open(
            `https://dailyxsdt.vn/api/Remote/userlogin?Account=${account}&Password=${password}`
          );
        } else {
          ctx.showSnackbar = true;
          ctx.snackbarOptions = {
            message: "Tên đăng nhập hoặc Mật khẩu không đúng.",
            variant: "error"
          };
        }
      };
      xhr.send();
    },
    getBlogs: function() {
      const url = new URL(`${apiUrl.api}/wp/v2/posts?per_page=4`);
      axios
        .get(url)
        .then(response => {
          if (!response.data) return;
          this.Model.Blog = response.data;
        })
        .catch(err => console.log("Can not get blogs"));
    }
  },
  computed: {},
  metaInfo() {
    return {
      title: this.Info.meta_title  + ' | Trang Chủ',
      description: this.Info.meta_description,
      meta: [
        {
          name: "title",
          content: this.Info.meta_title + ' | Về Chúng Tôi'
        },
        {
          name: "description",
          content: this.Info.meta_description
        },
        {
          property: "og:type",
          content: "website"
        },
        {
          property: "og:url",
          content: this.Info.meta_facebook_url
        },
        {
          property: "og:title",
          content: this.Info.meta_facebook_title
        },
        {
          property: "og:description",
          content: this.Info.meta_facebook_desciprtion
        },
        {
          property: "og:image",
          content: this.Info.meta_facebook_image
        },
        {
          property: "twitter:card",
          content: "summary_large_image"
        },
        {
          property: "twitter:url",
          content: this.Info.meta_twitter_url
        },
        {
          property: "twitter:title",
          content: this.Info.meta_twitter_title
        },
        {
          property: "twitter:description",
          content: this.Info.meta_twitter_desciprtion
        },
        {
          property: "twitter:image",
          content: this.Info.meta_twitter_image
        }
      ],
      htmlAttrs: {
        lang: "en"
      }
    };
  }
};
</script>
