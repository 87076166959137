<template>
  <section class="commission-policy">
    <div class="mb-5 mb-md-13">
      <page-heading :heading="heading"></page-heading>
    </div>
    <div v-html="data"></div>
  </section>
</template>

<script>
import PageHeading from "../../../components/PageHeading.vue";
export default {
  components: { PageHeading },
  props: ["data"],
  name: "CommissionPolicy",
  data: () => ({
    heading: "Chính sách hoa hồng"
  })
};
</script>
