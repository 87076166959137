<template>
  <v-sheet elevation="0" width="100%" color="transparent">
    <slot />
  </v-sheet>
</template>

<script>
export default {
  name: "Sidebar"
};
</script>
