<template>
  <section class="open-store cmn-section">
    <v-container>
      <h2 class="ttl-section clr-white text-center">MỞ ĐIỂM BÁN HÀNG<br>VIETLOTT VỚI MAI TÂM ANH, BẠN ĐƯỢC GÌ?</h2>
      <ul class="d-flex list flex-column flex-md-row" v-if="Model.open_store">
        <li class="col-12 col-md-4" v-for="(item, idx) in Model.open_store" :key="idx">
          <div class="wrap">
            <figure class="text-center img-wrap"><img :src="item.image" :alt="item.name"></figure>
            <h3 class="ttl text-center mt-7" v-html="item.name" />
          </div>
        </li>
      </ul>
      <figure class="text-center mb-5 mt-5"><img :src="Arrow" alt=""></figure>
      <div class="support-open">
        <h3 class="ttl-lg">Chúng tôi sẽ hỗ trợ bạn</h3>
        <ul class="d-flex list flex-column flex-md-row" v-if="Model.support">
          <li class="col-md-6 col-12" v-for="(item, idx) in Model.support" :key="idx">
            <figure class="img-wrap"><img :src="item.image" :alt="item.name"></figure>
            <h4 class="ttl" v-html="item.name" />
          </li>
        </ul>
      </div>
    </v-container>
  </section>
</template>

<script>
import Arr from "@/assets/img/index/icon-arr.svg"

export default {
  name: "OpenVietlott",
  props: {
    Model: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    Arrow: Arr,
  })
}
</script>

<style scoped>

</style>
