<template>
  <v-container>
    <v-breadcrumbs :items="breadCrumbs">
      <template v-slot:divider>
        <v-icon small>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
  </v-container>
</template>

<script>
export default {
  name: "BreadCrumbs",
  computed: {
    breadCrumbs() {
      let pathArray = this.$route.path.split("/");
      pathArray.shift();
      const breadCrumbs = [];
      let breadcrumb = "";
      let lastIndexFound = 0;
      breadCrumbs.push({ to: "/", disabled: false, text: "Trang chủ" });
      for (let i = 0; i < pathArray.length; ++i) {
        breadcrumb = `${breadcrumb}${"/"}${pathArray[i]}`;
        if (
          this.$route.matched[i] &&
          Object.hasOwnProperty.call(this.$route.matched[i], "meta") &&
          Object.hasOwnProperty.call(this.$route.matched[i].meta, "breadCrumb")
        ) {
          breadCrumbs.push({
            to:
              i !== 0 && pathArray[i - (i - lastIndexFound)]
                ? "/" + pathArray[i - (i - lastIndexFound)] + breadcrumb
                : breadcrumb,
            exact: i + 1 < pathArray.length,
            disabled: i + 1 === pathArray.length,
            text: this.$route.matched[i].meta.breadCrumb || pathArray[i]
          });
          lastIndexFound = i;
          breadcrumb = "";
        }
      }
      return breadCrumbs;
    }
  }
};
</script>
