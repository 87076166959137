<template>
  <div class="contact">
    <v-sheet
      elevation="0"
      color="#F6F7F9"
      class=""
    >
      <v-container>
        <div id="page-content">
          <page-heading heading="Điều khoản & Bảo mật"></page-heading>
          <div class="ladi-wraper">
            <div id="SECTION2" class='ladi-section'>
              <div class="ladi-container">
                <div id="GROUP51" class='ladi-element'>
                  <div class='ladi-group'>
                    <div id="BOX5" class='ladi-element'>
                      <div class='ladi-box'></div>
                    </div>
                    <div id="GROUP57" class='ladi-element'>
                      <div class='ladi-group'>
                        <div id="GROUP77" class='ladi-element'>
                          <div class='ladi-group'>
                            <div id="GROUP78" class='ladi-element'>
                              <div class='ladi-group'>
                                <div id="GROUP79" class='ladi-element'>
                                  <div class='ladi-group'>
                                    <div id="BUTTON12" class='ladi-element'>
                                      <div class='ladi-button ladi-transition'>
                                        <div class="ladi-button-background"></div>
                                        <div id="BUTTON_SHAPE12"
                                             class='ladi-element ladi-button-shape'>
                                          <div class='ladi-shape'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="100%"
                                                 height="100%" preserveAspectRatio="none"
                                                 viewBox="0 0 1792 1896.08" class=""
                                                 fill="rgba(255, 255, 255, 1)">
                                              <use xlink:href="#shape_qEcNJyTKdZ"></use>
                                            </svg>
                                          </div>
                                        </div>
                                        <div id="BUTTON_TEXT12"
                                             class='ladi-element ladi-button-headline'><p
                                            class='ladi-headline'>&nbsp; &nbsp; &nbsp; &nbsp;Giới
                                          thiệu</p></div>
                                      </div>
                                    </div>
                                    <div id="PARAGRAPH31" class='ladi-element'>
                                      <div class='ladi-paragraph'>1. Chính sách bảo mật này nhằm minh
                                        bạch với Đối tác, Người dùng về các thông tin bảo mật, xử lý
                                        dữ liệu của MTA.
                                        <br><br>2. Chính sách này bao gồm:
                                        <br>&nbsp; 1.1 Các loại thông tin mà chúng tôi thu thập từ
                                        người dùng, bao gồm cách hình thức cũng như mục đích thu
                                        thập.
                                        <br>&nbsp; 1.2 Liên quan đến các cam kết từ MTA và người
                                        dùng nhằm bảo mật, thông tin, dữ liệu trên nền tảng hoặc các
                                        dịch vụ khác mà Chúng tôi cung cấp.
                                        <br>&nbsp; 1.3 Bên thứ 3 ngoài MTA và người dùng biết đến
                                        thông tin và dữ liệu.
                                        <br>&nbsp; 1.4 Quyền và phạm vi của người dùng trên nền tảng
                                        của hoặc các dịch vụ khác mà chúng tôi cung cấp.
                                        <br>&nbsp; 1.5 Các biện pháp bảo mật dữ liệu cũng như các
                                        như quy định và bảo mật của chúng tôi.
                                        <br>&nbsp; 1.6 Lưu ý về cập nhật và thay đổi chính sách.
                                        <br>&nbsp; 1.7 Cách thức mà người dùng liên hệ với chúng
                                        tôi.<br></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="PARAGRAPH43" class='ladi-element'>
                  <div class='ladi-paragraph'>Bằng việc truy cập vào Website MTA và Ứng dụng MTA - Đối tác hoặc các sản
                    phẩm dịch vụ liên quan đến MTA, đồng nghĩa với việc đối tác đã xác nhận rằng đã đọc, hiểu và đồng ý
                    nội dung Chính sách bảo mật của chúng tôi.<br><br>Chính sách bảo mật này được thực hiện bởi Công ty
                    TNHH Mai Tâm Anh liên quan đến việc thu thập và bảo mật thông tin, xử lý dữ liệu và bảo mật dữ liệu
                    cá nhân theo Luật pháp Việt Nam hiện hành. Lưu ý rằng, Chính sách bảo mật dữ liệu này có thể thay
                    đổi nội dung bất cứ lúc nào mà không cần thông báo đến từng cá nhân người dùng. Khuyến khích đối tác
                    thường xuyên cập nhật trên Website MTA hoặc MTA - Đối tác mà chúng tôi đã cập nhật công khai trên hệ
                    thống. Đồng thời, Chính sách bảo mật được áp dụng để giải quyết mọi tranh chấp cả khi việc họp tác
                    hoặc cung cấp dịch vụ cho đối tác đã chấp dứt.<br></div>
                </div>
                <div id="GROUP80" class='ladi-element'>
                  <div class='ladi-group'>
                    <div id="GROUP81" class='ladi-element'>
                      <div class='ladi-group'>
                        <div id="GROUP82" class='ladi-element'>
                          <div class='ladi-group'>
                            <div id="GROUP83" class='ladi-element'>
                              <div class='ladi-group'>
                                <div id="BUTTON24" class='ladi-element'>
                                  <div class='ladi-button ladi-transition'>
                                    <div class="ladi-button-background"></div>
                                    <div id="BUTTON_SHAPE24" class='ladi-element ladi-button-shape'>
                                      <div class='ladi-shape'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="100%"
                                             height="100%" preserveAspectRatio="none"
                                             viewBox="0 0 1792 1896.08" class=""
                                             fill="rgba(255, 255, 255, 1)">
                                          <use xlink:href="#shape_qEcNJyTKdZ"></use>
                                        </svg>
                                      </div>
                                    </div>
                                    <div id="BUTTON_TEXT24" class='ladi-element ladi-button-headline'><p
                                        class='ladi-headline'>&nbsp; &nbsp; &nbsp; &nbsp;Thu thập
                                      thông tin</p></div>
                                  </div>
                                </div>
                                <div id="PARAGRAPH44" class='ladi-element'>
                                  <div class='ladi-paragraph'><span style="font-weight: bold;">1. Các thông tin mà chúng tôi thu thập<br></span>Khi
                                    Bạn sử dụng Ứng dụng, Bạn cần cung cấp cho Chúng tôi các thông tin
                                    cần thiết cũng như các dữ liệu cá nhân mà Chúng tôi thu nhập cụ thể
                                    như sau:<br>&nbsp; 1.1 Thông tin cơ bản: Ngày sinh, giới tính, năm
                                    sinh, địa chỉ.<br>&nbsp; 1.2 Thông tin điểm bán hàng: tên doanh
                                    nghiệp, địa chỉ, sản phẩm kinh doanh.<br>&nbsp; 1.3 Thông tin giao
                                    dịch: tài khoản ngân hàng, người thụ hưởng, số tài khoản.<br>&nbsp;
                                    1.4 Thông tin về kỹ thuật: thiết bị mà người dùng sử dụng, về hệ
                                    điều hành, vị trí, múi giờ và thời gian, phiên bản trình
                                    duyệt,..<br><br><span style="font-weight: bold;">2. Phương thức thu thập dữ liệu của Chúng tôi
<br></span>&nbsp; 2.1 Đối tác đăng ký tạo tài khoản với Chúng tôi và truy cập trên Website MTA và&nbsp; MTA - Đối tác
                                    hoặc bất kỳ dịch vụ khác mà MTA cung cấp.<br>&nbsp; 2.2 Người dùng
                                    thao tác trên hệ thống cho phép thiết bị theo dõi vào vị trí, truy
                                    cập vào danh bạ, thông tin trên thiết bị.<br>&nbsp; 2.3 Người dùng
                                    nhập hoặc tải thông tin, dữ liệu trên hệ thống liên quan đến các
                                    tính năng cần quản lý, theo dõi, báo cáo.<br>&nbsp; 2.4 Người dùng
                                    hoàn thành các biểu mẫu, khảo sát từ Chúng tôi.&nbsp;<br><br><span
                                        style="font-weight: bold;">3. Mục đích thu thập thông tin của đối tác/ người dùng</span><br>MTA
                                    cam kết bảo vệ quyền riêng tư và bảo mật thông tin cá nhân của Đối
                                    tác/ Người dùng. Chính sách này quy định cách Chúng tôi thu thập, sử
                                    dụng, và bảo vệ dữ liệu cá nhân của Đối tác. Chúng tôi thu thập dữ
                                    liệu cá nhân một cách minh bạch, an toàn và chỉ nhằm mục đích cung
                                    cấp dịch vụ, hỗ trợ trải nghiệm cho người dùng, cũng như phục vụ lợi
                                    ích hợp tác và mục đích kinh doanh hợp pháp theo quy định của pháp
                                    luật. Cụ thể như sau:<br><br>&nbsp; 3.1 Quản lý và đánh giá Đối tác:
                                    Quản lý, theo dõi và đánh giá năng lực, xếp hạng hoạt động của đối
                                    tác dựa trên dữ liệu thu thập được.<br>&nbsp; 3.2 Quản lý, theo dõi,
                                    đánh giá năng lực, xếp hạng hoạt động của Đối tác dựa trên các dữ
                                    liệu thu thập.<br>&nbsp; 3.3 Cải thiện dịch vụ và sản phẩm: Thống
                                    kê, đối chiếu dữ liệu để nâng cấp và bảo trì các cơ sở hạ tầng liên
                                    quan đến giao diện, trải nghiệm người dùng, và chất lượng dịch vụ,
                                    sản phẩm mà Chúng tôi cung cấp.<br>&nbsp; 3.4 Phát triển và phân
                                    tích: Nghiên cứu, lập kế hoạch và phân tích thống kê nhằm mục đích
                                    phát triển và cải thiện chất lượng sản phẩm, dịch vụ. Phục vụ các
                                    hoạt động kinh doanh, vận hành, và cải thiện quy trình làm việc nội
                                    bộ và với đối tác Khách hàng.<br>&nbsp; 3.5 Liên lạc và hỗ trợ người
                                    dùng: Liên lạc, cung cấp thông tin, và hỗ trợ người dùng thông qua
                                    việc chỉnh sửa, mở rộng, và khảo sát.<br>&nbsp; 3.6 Báo cáo và giải
                                    trình: Thực hiện báo cáo, giải trình và cung cấp thông tin cho các
                                    cơ quan nhà nước có thẩm quyền khi cần thiết.<br>&nbsp; 3.7 Thực
                                    hiện báo cáo, giải trình, cung cấp cho cơ quan nhà nước có thẩm
                                    quyền.<br><br></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="GROUP84" class='ladi-element'>
                  <div class='ladi-group'>
                    <div id="GROUP85" class='ladi-element'>
                      <div class='ladi-group'>
                        <div id="GROUP86" class='ladi-element'>
                          <div class='ladi-group'>
                            <div id="GROUP87" class='ladi-element'>
                              <div class='ladi-group'>
                                <div id="BUTTON25" class='ladi-element'>
                                  <div class='ladi-button ladi-transition'>
                                    <div class="ladi-button-background"></div>
                                    <div id="BUTTON_SHAPE25" class='ladi-element ladi-button-shape'>
                                      <div class='ladi-shape'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="100%"
                                             height="100%" preserveAspectRatio="none"
                                             viewBox="0 0 1792 1896.08" class=""
                                             fill="rgba(255, 255, 255, 1)">
                                          <use xlink:href="#shape_qEcNJyTKdZ"></use>
                                        </svg>
                                      </div>
                                    </div>
                                    <div id="BUTTON_TEXT25" class='ladi-element ladi-button-headline'><p
                                        class='ladi-headline'>&nbsp; &nbsp; &nbsp; &nbsp;Sử dụng
                                      thông tin</p></div>
                                  </div>
                                </div>
                                <div id="PARAGRAPH45" class='ladi-element'>
                                  <div class='ladi-paragraph'><span style="font-weight: bold;">1. Chia sẻ thông tin</span><br>&nbsp;
                                    1.1 Nhằm phục vụ mục đích thu thập, xử lý thông tin tại chính sách
                                    bảo mật này, cũng như nhằm nâng cao trải nghiệm người dùng, hoặc bất
                                    kỳ cách nào theo quy định pháp luật. Chúng tôi có thể chia sẻ thông
                                    tin dữ liệu cá nhân/ doanh nghiệp đối tác với bên thứ ba, bao
                                    gồm:<br>&nbsp; &nbsp; (a) Các đối tác kinh doanh, nhà cung cấp sản
                                    phẩm dịch vụ cho MTA (có liên quan trực tiếp/gián hoạt động hợp tác
                                    của Đối tác/Người dùng với MTA):
                                    <br>&nbsp;- Công ty Xổ Số Điện Toán Việt Nam - Bộ Tài Chính
                                    (Vietlott); Công ty cổ phần công nghệ Vidiva (Ví Ting, Pay Kit).
                                    <br>&nbsp;- Bộ phận xử lý thanh toán và xử lý hỗ trợ
                                    <br>&nbsp;- Nhà cung cấp lưu trữ đám mây.
                                    <br>&nbsp; &nbsp; (b) Các cơ quan Nhà nước có thẩm quyền khác
                                    <br><br>&nbsp; 1.2 Ngoài ra, chúng tôi có thể sử dụng dữ liệu mục
                                    đích tiếp thị, khuyến mãi bao gồm việc gửi tài liệu khuyến mãi, tiếp
                                    thị liên quan đến xổ số tự chọn điện toán, dịch vụ bảo hiểm, tuyển
                                    dụng, các hoạt động phi lợi nhuận/ từ thiện và các hoạt động chăm
                                    sóc khách hàng khác.
                                    <br><br><span
                                        style="font-weight: bold;">2. Lưu trữ dữ liệu cá nhân</span><br>&nbsp;Chúng
                                    tôi cam kết chỉ lưu trữ dữ dữ liệu cá nhân của người dùng trong
                                    trường hợp liên quan đến các mục đích được nhắc đến trong Chính sách
                                    bảo mật này.
                                    <br><br><span style="font-weight: bold;">3. Bảo mật thông tin</span><br>&nbsp;
                                    3.1 Chúng tôi áp dụng các biện pháp bảo mật nghiêm ngặt để bảo vệ
                                    thông tin cá nhân của Người dùng, các quy trình kiểm soát an ninh
                                    thông tin theo quy định nội bộ mà Chúng tôi ban hành. Bên cạnh đó,
                                    MTA phổ biến đến toàn thể nhân viên tuyệt đối không tiết lộ bất cứ
                                    thông tin dữ liệu cũng như là tài sản của công ty ra ngoài hay bất
                                    kỳ cá nhân tổ chức nào và có những chế tài quy định cho từng trường
                                    hợp vi phạm.
                                    <br>&nbsp; 3.2 Đối tác lưu trữ dữ liệu của chúng tôi chọn đã sàng
                                    lọc theo quy trình nội bộ, dựa trên các tiêu chí tiên quyết là đơn
                                    vị uy tín, được nhiều bên kiểm chứng.
                                    <br>&nbsp; 3.3 Chúng tôi cam kết cam kết nổ lực trong việc bảo vệ dữ
                                    liệu người dùng, đồng thời sử dụng thông tin theo mục đích của Chính
                                    sách bảo mật dữ liệu này.&nbsp;<br></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="GROUP88" class='ladi-element'>
                  <div class='ladi-group'>
                    <div id="GROUP89" class='ladi-element'>
                      <div class='ladi-group'>
                        <div id="GROUP90" class='ladi-element'>
                          <div class='ladi-group'>
                            <div id="BUTTON26" class='ladi-element'>
                              <div class='ladi-button ladi-transition'>
                                <div class="ladi-button-background"></div>
                                <div id="BUTTON_SHAPE26" class='ladi-element ladi-button-shape'>
                                  <div class='ladi-shape'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"
                                         preserveAspectRatio="none" viewBox="0 0 1792 1896.08" class=""
                                         fill="rgba(255, 255, 255, 1)">
                                      <use xlink:href="#shape_qEcNJyTKdZ"></use>
                                    </svg>
                                  </div>
                                </div>
                                <div id="BUTTON_TEXT26" class='ladi-element ladi-button-headline'><p
                                    class='ladi-headline'>&nbsp; &nbsp; &nbsp; &nbsp;Quyền của người
                                  dùng</p></div>
                              </div>
                            </div>
                            <div id="PARAGRAPH46" class='ladi-element'>
                              <div class='ladi-paragraph'>1. Người dùng được quyền truy cập vào hệ thống
                                website MTA và MTA - Đối tác (nếu đã có tài khoản truy cập), được quyền
                                chỉnh sửa, thay đổi thông tin cá nhân theo các yêu cầu tùy vào các tính năng
                                sẳn có.
                                <br>2. Được quyền ngừng cung cấp dịch vụ bằng văn bản gửi đến MTA
                                <br>&nbsp;Được quyền phản đối MTA xử lý dữ liệu cá nhân hoặc yêu cầu xóa dữ
                                liệu cá nhân trên hệ thống bằng văn bản gửi đến MTA.
                                <br>3. Các quyền khác theo quy định của pháp luật.<br></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="GROUP91" class='ladi-element'>
                  <div class='ladi-group'>
                    <div id="GROUP92" class='ladi-element'>
                      <div class='ladi-group'>
                        <div id="GROUP93" class='ladi-element'>
                          <div class='ladi-group'>
                            <div id="BUTTON27" class='ladi-element'>
                              <div class='ladi-button ladi-transition'>
                                <div class="ladi-button-background"></div>
                                <div id="BUTTON_SHAPE27" class='ladi-element ladi-button-shape'>
                                  <div class='ladi-shape'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"
                                         preserveAspectRatio="none" viewBox="0 0 1792 1896.08" class=""
                                         fill="rgba(255, 255, 255, 1)">
                                      <use xlink:href="#shape_qEcNJyTKdZ"></use>
                                    </svg>
                                  </div>
                                </div>
                                <div id="BUTTON_TEXT27" class='ladi-element ladi-button-headline'><p
                                    class='ladi-headline'>&nbsp; &nbsp; &nbsp; &nbsp;Liên hệ</p></div>
                              </div>
                            </div>
                            <div id="PARAGRAPH47" class='ladi-element'>
                              <div class='ladi-paragraph'>1. Nếu có bất kỳ thắc mắc hoặc khiếu nại nào về
                                Chính sách bảo mật này, đối tác có thể liên hệ với chúng tôi qua các phương
                                thức liên lạc say đây:
                                <br>&nbsp; &nbsp;- Hotline: 1900 633 035
                                <br>&nbsp; &nbsp;- Email: cskh@maitamanh.vn<br>&nbsp; &nbsp;Gửi thư trực
                                tiếp qua địa chỉ: Công ty TNHH đầu tư Mai Tâm Anh - Tầng 14, Tòa nhà Lim 3,
                                29A Nguyễn Đình Chiểu, Phường Đa Kao, Quận 1, Tp.HCM.
                                <br><br>2. MTA sẽ phản hồi đối tác qua điện thoại, email hoặc địa chỉ mà đối
                                tác đã đăng ký thông tin với chúng tôi trong các ngày làm việc.<br></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="GROUP94" class='ladi-element'>
                  <div class='ladi-group'>
                    <div id="GROUP95" class='ladi-element'>
                      <div class='ladi-group'>
                        <div id="GROUP96" class='ladi-element'>
                          <div class='ladi-group'>
                            <div id="BUTTON28" class='ladi-element'>
                              <div class='ladi-button ladi-transition'>
                                <div class="ladi-button-background"></div>
                                <div id="BUTTON_SHAPE28" class='ladi-element ladi-button-shape'>
                                  <div class='ladi-shape'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"
                                         preserveAspectRatio="none" viewBox="0 0 1792 1896.08" class=""
                                         fill="rgba(255, 255, 255, 1)">
                                      <use xlink:href="#shape_qEcNJyTKdZ"></use>
                                    </svg>
                                  </div>
                                </div>
                                <div id="BUTTON_TEXT28" class='ladi-element ladi-button-headline'><p
                                    class='ladi-headline'>&nbsp; &nbsp; &nbsp; &nbsp;Điều khoản khác</p>
                                </div>
                              </div>
                            </div>
                            <div id="PARAGRAPH48" class='ladi-element'>
                              <div class='ladi-paragraph'>1. Chính sách bảo mật này có hiệu lực kể từ ngày
                                chúng tôi tôi đăng tải lên Website MTA và MTA - Đối tác. Khi cần thiết, MTA
                                có thể sửa đổi, bổ sung Chính sách, người dùng hiểu và đồng ý rằng, người
                                dùng tiếp tục truy cập sau thời điểm sửa đổi bổ sung, đồng nghĩa chấp nhận
                                các nội dung sửa đổi, bổ sung đó.
                                <br><br>2. Chính sách này xem như một thỏa thuận điện tử giữa MTA và đối tác
                                dựa trên tinh thần thiện chí. Trong quá trình thực hiện nếu phát sinh tranh
                                chấp, các bên sẽ chủ động giải quyết thông qua thương lượng hòa giải. Trong
                                thời hạn 30 (ba mươi) ngày kể từ ngày phát sinh khiếu nại, mâu thuẫn hoặc
                                tranh chấp. Nếu không thể tự giải quyết trong thời hạn này, đối tác hoặc MTA
                                có quyền đưa vụ việc ra Tòa án có thẩm quyền theo quy định của pháp luật
                                Việt Nam.<br></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-container>
    </v-sheet>
  </div>
</template>

<script>
import PageHeading from "../../components/PageHeading.vue";

import { apiUrl } from "@/main";
import axios from "axios";

export default {
  name: "Policy",
  components: { PageHeading },
  data: () => ({
    currentTab: null,
    offices: [],
    headquarters: {
      google_map: ""
    },
    Info: {
      meta_title: 'CÔNG TY TNHH ĐẦU TƯ MAI TÂM ANH | Điều khoản & Bảo mật'
    },
    isTopSectionLoaded: true
  }),
  created() {
    this.currentTab = this.$route.hash.substr(1);
  },
  mounted() {
    axios
      .get(`${apiUrl.page}/privacy-policy`)
      .then(response => {
        if (!response) return false;
        const data = response.data[0].acf;
        this.headquarters = data.top_section;
        this.offices = data.representative_office;
      })
      .catch(err => console.log(err))
      .finally(() => {
        this.isTopSectionLoaded = false;
      });
    axios
        .get(`${apiUrl.api}/acf/v3/options/options`)
        .then(res => {
          if (!res.data) return false;
          this.Info = res.data.acf;
          this.firstLoad = false;
        })
        .catch(err => {
          console.log(err);
        });
  },
  metaInfo() {
    return {
      title: this.Info.meta_title  + ' | Điều khoản & Bảo mật',
      description: this.Info.meta_description,
      meta: [
        {
          name: "title",
          content: this.Info.meta_title
        },
        {
          name: "description",
          content: this.Info.meta_description
        },
        {
          property: "og:type",
          content: "website"
        },
        {
          property: "og:url",
          content: this.Info.meta_facebook_url
        },
        {
          property: "og:title",
          content: this.Info.meta_facebook_title
        },
        {
          property: "og:description",
          content: this.Info.meta_facebook_desciprtion
        },
        {
          property: "og:image",
          content: this.Info.meta_facebook_image
        },
        {
          property: "twitter:card",
          content: "summary_large_image"
        },
        {
          property: "twitter:url",
          content: this.Info.meta_twitter_url
        },
        {
          property: "twitter:title",
          content: this.Info.meta_twitter_title
        },
        {
          property: "twitter:description",
          content: this.Info.meta_twitter_desciprtion
        },
        {
          property: "twitter:image",
          content: this.Info.meta_twitter_image
        }
      ],
      htmlAttrs: {
        lang: "en"
      }
    };
  }
};
</script>
<style scoped lang="scss">
#ho-chi-minh {
  margin-top: -85px;
}
 abbr, acronym, address, applet, article, aside, audio, b, big, blockquote, button, canvas, caption, center, cite, code, dd, del, details, dfn, div, dl, dt, em, embed, fieldset, figcaption, figure, hgroup, i, iframe, img, input, ins, kbd, label, legend, li, mark, object, ol, output, pre, q, ruby, s, samp, select, small, strike {
  //margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block
}

body {
  line-height: 1
}

a {
  text-decoration: none
}

ol, ul {
  list-style: none
}

blockquote, q {
  quotes: none
}

blockquote:after, blockquote:before, q:after, q:before {
  content: '';
  content: none
}

table {
  border-collapse: collapse;
  border-spacing: 0
}

.ladi-loading {
  z-index: 900000000000;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, .1)
}

.ladi-loading .loading {
  width: 80px;
  height: 80px;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  overflow: hidden;
  position: absolute
}

.ladi-loading .loading div {
  position: absolute;
  width: 6px;
  height: 6px;
  background: #fff;
  border-radius: 50%;
  animation: ladi-loading 1.2s linear infinite
}

.ladi-loading .loading div:nth-child(1) {
  animation-delay: 0s;
  top: 37px;
  left: 66px
}

.ladi-loading .loading div:nth-child(2) {
  animation-delay: -.1s;
  top: 22px;
  left: 62px
}

.ladi-loading .loading div:nth-child(3) {
  animation-delay: -.2s;
  top: 11px;
  left: 52px
}

.ladi-loading .loading div:nth-child(4) {
  animation-delay: -.3s;
  top: 7px;
  left: 37px
}

.ladi-loading .loading div:nth-child(5) {
  animation-delay: -.4s;
  top: 11px;
  left: 22px
}

.ladi-loading .loading div:nth-child(6) {
  animation-delay: -.5s;
  top: 22px;
  left: 11px
}

.ladi-loading .loading div:nth-child(7) {
  animation-delay: -.6s;
  top: 37px;
  left: 7px
}

.ladi-loading .loading div:nth-child(8) {
  animation-delay: -.7s;
  top: 52px;
  left: 11px
}

.ladi-loading .loading div:nth-child(9) {
  animation-delay: -.8s;
  top: 62px;
  left: 22px
}

.ladi-loading .loading div:nth-child(10) {
  animation-delay: -.9s;
  top: 66px;
  left: 37px
}

.ladi-loading .loading div:nth-child(11) {
  animation-delay: -1s;
  top: 62px;
  left: 52px
}

.ladi-loading .loading div:nth-child(12) {
  animation-delay: -1.1s;
  top: 52px;
  left: 62px
}

@keyframes ladi-loading {
  0%, 100%, 20%, 80% {
    transform: scale(1)
  }
  50% {
    transform: scale(1.5)
  }
}

.ladipage-message {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10000000000;
  background: rgba(0, 0, 0, .3)
}

.ladipage-message .ladipage-message-box {
  width: 400px;
  max-width: calc(100% - 50px);
  height: 160px;
  border: 1px solid rgba(0, 0, 0, .3);
  background-color: #fff;
  position: fixed;
  top: calc(50% - 155px);
  left: 0;
  right: 0;
  margin: auto;
  border-radius: 10px
}

.ladipage-message .ladipage-message-box span {
  display: block;
  background-color: rgba(6, 21, 40, .05);
  color: #000;
  padding: 12px 15px;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px
}

.ladipage-message .ladipage-message-box .ladipage-message-text {
  display: -webkit-box;
  font-size: 14px;
  padding: 0 20px;
  margin-top: 10px;
  line-height: 18px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis
}

.ladipage-message .ladipage-message-box .ladipage-message-close {
  display: block;
  position: absolute;
  right: 15px;
  bottom: 10px;
  margin: 0 auto;
  padding: 10px 0;
  border: none;
  width: 80px;
  text-transform: uppercase;
  text-align: center;
  color: #000;
  background-color: #e6e6e6;
  border-radius: 5px;
  text-decoration: none;
  font-size: 14px;
  line-height: 14px;
  font-weight: 600;
  cursor: pointer;
  outline: 0
}

.lightbox-screen {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  z-index: 9000000080;
  background: rgba(0, 0, 0, .5)
}

.lightbox-screen .lightbox-close {
  position: absolute;
  z-index: 9000000090;
  cursor: pointer
}

.lightbox-screen .lightbox-hidden {
  display: none
}

.lightbox-screen .lightbox-close {
  width: 16px;
  height: 16px;
  margin: 10px;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url("data:image/svg+xml;utf8, %3Csvg%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20fill%3D%22%23fff%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M23.4144%202.00015L2.00015%2023.4144L0.585938%2022.0002L22.0002%200.585938L23.4144%202.00015Z%22%3E%3C%2Fpath%3E%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M2.00015%200.585938L23.4144%2022.0002L22.0002%2023.4144L0.585938%202.00015L2.00015%200.585938Z%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E")
}

body {
  font-size: 12px;
  -ms-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -o-text-size-adjust: none;
  -webkit-text-size-adjust: none;
  background-color: #fff;
}

.overflow-hidden {
  overflow: hidden;
}

.ladi-transition {
  transition: all 150ms linear 0s;
}

.z-index-1 {
  z-index: 1;
}

.opacity-0 {
  opacity: 0;
}

.height-0 {
  height: 0 !important;
}

.pointer-events-none {
  pointer-events: none;
}

.transition-parent-collapse-height {
  transition: height 150ms linear 0s;
}

.transition-parent-collapse-top {
  transition: top 150ms linear 0s;
}

.transition-readmore {
  transition: height 350ms linear 0s;
}

.transition-collapse {
  transition: height 150ms linear 0s;
}

body.grab {
  cursor: grab;
}

.ladi-wraper {
  width: 100%;
  min-height: 100%;
  overflow: hidden;
}

.ladi-container {
  position: relative;
  margin: 0 auto;
  height: 100%;
}

.ladi-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  pointer-events: none;
}

.ladi-element {
  position: absolute;
}

@media (hover: hover) {
  .ladi-check-hover {
    opacity: 0;
  }
}

.ladi-section {
  margin: 0 auto;
  position: relative;
}

.ladi-section[data-tab-id] {
  display: none;
}

.ladi-section.selected[data-tab-id] {
  display: block;
}

.ladi-section .ladi-section-background {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  overflow: hidden;
}

.ladi-box {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.ladi-button {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.ladi-button:active {
  transform: translateY(2px);
  transition: transform 0.2s linear;
}

.ladi-button .ladi-button-background {
  height: 100%;
  width: 100%;
  pointer-events: none;
  transition: inherit;
}

.ladi-button > .ladi-button-headline, .ladi-button > .ladi-button-shape {
  width: 100% !important;
  height: 100% !important;
  top: 0 !important;
  left: 0 !important;
  display: table;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.ladi-button > .ladi-button-shape .ladi-shape {
  margin: auto;
  top: 0;
  bottom: 0;
}

.ladi-button > .ladi-button-headline .ladi-headline {
  display: table-cell;
  vertical-align: middle;
}

.ladi-group {
  position: absolute;
  width: 100%;
  height: 100%;
}

.ladi-shape {
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.ladi-cart-number {
  position: absolute;
  top: -2px;
  right: -7px;
  background: #f36e36;
  text-align: center;
  width: 18px;
  height: 18px;
  line-height: 18px;
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  border-radius: 100%;
}

.ladi-image {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.ladi-image .ladi-image-background {
  background-repeat: no-repeat;
  background-position: left top;
  background-size: cover;
  background-attachment: scroll;
  background-origin: content-box;
  position: absolute;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.ladi-headline {
  width: 100%;
  display: inline-block;
  word-break: break-word;
  background-size: cover;
  background-position: center center;
}

.ladi-headline a {
  text-decoration: underline;
}

.ladi-paragraph {
  width: 100%;
  display: inline-block;
  word-break: break-word;
}

.ladi-paragraph a {
  text-decoration: underline;
}

a[data-action] {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  cursor: pointer
}

a:visited {
  color: inherit
}

a:link {
  color: inherit
}

[data-opacity="0"] {
  opacity: 0
}

[data-hidden="true"] {
  display: none
}

[data-action="true"] {
  cursor: pointer
}

.ladi-hidden {
  display: none
}

.ladi-animation-hidden {
  visibility: hidden !important;
  opacity: 0 !important
}

.element-click-selected {
  cursor: pointer
}

.is-2nd-click {
  cursor: pointer
}

.ladi-button-shape.is-2nd-click, .ladi-accordion-shape.is-2nd-click {
  z-index: 1
}

.backdrop-popup {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 90000060
}

.backdrop-dropbox {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 90000040
}

.ladi-lazyload {
  background-image: none !important;
}

.ladi-list-paragraph ul li.ladi-lazyload:before {
  background-image: none !important;
}

#BODY_BACKGROUND {
  position: fixed;
  pointer-events: none;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  height: 100vh !important;
}

@media (min-width: 768px) {
}

@media (max-width: 767px) {
  .ladi-element.ladi-auto-scroll {
    overflow-x: auto;
    overflow-y: hidden;
    width: 100% !important;
    left: 0 !important;
    -webkit-overflow-scrolling: touch;
  }

  [data-hint]:not([data-timeout-id-copied]):before, [data-hint]:not([data-timeout-id-copied]):after {
    display: none !important;
  }

  .ladi-section.ladi-auto-scroll {
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
  }
}</style>
<style id="style_page" type="text/css">body {
                                        direction: ltr;
                                      }

@media (min-width: 768px) {
  .ladi-section .ladi-container {
    width: 1200px;
  }
}

@media (max-width: 767px) {
  .ladi-section .ladi-container {
    width: 420px;
  }
}

body {
  font-family: "Open Sans", sans-serif
}</style>
<style id="style_element" type="text/css">#BODY_BACKGROUND {
                                           height: 0px;
                                         }

#HEADLINE58 > .ladi-headline {
  font-weight: bold;
  line-height: 1.6;
  color: rgb(250, 174, 64);
  text-align: center;
}

#GROUP51, #BUTTON_TEXT12, #BUTTON_TEXT26, #BUTTON_TEXT27, #BUTTON_TEXT28, #GROUP71, #GROUP72, #GROUP76 {
  left: 0px;
}

#BOX5 > .ladi-box {
  border-width: 10px;
  border-radius: 1px;
  border-style: solid;
  border-color: rgb(241, 243, 244);
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.5) 0px 15px 20px -15px;
}

#GROUP57 {
  top: 0px;
}

#GROUP77, #GROUP78, #GROUP79, #BUTTON12, #BUTTON_SHAPE12, #GROUP81, #GROUP82, #GROUP83, #BUTTON24, #BUTTON_SHAPE24, #GROUP85, #GROUP86, #GROUP87, #BUTTON25, #BUTTON_SHAPE25, #GROUP89, #GROUP90, #BUTTON26, #BUTTON_SHAPE26, #GROUP92, #GROUP93, #BUTTON27, #BUTTON_SHAPE27, #GROUP95, #GROUP96, #BUTTON28, #BUTTON_SHAPE28, #IMAGE13, #GROUP70, #SHAPE30, #SHAPE31, #SHAPE32, #GROUP74, #SHAPE35, #PARAGRAPH40, #IMAGE14 > .ladi-image > .ladi-image-background, #IMAGE15 > .ladi-image > .ladi-image-background, #IMAGE16, #IMAGE16 > .ladi-image > .ladi-image-background {
  top: 0px;
  left: 0px;
}

#BUTTON12 > .ladi-button > .ladi-button-background, #BUTTON24 > .ladi-button > .ladi-button-background, #BUTTON25 > .ladi-button > .ladi-button-background, #BUTTON26 > .ladi-button > .ladi-button-background, #BUTTON27 > .ladi-button > .ladi-button-background, #BUTTON28 > .ladi-button > .ladi-button-background, #SECTION8 > .ladi-section-background {
  background-color: rgb(113, 43, 143);
}

#BUTTON12 > .ladi-button, #BUTTON24 > .ladi-button, #BUTTON25 > .ladi-button, #BUTTON26 > .ladi-button, #BUTTON27 > .ladi-button, #BUTTON28 > .ladi-button {
  border-radius: 10px;
}

#BUTTON12 > .ladi-button:hover, #BUTTON24 > .ladi-button:hover, #BUTTON25 > .ladi-button:hover, #BUTTON26 > .ladi-button:hover, #BUTTON27 > .ladi-button:hover, #BUTTON28 > .ladi-button:hover {
  /*transform: scale(1.1);*/
  /*opacity: 1;*/
}

#BUTTON_SHAPE12 > .ladi-shape, #BUTTON_SHAPE24 > .ladi-shape, #BUTTON_SHAPE25 > .ladi-shape, #BUTTON_SHAPE26 > .ladi-shape, #BUTTON_SHAPE27 > .ladi-shape, #BUTTON_SHAPE28 > .ladi-shape {
  width: 18.9021px;
  height: 20px;
  left: 10px;
}

#BUTTON_SHAPE12 svg:last-child, #BUTTON_SHAPE24 svg:last-child, #BUTTON_SHAPE25 svg:last-child, #BUTTON_SHAPE26 svg:last-child, #BUTTON_SHAPE27 svg:last-child, #BUTTON_SHAPE28 svg:last-child, #SHAPE33 svg:last-child, #SHAPE34 svg:last-child, #SHAPE35 svg:last-child {
  fill: rgb(255, 255, 255);
}

#BUTTON_TEXT12 > .ladi-headline, #BUTTON_TEXT24 > .ladi-headline, #BUTTON_TEXT25 > .ladi-headline, #BUTTON_TEXT26 > .ladi-headline, #BUTTON_TEXT27 > .ladi-headline, #BUTTON_TEXT28 > .ladi-headline {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.6;
  color: rgb(255, 255, 255);
  text-align: justify;
  padding-left: 20px;
}

#PARAGRAPH31 > .ladi-paragraph {
  font-size: 15px;
  line-height: 1.6;
  color: rgb(0, 0, 0);
}

#PARAGRAPH43 > .ladi-paragraph {
  line-height: 1.6;
  color: rgb(0, 0, 0);
}

#BUTTON24, #BUTTON25 {
  height: 54.0519px;
}

#BUTTON_TEXT24, #BUTTON_TEXT25 {
  top: 31.8336px;
  left: 0px;
}

#PARAGRAPH44 {
  top: 76.7293px;
}

#PARAGRAPH44 > .ladi-paragraph, #PARAGRAPH45 > .ladi-paragraph, #PARAGRAPH46 > .ladi-paragraph, #PARAGRAPH47 > .ladi-paragraph, #PARAGRAPH48 > .ladi-paragraph {
  font-size: 15px;
  line-height: 1.6;
  color: rgb(0, 0, 0);
  text-align: justify;
}

#GROUP84, #GROUP85, #GROUP86, #GROUP87 {
  height: 569.729px;
}

#PARAGRAPH45 {
  top: 65.7293px;
}

#GROUP88, #GROUP89, #GROUP90 {
  height: 192.729px;
}

#GROUP91, #GROUP92, #GROUP93 {
  height: 215.729px;
}

#GROUP94, #GROUP95, #GROUP96 {
  height: 209.729px;
}

#IMAGE13 > .ladi-image > .ladi-image-background {
  top: 0px;
  left: -2.24122px;
}

#HEADLINE67 > .ladi-headline {
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.2;
  color: rgb(113, 43, 143);
  text-align: left;
}

#SHAPE30 svg:last-child, #SHAPE31 svg:last-child, #SHAPE32 svg:last-child {
  fill: rgb(23, 23, 23);
}

#HEADLINE68 > .ladi-headline, #HEADLINE69 > .ladi-headline, #HEADLINE70 > .ladi-headline {
  font-size: 14px;
  line-height: 1.4;
  color: rgb(23, 23, 23);
  text-align: left;
}

#GROUP71, #GROUP72 {
  width: 325px;
}

#SHAPE31, #SHAPE32 {
  width: 22.5469px;
}

#HEADLINE69, #HEADLINE70 {
  width: 292px;
  left: 33px;
}

#GROUP73 {
  width: 199.825px;
  height: 40.387px;
}

#SHAPE33 {
  width: 40.387px;
  height: 40.387px;
  top: 0px;
  left: 109.615px;
}

#GROUP74 {
  width: 199.825px;
  height: 40px;
}

#SHAPE34, #SHAPE35, #SHAPE36 {
  width: 40px;
  height: 40px;
}

#SHAPE34 {
  top: 0px;
  left: 53.825px;
}

#SHAPE36 {
  top: 0px;
  left: 159.825px;
}

#SHAPE36 svg:last-child {
  fill: rgb(0, 0, 0);
}

#GROUP75, #PARAGRAPH40 {
  width: 469px;
}

#PARAGRAPH40 > .ladi-paragraph, #PARAGRAPH41 > .ladi-paragraph {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.6;
  color: rgb(0, 0, 0);
  text-transform: uppercase;
}

#GROUP76 {
  width: 266px;
  height: 114.991px;
}

#IMAGE14, #IMAGE14 > .ladi-image > .ladi-image-background {
  width: 144.967px;
  height: 42.7653px;
}

#IMAGE14 {
  top: 12.2187px;
  left: 121.033px;
}

#IMAGE14 > .ladi-image > .ladi-image-background {
  background-image: url("https://w.ladicdn.com/s450x350/66c2b0ee8807d000127ea359/momo-upload-api-210724113855-637627235353131497-20240808073949-rjtmm.jpg");
}

#IMAGE15, #IMAGE15 > .ladi-image > .ladi-image-background {
  width: 144.967px;
  height: 41.629px;
}

#IMAGE15 {
  top: 66.0308px;
  left: 121.033px;
}

#IMAGE15 > .ladi-image > .ladi-image-background {
  background-image: url("https://w.ladicdn.com/s450x350/66c2b0ee8807d000127ea359/momo-upload-api-210724113959-637627235994410679-20240808073949-p-mwh.jpg");
}

#IMAGE16, #IMAGE16 > .ladi-image > .ladi-image-background {
  width: 114.991px;
  height: 114.991px;
}

#IMAGE16 > .ladi-image > .ladi-image-background {
  background-image: url("https://w.ladicdn.com/s450x450/66c2b0ee8807d000127ea359/qrcode_121370605_9db5ab755826e56525ddc40b3aac7d0b-20240808074646-2opjc.png");
}

#PARAGRAPH42 > .ladi-paragraph {
  font-size: 14px;
  line-height: 1.6;
  color: rgb(255, 255, 255);
}

@media (min-width: 768px) {
  #SECTION2 {
    height: 2756px;
  }

  #SECTION2 > .ladi-section-background {
    background-image: url("https://w.ladicdn.com/s1440x2856/66c2b0ee8807d000127ea359/_final-2-feature-graphic-1024x500px-1-20240805064035-5sbwx.png");
    background-size: cover;
    background-position: 50% 0%;
    background-repeat: repeat;
    background-attachment: fixed;
    filter: blur(4px);
  }

  #HEADLINE58, #BUTTON_TEXT12, #BUTTON_TEXT24, #BUTTON_TEXT25, #BUTTON_TEXT26, #BUTTON_TEXT27, #BUTTON_TEXT28 {
    width: 1200px;
  }

  #HEADLINE58 {
    top: 43.4999px;
    left: 0.5px;
  }

  #HEADLINE58 > .ladi-headline {
    font-size: 50px;
  }

  #GROUP51 {
    width: 1200.46px;
    height: 2450.75px;
    top: 240px;
  }

  #BOX5 {
    width: 1198px;
    height: 2450.75px;
    top: 0px;
    left: 0.5px;
  }

  #GROUP57, #GROUP77, #GROUP78, #GROUP79 {
    width: 1200.46px;
    height: 305.729px;
  }

  #GROUP57 {
    left: 0px;
  }

  #BUTTON12, #BUTTON26, #BUTTON27, #BUTTON28 {
    width: 1200.46px;
    height: 54.0519px;
  }

  #BUTTON_TEXT12, #BUTTON_TEXT26, #BUTTON_TEXT27, #BUTTON_TEXT28 {
    top: 31.8336px;
  }

  #PARAGRAPH31, #PARAGRAPH44, #PARAGRAPH45, #PARAGRAPH46, #PARAGRAPH47, #PARAGRAPH48 {
    width: 1140px;
  }

  #PARAGRAPH31, #PARAGRAPH48 {
    top: 65.7293px;
    left: 35.5px;
  }

  #PARAGRAPH43 {
    width: 1130px;
    /*top: 145.5px;*/
    left: 30px;
  }

  #PARAGRAPH43 > .ladi-paragraph {
    font-size: 16px;
    text-align: justify;
  }

  #GROUP80, #GROUP81, #GROUP82, #GROUP83 {
    width: 1200.46px;
    height: 748.729px;
  }

  #GROUP80 {
    top: 580px;
    left: -0.23px;
  }

  #BUTTON24, #GROUP84, #GROUP85, #GROUP86, #GROUP87, #BUTTON25, #GROUP88, #GROUP89, #GROUP90, #GROUP91, #GROUP92, #GROUP93, #GROUP94, #GROUP95, #GROUP96 {
    width: 1200.46px;
  }

  #PARAGRAPH44, #PARAGRAPH45 {
    left: 35.5px;
  }

  #GROUP84 {
    top: 1340px;
    left: 0px;
  }

  #GROUP88 {
    top: 1940px;
    left: 0px;
  }

  #PARAGRAPH46 {
    top: 72.7293px;
    left: 30.23px;
  }

  #GROUP91 {
    top: 2160px;
    left: 0px;
  }

  #PARAGRAPH47 {
    top: 71.7293px;
    left: 30.23px;
  }

  #GROUP94 {
    top: 2400.43px;
    left: -0.23px;
  }

  #SECTION7 {
    height: 356.125px;
  }

  #SECTION7 > .ladi-section-background {
    background-color: rgb(255, 255, 255);
  }

  #GROUP68 {
    width: 325px;
    height: 109.125px;
    top: 66px;
    left: 0px;
  }

  #IMAGE13 {
    width: 161.425px;
    height: 74.125px;
  }

  #IMAGE13 > .ladi-image > .ladi-image-background {
    width: 163.666px;
    height: 74.125px;
    background-image: url("https://w.ladicdn.com/s500x400/66c2b0ee8807d000127ea359/logo-mta-2-20240808013440-ai_7v.png");
  }

  #HEADLINE67 {
    width: 319px;
    top: 85.125px;
    left: 6px;
  }

  #GROUP69 {
    width: 405.066px;
    height: 109.111px;
    top: 197.005px;
    left: 0px;
  }

  #GROUP70 {
    width: 405.066px;
    height: 63.4805px;
  }

  #SHAPE30 {
    width: 22.7189px;
    height: 22.7189px;
  }

  #HEADLINE68 {
    width: 372px;
    top: 4.03051px;
    left: 33.2517px;
  }

  #GROUP71 {
    height: 21.9113px;
    top: 52.782px;
  }

  #SHAPE31 {
    height: 21.5469px;
  }

  #HEADLINE69 {
    top: 1.9113px;
  }

  #GROUP72, #SHAPE32 {
    height: 22.5469px;
  }

  #GROUP72 {
    top: 86.5641px;
  }

  #HEADLINE70 {
    top: 2px;
  }

  #GROUP73 {
    top: 99.999px;
    left: 731px;
  }

  #GROUP75 {
    height: 151.061px;
    top: 181.055px;
    left: 731px;
  }

  #GROUP76 {
    top: 36.07px;
  }

  #PARAGRAPH41 {
    width: 469px;
    top: 65.999px;
    left: 731px;
  }

  #SECTION8 {
    height: 68.5px;
  }

  #PARAGRAPH42 {
    width: 804px;
    top: 13.25px;
    left: 0px;
  }
}

@media (max-width: 767px) {
  #SECTION2 {
    height: 5031.32px;
  }

  #SECTION2 > .ladi-section-background {
    background-color: rgb(255, 255, 255);
  }

  #HEADLINE58 {
    width: 396px;
    top: 30.656px;
    left: 12px;
  }

  #HEADLINE58 > .ladi-headline {
    font-size: 29px;
  }

  #GROUP51 {
    width: 420px;
    height: 4520.55px;
    top: 476.754px;
  }

  #BOX5 {
    width: 420px;
    height: 4518.35px;
    top: 2.20194px;
    left: 0px;
  }

  #GROUP57, #GROUP77, #GROUP78, #GROUP79 {
    width: 404.46px;
    height: 510.088px;
  }

  #GROUP57 {
    left: 8px;
  }

  #BUTTON12 {
    width: 404.46px;
    height: 47.499px;
  }

  #BUTTON_TEXT12 {
    width: 404px;
    top: 17.9622px;
  }

  #PARAGRAPH31 {
    width: 384px;
    top: 54.088px;
    left: 10px;
  }

  #PARAGRAPH43, #BUTTON_TEXT24, #PARAGRAPH41, #PARAGRAPH42 {
    width: 400px;
  }

  #PARAGRAPH43 {
    top: 101.656px;
    left: 12px;
  }

  #PARAGRAPH43 > .ladi-paragraph {
    font-size: 14px;
  }

  #GROUP80, #GROUP81, #GROUP82, #GROUP83 {
    width: 400.23px;
    height: 796.729px;
  }

  #GROUP80 {
    top: 1021.32px;
    left: 9.885px;
  }

  #BUTTON24 {
    width: 400.23px;
  }

  #PARAGRAPH44 {
    width: 380px;
    left: 11.8356px;
  }

  #GROUP84, #GROUP85, #GROUP86, #GROUP87, #BUTTON25 {
    width: 398.23px;
  }

  #GROUP84 {
    top: 2628.04px;
    left: 10.885px;
  }

  #BUTTON_TEXT25 {
    width: 398px;
  }

  #PARAGRAPH45 {
    width: 379px;
    left: 11.7765px;
  }

  #GROUP88, #GROUP89, #GROUP90, #GROUP91, #GROUP92, #GROUP93, #GROUP94, #GROUP95, #GROUP96 {
    width: 397.46px;
  }

  #GROUP88 {
    top: 3787.77px;
    left: 12.77px;
  }

  #BUTTON26, #BUTTON27, #BUTTON28 {
    width: 397.46px;
    height: 54.0518px;
  }

  #BUTTON_TEXT26, #BUTTON_TEXT27, #BUTTON_TEXT28 {
    width: 397px;
    top: 31.8335px;
  }

  #PARAGRAPH46, #PARAGRAPH47, #PARAGRAPH48 {
    width: 377px;
  }

  #PARAGRAPH46 {
    top: 72.7292px;
    left: 10.0089px;
  }

  #GROUP91 {
    top: 4115.5px;
    left: 12.77px;
  }

  #PARAGRAPH47 {
    top: 71.7292px;
    left: 10.0089px;
  }

  #GROUP94 {
    top: 4493.23px;
    left: 11.27px;
  }

  #PARAGRAPH48 {
    top: 65.7292px;
    left: 11.7537px;
  }

  #SECTION7 {
    height: 558.122px;
  }

  #SECTION7 > .ladi-section-background {
    background-color: rgb(236, 236, 236);
  }

  #GROUP68 {
    width: 287px;
    height: 95.125px;
    top: 46px;
    left: 10px;
  }

  #IMAGE13 {
    width: 141.5px;
    height: 64.125px;
  }

  #IMAGE13 > .ladi-image > .ladi-image-background {
    width: 143.741px;
    height: 64.125px;
    background-image: url("https://w.ladicdn.com/s450x400/66c2b0ee8807d000127ea359/logo-mta-2-20240808013440-ai_7v.png");
  }

  #HEADLINE67 {
    width: 283px;
    top: 71.125px;
    left: 4px;
  }

  #GROUP69 {
    width: 402px;
    height: 118.667px;
    top: 150.634px;
    left: 10px;
  }

  #GROUP70 {
    width: 402px;
    height: 28.6431px;
  }

  #SHAPE30 {
    width: 22.5469px;
    height: 28.0788px;
  }

  #HEADLINE68 {
    width: 369px;
    top: 4.98141px;
    left: 33px;
  }

  #GROUP71, #SHAPE31, #GROUP72, #SHAPE32 {
    height: 28.0789px;
  }

  #GROUP71 {
    top: 55.5092px;
  }

  #HEADLINE69, #HEADLINE70 {
    top: 2.49071px;
  }

  #GROUP72 {
    top: 90.5881px;
  }

  #GROUP73 {
    top: 485.125px;
    left: 111.087px;
  }

  #GROUP75 {
    height: 154.061px;
    top: 298.451px;
    left: 10px;
  }

  #GROUP76 {
    top: 39.07px;
  }

  #PARAGRAPH41 {
    top: 10px;
    left: 10px;
    display: none !important;
  }

  #SECTION8 {
    height: 87px;
  }

  #PARAGRAPH42 {
    top: 10px;
    left: 11px;
  }
}
</style>
<style id="style_lazyload"
       type="text/css">body.lazyload .ladi-overlay, body.lazyload .ladi-box, body.lazyload .ladi-button-background, body.lazyload .ladi-collection-item:before, body.lazyload .ladi-countdown-background, body.lazyload .ladi-form-item-background, body.lazyload .ladi-form-label-container .ladi-form-label-item.image, body.lazyload .ladi-frame-background, body.lazyload .ladi-gallery-view-item, body.lazyload .ladi-gallery-control-item, body.lazyload .ladi-headline, body.lazyload .ladi-image-background, body.lazyload .ladi-image-compare, body.lazyload .ladi-list-paragraph ul li:before, body.lazyload .ladi-section-background, body.lazyload .ladi-survey-option-background, body.lazyload .ladi-survey-option-image, body.lazyload .ladi-tabs-background, body.lazyload .ladi-video-background, body.lazyload .ladi-banner, body.lazyload .ladi-spin-lucky-screen, body.lazyload .ladi-spin-lucky-start {
  background-image: none !important;
}</style>
