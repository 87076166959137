import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';
import * as GmapVue from 'gmap-vue';
import VueMeta from 'vue-meta'

Vue.config.productionTip = false
Vue.use(VueMeta, {
    // optional pluginOptions
    refreshOnceOnNavigation: true
})

Vue.use(GmapVue, {
    load: {
        key: 'AIzaSyDRDRTbbp9rEeaSEH6E_abi1WoMorFmiz4', // pnhudinh@gmail.com,  QIS: AIzaSyBFu8GxaN9UbcYcqn_O7Bfe0aINZ29xtVY
        libraries: 'places', // This is required if you use the Autocomplete plugin
        region: 'VI',
        language: 'vi',
        // OR: libraries: 'places,drawing'
        // OR: libraries: 'places,drawing,visualization'
        // (as you require)

        //// If you want to set the version, you can do so:
        // v: '3.26',
    },

    //// If you intend to programmatically custom event listener code
    //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
    //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
    //// you might need to turn this on.
    autobindAllEvents: false,

    //// If you want to manually install components, e.g.
    //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
    //// Vue.component('GmapMarker', GmapMarker)
    //// then set installComponents to 'false'.
    //// If you want to automatically install all the components this property must be set to 'true':
    installComponents: true
});

export const siteKeyCaptcha = "6LeO__UUAAAAADODrcx9GptP8phmrwblGJDCuoYj"

export const apiUrl = {
    api: "https://cms.maitamanh.vn:445/wp-json",
    page: "https://cms.maitamanh.vn:445/wp-json/wp/v2/pages?_embed&slug=",
    extUrl: "https://api.maitamanh.vn:444",
    product: "https://cms.maitamanh.vn:445/wp-json/wp/v2/pages",
    saApiKey: "YmnsVHYGaKDs5MZc2ZkF6DFj-a_yDZQy"
};
// export const apiUrl = {
//     api: "http://cms.maitamanh.internal/wp-json",
//     page: "http://cms.maitamanh.internal/wp-json/wp/v2/pages?_embed&slug=",
//     extUrl: "http://api.diva-agent.internal",
//     product: "http://cms.maitamanh.internal/wp-json/wp/v2/pages",
//     saApiKey: "YmnsVHYGaKDs5MZc2ZkF6DFj-a_yDZQy"
// };

new Vue({
    router,
    vuetify,
    render: h => h(App)
}).$mount("#app");
