<template>
  <section class="cmn-section organizational-structure" id="organizational-structure">
    <v-container>
      <page-heading :heading="title" :subHeading="subtitle" />
      <div class="organizational-structure__content mt-10 mt-md-10 text-center">
        <img :src="mainContent" alt=""/>
      </div>
    </v-container>
  </section>
</template>

<script>
import PageHeading from "@/components/PageHeading.vue";
import _mainContent from "@/assets/img/about/organizational-structure.png";
export default {
  components: { PageHeading },
  name: "OrganizationalStructure",
  data: () => ({
    title: "Cơ cấu tổ chức",
    subtitle: "HỆ THỐNG ĐIỀU HÀNH, GIÁM SÁT, KINH DOANH & HỖ TRỢ NGHIỆP VỤ",
    mainContent: _mainContent
  })
};
</script>
