<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="57.001" height="36" viewBox="0 0 57.001 36">
    <defs>
      <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
        <stop offset="0" stop-color="#fff" stop-opacity="0"/>
        <stop offset="1" stop-color="#fff"/>
      </linearGradient>
    </defs>
    <path id="Union_4" data-name="Union 4" d="M0,20.3H20.073C19.587,16.223,17.565,6.471,9.194,0H49.646c-7.262,7.1-9.874,16.367-10.7,20.3H57L28.5,36Z" opacity="0.7" fill="url(#linear-gradient)"/>
  </svg>
</template>

<script>
export default {
  name: "ArrowDown"
}
</script>

<style scoped>

</style>