<template>
  <right-content>
    <v-skeleton-loader
      v-bind="attrs"
      type="article"
      :loading="isLoading"
      v-if="isLoading"
    ></v-skeleton-loader>
    <section class="post-details pa-5 pa-md-10" v-else>
      <div v-if="!error">
        <span class="post-item__date mb-3 text-15">
          {{ details.createdDate }}
        </span>
        <h6
          class="post-item__title primary--text mb-4 text-md-22 text-20 font-weight-bold"
          v-html="details.title"
        ></h6>
        <div class="post-details__content mb-10" v-html="details.content"></div>
        <div class="post-author text-center" v-if="details.authorId">
          <v-avatar size="128" class="mb-md-5 mb-3">
            <img :src="author.avatar" :alt="author.name" />
          </v-avatar>
          <h6 class="text-16 text-md-20 mb-2 font-weight-bold">
            {{ author.name }}
          </h6>
          <p class="grey--text text--darken-4 text-14 text-md-16 mb-3">
            {{ author.position }}
          </p>
          <v-btn
            elevation="0"
            text
            :to="`/tin-tuc/tac-gia/${author.id}`"
            class="black--text"
          >
            {{ `Xem thêm bài viết từ ${author.name}` }}
          </v-btn>
          <div
            class="post-details__navigation d-flex justify-space-between align-center mt-5"
          >
            <v-btn
              v-if="details.prevPostId"
              elevation="0"
              text
              :to="`/tin-tuc/${details.prevPostId}`"
              class="black--text"
            >
              <v-icon small class="mr-1">mdi-arrow-left</v-icon>
              Trước
            </v-btn>
            <v-btn
              v-if="details.nextPostId"
              elevation="0"
              text
              :to="`/tin-tuc/${details.nextPostId}`"
              class="black--text"
            >
              Tiếp
              <v-icon small class="ml-1">mdi-arrow-right</v-icon>
            </v-btn>
          </div>
        </div>
      </div>
      <div class="text-13 text-md-16" v-else v-html="error"></div>
    </section>
  </right-content>
</template>

<script>
import RightContent from "../../components/RightContent.vue";
import sampleAuthorImage from "../../assets/img/news/author.png";
import axios from "axios";
import { apiUrl } from "@/main";

export default {
  components: { RightContent },
  name: "NewsDetails",
  data: () => ({
    isLoading: true,
    details: {},
    error: null,
    author: {
      id: "spaceD",
      avatar: sampleAuthorImage,
      name: "Full Name",
      position: "Position"
    }
  }),
  methods: {
    getPostDetails: function(postId) {
      this.isLoading = true;
      const url = `${apiUrl.api}/wp/v2/posts/${postId}`;

      axios
        .get(url)
        .then(response => {
          if (!response.data) return false;
          const {
            title: { rendered: title },
            date_gmt: createdDate,
            content: { rendered: content }
          } = response.data;

          this.details = {
            title,
            createdDate: new Date(createdDate).toLocaleDateString(),
            content
          };

          this.error = null;
        })
        .catch(err => {
          this.error = "Không tìm thấy bài viết";
        })
        .finally(() => (this.isLoading = false));
    }
  },
  mounted() {
    this.getPostDetails(this.$route.params.postId);
  },
  watch: {
    $route(to, from) {
      const currentPostId = to.params.postId;
      if (currentPostId) this.getPostDetails(currentPostId);
    }
  }
};
</script>
