<template>
  <section class="cmn-section executive-board" id="executive-board">
    <page-heading :heading="title"></page-heading>
    <div class="executive-board__content">
      <v-carousel
        v-model="model"
        prev-icon="mdi-chevron-left"
        next-icon="mdi-chevron-right"
        hide-delimiters
        :height="$vuetify.breakpoint.smAndDown ? 600 : 400"
        v-if="Model"
      >
        <template v-for="(item, index) in Model">
          <v-carousel-item v-if="(index + 1) % columns === 1 || columns === 1"  :key="index">
            <v-row class="flex-nowrap" style="height:100%">
              <template v-for="(n,i) in columns">
                <template v-if="(+index + i) < Model.length">
                  <v-col :key="i">
                    <v-sheet v-if="(+index + i) < Model.length"
                             height="100%"
                    >
                      <v-row class="fill-height"
                             align="center"
                             justify="center"
                      >
                        <div class="display-1">
                          <div class="eb-item black--text">
                            <div class="eb-item__info">
                              <v-dialog
                                  v-model="dialog[(+index + i)]"
                                  width="60%"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-avatar class="mb-2 mb-md-4" color="lightgrey" size="128" v-bind="attrs"
                                            v-on="on">
                                    <img v-if="Model[(+index + i)].image" :alt="Model[(+index + i)].name" :title="'Giới thiệu về ' + Model[(+index + i)].name" :src="Model[(+index + i)].image"/>
                                    <v-icon v-else size="80" color="#CCC">mdi-account</v-icon>
                                  </v-avatar>
                                </template>

                                <v-card>
                                  <v-img
                                      class="white--text align-end"
                                      max-height="400px"
                                      :src="Model[(+index + i)].large_image"
                                  >
                                  </v-img>
                                  <v-card-title>{{Model[(+index + i)].name}}</v-card-title>
                                  <v-card-subtitle>{{Model[(+index + i)].position}}</v-card-subtitle>

                                  <v-card-text v-html="Model[(+index + i)].content">

                                  </v-card-text>
                                </v-card>
                              </v-dialog>
                              <h6
                                  class="font-weight-bold text-16 text-md-17 mb-1" style="color: white"
                              >
                                {{ Model[(+index + i)].name }}
                              </h6>
                              <p class="text-13 text-md-13 text-capitalize" style="color: white">
                                {{ Model[(+index + i)].position }}
                              </p>
                            </div>
                            <!--            <div class="text-md-16 text-13">-->
                            <!--              <div v-html="item.content" class="text-paragraph"/>-->
                            <!--            </div>-->
                          </div>
                        </div>
                      </v-row>
                    </v-sheet>
                  </v-col>
                </template>
              </template>
            </v-row>
          </v-carousel-item>
        </template>
      </v-carousel>
    </div>
  </section>
</template>

<script>
import PageHeading from "@/components/PageHeading";

export default {
  name: "ExecutiveBoard",
  components: { PageHeading },
  props: {
    Model: {
      type: Array,
      required: true,
    }
  },
  data: function () {
    let data = {
      title: "Đội Ngũ Mai Tâm Anh",
      model: 0,
      dialog: [],
    }
    let dialogs = [];
    this.Model.forEach((value, index) => {
      dialogs[index] = false;
    });

    data['dialog'] = dialogs;

    return data;
  },
  computed: {
    columns() {
      if (this.$vuetify.breakpoint.xl) {
        return 3;
      }

      if (this.$vuetify.breakpoint.lg) {
        return 3;
      }

      if (this.$vuetify.breakpoint.md) {
        return 2;
      }

      return 1;
    }
  },
  methods: {
  }
};
</script>

<style scoped></style>
