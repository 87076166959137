<template>
  <div class="contact">
    <v-sheet
      elevation="0"
      color="#F6F7F9"
      class="pt-4 pb-4 pt-md-10 pb-md-15 pt-md-13"
    >
      <v-container>
        <div class="mb-5 mb-md-13">
          <page-heading heading="Ứng dụng di động"></page-heading>
        </div>
      </v-container>
    </v-sheet>
  </div>
</template>

<script>
import PageHeading from "../../components/PageHeading.vue";

import { apiUrl } from "@/main";
import axios from "axios";

export default {
  name: "MobileApp",
  components: { PageHeading },
  data: () => ({
    currentTab: null,
    offices: [],
    headquarters: {
      google_map: ""
    },
    Info: {
      meta_title: 'CÔNG TY TNHH ĐẦU TƯ MAI TÂM ANH | Ứng dụng di động'
    },
    isTopSectionLoaded: true
  }),
  created() {
    this.currentTab = this.$route.hash.substr(1);
  },
  mounted() {
    axios
      .get(`${apiUrl.page}/mobile-app`)
      .then(response => {
        if (!response) return false;
        const data = response.data[0].acf;
        this.headquarters = data.top_section;
        this.offices = data.representative_office;
      })
      .catch(err => console.log(err))
      .finally(() => {
        this.isTopSectionLoaded = false;
      });
    axios
        .get(`${apiUrl.api}/acf/v3/options/options`)
        .then(res => {
          if (!res.data) return false;
          this.Info = res.data.acf;
          this.firstLoad = false;
        })
        .catch(err => {
          console.log(err);
        });
  },
  metaInfo() {
    return {
      title: this.Info.meta_title  + ' | Ứng dụng di động',
      description: this.Info.meta_description,
      meta: [
        {
          name: "title",
          content: this.Info.meta_title
        },
        {
          name: "description",
          content: this.Info.meta_description
        },
        {
          property: "og:type",
          content: "website"
        },
        {
          property: "og:url",
          content: this.Info.meta_facebook_url
        },
        {
          property: "og:title",
          content: this.Info.meta_facebook_title
        },
        {
          property: "og:description",
          content: this.Info.meta_facebook_desciprtion
        },
        {
          property: "og:image",
          content: this.Info.meta_facebook_image
        },
        {
          property: "twitter:card",
          content: "summary_large_image"
        },
        {
          property: "twitter:url",
          content: this.Info.meta_twitter_url
        },
        {
          property: "twitter:title",
          content: this.Info.meta_twitter_title
        },
        {
          property: "twitter:description",
          content: this.Info.meta_twitter_desciprtion
        },
        {
          property: "twitter:image",
          content: this.Info.meta_twitter_image
        }
      ],
      htmlAttrs: {
        lang: "en"
      }
    };
  }
};
</script>
<style scoped lang="scss">
#ho-chi-minh {
  margin-top: -85px;
}
</style>
