<template>
  <section class="register">
    <div class="pb-13">
      <page-heading :heading="title" :subHeading="description"/>
    </div>
    <form @submit.prevent="onFormSubmit" enctype="multipart/form-data">
      <div class="mb-15">
        <v-subheader>Thông tin liên hệ</v-subheader>
        <div class="pt-3">
          <div class="form-group">
            <p class="form-label">
              Họ và tên
              <span class="field-required">*</span>
            </p>
            <div class="form-input">
              <v-text-field
                  outlined
                  full-width
                  dense
                  name="your_name"
                  id="your_name"
                  v-model="formData.your_name"
                  @input="setFromValues('your_name', $event)"
                  :hide-details="!formErrors.your_name"
                  :error-messages="formErrors.your_name"
              />
            </div>
          </div>
        </div>
        <div class="pt-3">
          <div class="form-group">
            <p class="form-label">
              Số điện thoại
              <span class="field-required">*</span>
            </p>
            <div class="form-input">
              <v-text-field
                  @input="setFromValues('your_phone', $event)"
                  outlined
                  full-width
                  :hide-details="!formErrors.your_phone"
                  dense
                  name="your_phone"
                  id="your_phone"
                  v-model="formData.your_phone"
                  :error-messages="formErrors.your_phone"
              />
            </div>
          </div>
        </div>
        <div class="pt-3">
          <div class="form-group address-div">
            <p class="form-label">
              Địa chỉ
              <span class="field-required">*</span>
            </p>
            <div class="address-field">
              <div class="address-field-item">
                <p class="address-form-label">
                  Tỉnh/Thành phố
                  <span class="field-required">*</span>
                </p>
                <div class="address-form-input">
                  <v-select
                      @change="setFromValues('your_city', $event)"
                      name="your_city"
                      id="your_city"
                      outlined
                      dense
                      :items="cityOptions"
                      :hide-details="!formErrors.your_city"
                      full-width
                      placeholder="- lựa chọn -"
                      v-model="formData.your_city"
                      :error-messages="formErrors.your_city"
                  />
                </div>
              </div>
              <div class="address-field-item">
                <p class="address-form-label">
                  Quận/Huyện/Thị Xã
                  <span class="field-required">*</span>
                </p>
                <div class="address-form-input">
                  <v-select
                      @change="setFromValues('your_district', $event)"
                      name="your_district"
                      id="your_district"
                      outlined
                      dense
                      :items="districtOptions"
                      :hide-details="!formErrors.your_district"
                      full-width
                      placeholder="- lựa chọn -"
                      v-model="formData.your_district"
                      :error-messages="formErrors.your_district"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center">
        <vue-recaptcha
            class="ma-auto"
            style="width: 300px"
            type="invisible"
            @verify="onCaptchaVerify"
            @error="onCaptchaError"
            ref="recaptcha"
            :sitekey="siteKey"
            :loadRecaptchaScript="true"
        />
        <p v-if="captchaError" class="mt-1 mb-0 text--red text-12 text-md-14">
          {{ captchaError }}
        </p>
        <v-btn
            type="submit"
            color="secondary"
            large
            class="btn-mdf mt-6"
            :disabled="isDisableSubmit"
        >
          Gửi
        </v-btn>
      </div>
    </form>
    <v-snackbar v-model="showSnackbar" right :color="snackbarOptions.variant">
      <div v-html="snackbarOptions.message"></div>
      <template v-slot:action="{ attrs }">
        <v-btn icon color="gray" v-bind="attrs" @click="showSnackbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </section>
</template>

<script>
import PageHeading from "../../../components/PageHeading.vue";
import VueRecaptcha from "vue-recaptcha";
import {apiUrl} from "@/main";
import Axios from "axios";
import {siteKeyCaptcha} from "@/main";

export default {
  components: {PageHeading, VueRecaptcha},
  name: "Register",
  data: () => ({
    title: "Đăng ký tư vấn miễn phí",
    description: `Quý Đối tác có nhu cầu mở Điểm Bán Hàng, vui lòng điền đầy đủ thông tin bên dưới.
       Nhân viên chúng tôi sẽ liên hệ trong thời gian sớm nhất!`,
    robot: false,
    isDisableSubmit: true,
    cityOptions: [],
    districtOptions: [],
    siteKey: siteKeyCaptcha,
    validatePhone: false,
    defaultFormData: {
      your_name: "",
      your_phone: "",
      // your_email: "",
      // your_address: "",
      // your_ward: "",
      your_district: "",
      your_city: "",
      your_subject: "Đăng ký điểm bán hàng"
    },
    formData: {
      your_name: "",
      your_phone: "",
      // your_email: "",
      // your_address: "",
      // your_ward: "",
      your_district: "",
      your_city: "",
      your_subject: "Đăng ký điểm bán hàng"
    },
    formErrors: {},
    captchaError: null,
    showSnackbar: false,
    snackbarOptions: {
      message: null,
      variant: null
    }
  }),
  methods: {
    async onFormSubmit() {
      const requiredValidationText = "Trường này là bắt buộc";
      const emailValidationText = "Email không hợp lệ";
      const {
        your_name,
        // your_email,
        your_phone,
        // your_address,
        your_district,
        your_city,
      } = this.formData;
      if (your_name === "")
        this.formErrors = {
          ...this.formErrors,
          your_name: requiredValidationText
        };
      // if (your_email !== "" && this.validateEmail(your_email) === false)
      //   this.formErrors = {...this.formErrors, your_email: emailValidationText};
      if (your_phone.length < 10 || your_phone == "")
        this.formErrors = {...this.formErrors, your_phone: "Trường này là bắt buộc và số điện thoại ít nhất 10 ký tự."};
      // if (your_address === "")
      //   this.formErrors = {
      //     ...this.formErrors,
      //     your_address: requiredValidationText
      //   };
      if (your_city === "")
        this.formErrors = {...this.formErrors, your_city: requiredValidationText};
      if (your_district === "")
        this.formErrors = {
          ...this.formErrors,
          your_district: requiredValidationText
        };

      if (Object.keys(this.formErrors).length <= 0) {
        const url = `${apiUrl.api}/contact-form-7/v1/contact-forms/5/feedback`;
        this.isDisableSubmit = true;
        const body = new FormData();
        await Object.keys(this.formData).forEach(key =>
            body.append(key, this.formData[key])
        );
        await Axios({
          /* Send email using Contact Form 7 without headers */
          method: "post",
          url: url,
          data: body,
          headers: {"Content-Type": "multipart/form-data"}
        })
            .then(res => {
              if (res.data.status === "validation_failed") {
                this.showSnackbar = true;
                this.isDisableSubmit = false;
                this.snackbarOptions = {
                  message: res.data.message,
                  variant: "error"
                };
                return;
              }
              if (res.data.status === "mail_sent") {
                this.showSnackbar = true;
                this.snackbarOptions = {
                  message: res.data.message,
                  variant: "success"
                };
                this.formData = this.defaultFormData;
                this.$refs.recaptcha.reset();
                this.isDisableSubmit = true;
              }
            })
            .catch(err => {
              this.isDisableSubmit = false;
              this.showSnackbar = true;
              this.snackbarOptions = {
                message: "Có lỗi xảy ra. Vui lòng thử lại sau",
                variant: "error"
              };
              console.log(err);
            });
      }
    },
    onCaptchaVerify: function (response) {
      if (response) this.isDisableSubmit = false;
    },
    onCaptchaError: err => {
      this.captchaError = "Có lỗi xảy ra!";
      this.isDisableSubmit = true;
    },
    setFromValues(name, value) {
      this.formData[name] = value;
      if (value !== "") {
        if (name === "your_city") this.onCityChange(value);
        const cloneFormErrors = this.formErrors;
        delete cloneFormErrors[name];
        this.formErrors = cloneFormErrors;
      }
    },

    onCityChange: function (value) {
      const addressData = JSON.parse(sessionStorage.getItem("address-data"));
      if (addressData) {
        const rawDistricts = addressData.find(x => x.name === value);
        if (rawDistricts)
          this.districtOptions = rawDistricts.districts.map(x => ({
            text: x,
            value: x
          }));
      }
    },
    getCities: function () {
      const addressData = JSON.parse(sessionStorage.getItem("address-data"));
      if (addressData)
        this.cityOptions = addressData.map(x => ({
          text: x.name,
          value: x.name
        }));
    },
    validateEmail: function (email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }
  },
  mounted() {
    this.getCities();
  }
};
</script>
