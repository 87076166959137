<template>
  <v-app-bar
    fixed
    elevation="1"
    color="white"
    elevate-on-scroll
    clipped-left
    clipped-right
    class="pa-0"
  >
    <v-container fluid>
      <v-row align="center">
        <h1 class="logo">
          <router-link to="/">
            <img
              :src="Logo"
              :height="$vuetify.breakpoint.lgAndUp ? 82 : 48"
              style="margin-top: 6px"
              alt="Mai Tâm Anh"
            />
          </router-link>
          <span class="hidden-screen-only">Tổng Đại Lý Mai Tâm Anh</span>
        </h1>
        <nav
          v-if="$vuetify.breakpoint.lgAndUp"
          class="d-flex align-center ml-auto"
        >
          <div class="d-flex flex-column mr-5">
            <ul class="nav-top d-flex ml-auto">
              <li v-for="item in menu.smallMenu" :key="item.path">
                <router-link :to="item.path">
                  {{ item.name }}
                </router-link>
              </li>
            </ul>
            <ul class="nav-main d-flex">
              <li v-for="item in menu.mainMenu" :key="item.path">
                <router-link :to="item.path" :exact="item.exact">
                  {{ item.name }}
                </router-link>
              </li>
            </ul>
          </div>
          <router-link
            v-for="item in menu.buttonMenu"
            :key="item.path"
            :to="item.path"
            class="btn-register"
          >
            {{ item.name }}
          </router-link>
        </nav>
        <v-btn
          v-else
          color="transparent"
          elevation="0"
          class="ml-auto"
          @click.stop="drawer = !drawer"
        >
          <v-icon large color="#000">
            mdi-menu
          </v-icon>
        </v-btn>
      </v-row>
    </v-container>
    <v-navigation-drawer
      class="drawer-menu"
      v-model="drawer"
      absolute
      height="105vh"
      right
      temporary
      overlay-opacity="0"
      app
    >
      <v-list dense>
        <v-list-item
          v-for="item in menu.mainMenu"
          :key="item.path"
          :to="item.path"
        >
          <v-list-item-icon>
            <v-icon size="20">mdi-chevron-right</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-for="item in menu.smallMenu"
          :key="item.path"
          :to="item.path"
        >
          <v-list-item-icon>
            <v-icon size="20">mdi-chevron-right</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-for="item in menu.buttonMenu"
          :key="item.path"
          :to="item.path"
        >
          <v-list-item-icon>
            <v-icon size="20">mdi-chevron-right</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </v-app-bar>
</template>

<script>
import Logo from "@/assets/img/common/logo.jpg";
import menu from "../../data/menu";

export default {
  name: "Header",
  data: () => ({
    Logo: Logo,
    menu: menu,
    drawer: null
  }),
  mounted() {
    this.menu = menu.reduce((acc, item) => {
      acc[item.type] = acc[item.type] || [];
      acc[item.type].push(item);
      return acc;
    }, {});
  }
};
</script>
