<template>
  <section class="what-prepare cmn-section">
    <v-container>
      <h2 class="ttl-section text-center">
        Mở điểm bán hàng Vietlott <br />
        bạn cần chuẩn bị gì ?
      </h2>
      <ul class="d-flex list flex-lg-row flex-column" v-if="Model">
        <li
          class="d-flex flex-column col-md-12 col-lg-3 align-center item"
          v-for="(item, idx) in Model"
          :key="idx"
        >
          <figure class="wrap-img">
            <img :src="item.image.url" :alt="item.title" />
          </figure>
          <h3 class="ttl mt-5 mb-3"><a :href="'/ve-chung-toi/' + item.url">{{ item.title }}</a></h3>
          <div class="description">
            {{ item.content }}
          </div>
          <router-link :to="{name: 'Đăng ký điểm bán', hash: item.url}" class="v-btn btn-mdf">
            Chi tiết
            <v-icon color="orange" class="absolute">
              mdi-menu-right
            </v-icon>
          </router-link>
        </li>
      </ul>
      <ul
        v-if="Data.Product"
        class="list-products d-flex flex-wrap flex-row mt-15 hidden-sm-and-down"
      >
        <li
          v-for="(item, idx) in Data.Product"
          :key="idx"
          class="d-flex flex-wrap align-center"
        >
          <router-link :to="item.link">
            <img :src="item.img" :alt="item.alt" />
          </router-link>
        </li>
      </ul>
    </v-container>
  </section>
</template>

<script>
import PROD01 from "@/assets/img/index/wyp_img-01.png";
import PROD02 from "@/assets/img/index/wyp_img-02.png";
import PROD03 from "@/assets/img/index/wyp_img-03.png";
import PROD04 from "@/assets/img/index/wyp_img-04.png";
import PROD05 from "@/assets/img/index/wyp_img-05.png";

export default {
  name: "WhatYouPrepare",
  props: {
    Model: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    Data: {
      Product: [
        {
          img: PROD02,
          alt: "Mega 6/45",
          link: "san-pham/39",
        },
        {
          img: PROD03,
          alt: "Power 6/55",
          link: "san-pham/41",
        },
        {
          img: PROD04,
          alt: "Max 3D",
          link: "san-pham/43",
        },
        {
          img: PROD05,
          alt: "Max 4D",
          link: "san-pham/45",
        },
        {
          img: PROD01,
          alt: "Keno",
          link: "san-pham/47",
        },
      ]
    }
  })
};
</script>

<style scoped></style>
