<template>
  <v-container>
    <v-row>
      <v-col lg="3" md="4" cols="12">
        <sidebar>
          <sidebar-item class="registration">
            <v-tabs vertical v-model="tab">
              <v-tab v-for="(item, i) in tabContent" :data-key="i"
                     :key="i"
                     :href="'#' + i"
              >
                <h3>{{ item.label }}</h3>
              </v-tab>
            </v-tabs>
          </sidebar-item>
        </sidebar>
      </v-col>
      <v-col lg="9" md="8" cols="12">
        <right-content class="registration">
          <v-tabs-items v-model="tab" touchless>
            <v-tab-item v-for="(item, i) in tabContent"
                        :key="i"
                        :data-key="i"
                        :value="item.id"

            >
              <v-card flat>
                <v-skeleton-loader
                  v-if="!isContentLoaded"
                  type="article"
                ></v-skeleton-loader>
                <component
                  v-bind:is="item.content"
                  :data="item.data"
                  v-else
                ></component>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </right-content>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import RightContent from "../../components/RightContent.vue";
import Sidebar from "../../components/Sidebar.vue";
import SidebarItem from "../../components/SidebarItem.vue";
import CommissionPolicy from "./components/CommissionPolicy";
import Criteria from "./components/Criteria";
import Design from "./components/Design";
import FAQ from "./components/FAQ";
import Investments from "./components/Investments";
import Register from "./components/Register";
import Procedure from "./components/Procedure";
import axios from "axios";
import { apiUrl } from "@/main";

export default {
  components: {
    Sidebar,
    SidebarItem,
    CommissionPolicy,
    Criteria,
    Procedure,
    Design,
    FAQ,
    Investments,
    Register,
    RightContent
  },
  name: "Registration",
  data: () => ({
    Info: {
      meta_title: 'CÔNG TY TNHH ĐẦU TƯ MAI TÂM ANH | Đăng Ký ĐBH'
    },
    tabContent: {
      register: {
        label: "Đăng Ký Điểm Bán Hàng",
        content: Register,
        data: null,
        id: 'register'
      },
      criteria: {
        label: "Tiêu Chí Điểm Bán Hàng",
        content: Criteria,
        data: null,
        id: 'criteria'
      },
      procedure: {
        label: "Quy Trình Mở Điểm Bán Hàng",
        content: Procedure,
        data: null,
        id: 'procedure'
      },
      investments: {
        label: "Hạng Mục Đầu Tư",
        content: Investments,
        data: null,
        id: 'investments'
      },
      design: {
        label: "Layout Điểm Bán Hàng",
        content: Design,
        data: null,
        id: 'design'
      },
      ["commission-policy"]: {
        label: "Chính Sách Hoa Hồng",
        content: CommissionPolicy,
        data: null,
        id: 'commission-policy'
      },
      FAQ: {
        label: "Hỏi Đáp",
        content: FAQ,
        data: null,
        id: 'FAQ'
      }
    },
    tab: null,
    isContentLoaded: false
  }),
  methods: {
    getData: function() {
      axios
        .get(`${apiUrl.page}/dang-ky-diem-ban-hang`)
        .then(response => {
          if (!response) return false;
          const {
            faq,
            investments,
            open_store,
            qualification_criteria,
            privacy,
            design
          } = response.data[0].acf;
          this.tabContent.FAQ.data = faq;
          this.tabContent.investments.data = investments;
          this.tabContent.criteria.data = qualification_criteria;
          this.tabContent.procedure.data = open_store;
          this.tabContent["commission-policy"].data = privacy;
          this.tabContent.design.data = design;
        })
        .catch(err => console.log(err))
        .finally(() => (this.isContentLoaded = true));
    }
  },
  mounted() {
    this.getData();
    this.tab = this.$route.hash.substr(1);

    axios
        .get(`${apiUrl.api}/acf/v3/options/options`)
        .then(res => {
          if (!res.data) return false;
          this.Info = res.data.acf;
        })
        .catch(err => {
          console.log(err);
        });
  },
  metaInfo() {
    return {
      title: this.Info.meta_title  + ' | Đăng Ký ĐBH',
      description: this.Info.meta_description,
      meta: [
        {
          name: "title",
          content: this.Info.meta_title + ' | Về Chúng Tôi'
        },
        {
          name: "description",
          content: this.Info.meta_description
        },
        {
          property: "og:type",
          content: "website"
        },
        {
          property: "og:url",
          content: this.Info.meta_facebook_url
        },
        {
          property: "og:title",
          content: this.Info.meta_facebook_title
        },
        {
          property: "og:description",
          content: this.Info.meta_facebook_desciprtion
        },
        {
          property: "og:image",
          content: this.Info.meta_facebook_image
        },
        {
          property: "twitter:card",
          content: "summary_large_image"
        },
        {
          property: "twitter:url",
          content: this.Info.meta_twitter_url
        },
        {
          property: "twitter:title",
          content: this.Info.meta_twitter_title
        },
        {
          property: "twitter:description",
          content: this.Info.meta_twitter_desciprtion
        },
        {
          property: "twitter:image",
          content: this.Info.meta_twitter_image
        }
      ],
      htmlAttrs: {
        lang: "en"
      }
    };
  }
};
</script>
