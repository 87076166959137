<template>
  <section class="why-choose cmn-section">
    <v-container>
      <h2 class="ttl-section text-center">
        vì sao nên chọn <br> tổng đại lý mai tâm anh?
      </h2>
      <ul class="d-flex list flex-column flex-md-row" v-if="Model">
        <li class="col-12 col-md-4" v-for="(item, idx) in Model" :key="idx">
          <figure class="img-round"><img :src="item.image.url" :alt="item.title"></figure>
          <h3 class="ttl spacing-zero mt-7 mb-7" v-html="item.title"/>
          <div class="text-center">
            <router-link class="v-btn btn-mdf" :to="{name: 'Về Mai Tâm Anh', hash: item.url}">
              Chi tiết
              <v-icon color="orange" class="absolute">
                mdi-menu-right
              </v-icon>
            </router-link>
          </div>
        </li>
      </ul>
    </v-container>
  </section>
</template>

<script>
export default {
  name: "WhyChoose",
  props: {
    Model: {
      type: Array,
      required: true,
    }
  },

}
</script>
